<template>
  <svg
    width="21"
    height="11"
    viewBox="0 0 21 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.5616 0.0645784C20.4323 -0.0275731 20.272 -0.000603506 20.1178 0.0216628L17.1394 0.384243C16.9122 0.485359 16.8114 0.749835 16.913 0.976319C17.0155 1.19972 17.2816 1.30238 17.5111 1.22213L18.9079 1.08692L16.9825 3.26563L13.425 3.49704L13.2948 3.72117L12.0537 5.85227H7.9725L7.83804 6.03503L6.60028 7.71073H2.47422L2.34316 7.90936L0.828562 10.306C0.690992 10.5113 0.747025 10.7885 0.953268 10.9247C1.15966 11.0613 1.43946 11.0072 1.57703 10.8013L2.95642 8.60498H7.05771L7.19203 8.42215L8.42809 6.74652H12.5734L12.7022 6.52231L13.9441 4.3921L17.4048 4.11381L17.5135 4.00431L19.7438 1.59566L19.6984 3.4104C19.6775 3.6568 19.8629 3.87152 20.1084 3.89298C20.3554 3.91209 20.5737 3.7294 20.5937 3.483L20.752 0.466914C20.7632 0.307891 20.693 0.15526 20.5616 0.0645784Z"
      fill="#00C7B2" />
  </svg>
</template>