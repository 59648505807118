<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="115"
    height="44"
    viewBox="0 0 115 44"
    fill="none">
    <path
      d="M22.3977 43.4744C34.4527 43.4744 44.2251 33.7423 44.2251 21.7372C44.2251 9.73208 34.4527 0 22.3977 0C10.3428 0 0.570313 9.73208 0.570313 21.7372C0.570313 33.7423 10.3428 43.4744 22.3977 43.4744Z"
      fill="#222222" />
    <path
      d="M22.6565 15.4766C19.1476 15.4766 16.3018 18.3224 16.3018 21.8313C16.3018 25.3402 19.1476 28.186 22.6565 28.186C26.1654 28.186 29.0112 25.3402 29.0112 21.8313C29.0112 18.3224 26.1654 15.4766 22.6565 15.4766ZM22.6565 24.9741C20.9227 24.9741 19.516 23.5673 19.516 21.8336C19.516 20.0999 20.9227 18.6931 22.6565 18.6931C24.3902 18.6931 25.797 20.0999 25.797 21.8336C25.797 23.5673 24.3902 24.9741 22.6565 24.9741Z"
      fill="#ED4324" />
    <path
      d="M27.8541 3.29871C26.5302 1.95409 24.6929 1.12061 22.6575 1.12061C20.6222 1.12061 18.7848 1.95639 17.4609 3.29871H27.8541Z"
      fill="#F28030" />
    <path
      d="M40.8857 26.9152C42.2304 25.5913 43.0639 23.7539 43.0639 21.7186C43.0639 19.6832 42.2281 17.8459 40.8857 16.522V26.9152Z"
      fill="#FFCB32" />
    <path
      d="M4.18982 16.522C2.8452 17.8459 2.01172 19.6832 2.01172 21.7186C2.01172 23.7539 2.8475 25.5913 4.18982 26.9152V16.522Z"
      fill="#6D4097" />
    <path
      d="M17.4609 40.0405C18.7848 41.3852 20.6222 42.2186 22.6575 42.2186C24.6929 42.2186 26.5302 41.3828 27.8541 40.0405H17.4609Z"
      fill="#2C91CF" />
    <path
      d="M28.8122 6.99913C27.5182 4.95917 25.248 3.60303 22.6532 3.60303C20.0606 3.60303 17.7881 4.96147 16.4941 6.99913H28.8122Z"
      fill="#2C91CF" />
    <path
      d="M37.1826 27.8752C39.2226 26.5812 40.5787 24.311 40.5787 21.7162C40.5787 19.1236 39.2203 16.8511 37.1826 15.5571V27.8752Z"
      fill="#6D4097" />
    <path
      d="M7.89024 15.5591C5.85028 16.8531 4.49414 19.1233 4.49414 21.7181C4.49414 24.3106 5.85258 26.5832 7.89024 27.8771V15.5591Z"
      fill="#FFCB32" />
    <path
      d="M16.4971 36.3408C17.791 38.3808 20.0612 39.7369 22.6561 39.7369C25.2486 39.7369 27.5212 38.3785 28.8151 36.3408H16.4971Z"
      fill="#50B648" />
    <path
      d="M15.4193 32.0173C13.6717 31.9137 12.2603 30.5667 12.0577 28.8491H8.3623C8.57873 32.5975 11.6203 35.5814 15.394 35.7058L15.4193 32.0173Z"
      fill="#6D4097" />
    <path
      d="M32.9084 28.7476C32.8048 30.4951 31.4602 31.9065 29.7402 32.1091V35.8022C33.4886 35.5858 36.4726 32.5443 36.5969 28.7706L32.9084 28.7476Z"
      fill="#F28030" />
    <path
      d="M29.5381 11.187C31.2856 11.2907 32.697 12.6376 32.8997 14.3552H36.5928C36.3763 10.6068 33.3348 7.62287 29.5611 7.49854L29.5381 11.187Z"
      fill="#FFCB32" />
    <path
      d="M12.0508 14.4565C12.1544 12.709 13.5013 11.2976 15.219 11.095V7.40186C11.4706 7.61828 8.48664 10.6598 8.3623 14.4335L12.0508 14.4565Z"
      fill="#ED4324" />
    <path
      d="M8.09396 8.32959L7.95121 14.2929L4.51367 14.4172L4.58505 12.6628C4.58274 12.6651 5.78231 9.88834 8.09396 8.32959Z"
      fill="#FFCB32" />
    <path
      d="M36.9883 8.28418L37.131 14.2475L40.5686 14.3718L40.4972 12.6174C40.4972 12.6174 39.2976 9.84063 36.9883 8.28418Z"
      fill="#2C91CF" />
    <path
      d="M36.9883 34.5735L37.131 28.6102L40.5686 28.4858L40.4972 30.2403C40.4972 30.2403 39.2976 33.0147 36.9883 34.5735Z"
      fill="#FFCB32" />
    <path
      d="M8.09396 34.6213L7.95121 28.658L4.51367 28.5337L4.58505 30.2881C4.58274 30.2881 5.78231 33.0626 8.09396 34.6213Z"
      fill="#2C91CF" />
    <path
      d="M9.36523 7.02445C9.36523 7.02445 12.0522 4.03359 13.7698 3.58691H15.7568L15.7384 7.02445H9.36523Z"
      fill="#50B648" />
    <path
      d="M36.0547 7.11869C36.0547 7.11869 33.3677 4.12783 31.6501 3.68115H29.6631L29.6815 7.11869H36.0547Z"
      fill="#6D4097" />
    <path
      d="M36.0547 36.0601C36.0547 36.0601 33.3677 39.0509 31.6501 39.4976H29.6631L29.6815 36.0601H36.0547Z"
      fill="#ED4324" />
    <path
      d="M9.36523 35.9639C9.36523 35.9639 12.0522 38.9547 13.7698 39.4014H15.7568L15.7384 35.9639H9.36523Z"
      fill="#F28030" />
    <path
      d="M19.4714 22.0986H16.3516V34.4996H19.4714V22.0986Z"
      fill="#ED4324" />
    <path
      d="M19.0609 14.3304C19.1622 12.4308 20.7301 10.9204 22.6573 10.9204C24.5821 10.9204 26.1501 12.4308 26.2537 14.3304H29.9422C29.8363 10.3932 26.6198 7.23193 22.6596 7.23193C18.6971 7.23193 15.4806 10.3932 15.377 14.3304H19.0609Z"
      fill="#F28030" />
    <path
      d="M29.8525 18.1229C31.7521 18.2242 33.2624 19.7921 33.2624 21.7193C33.2624 23.6441 31.7521 25.2121 29.8525 25.3157V29.0042C33.7897 28.8983 36.9509 25.6818 36.9509 21.7216C36.9509 17.7591 33.7897 14.5426 29.8525 14.439V18.1229Z"
      fill="#50B648" />
    <path
      d="M15.2224 25.313C13.3229 25.2117 11.8125 23.6437 11.8125 21.7166C11.8125 19.7917 13.3229 18.2238 15.2224 18.1201V14.4316C11.2853 14.5376 8.12402 17.7541 8.12402 21.7143C8.12402 25.6767 11.2853 28.8932 15.2224 28.9969V25.313Z"
      fill="#2C91CF" />
    <path
      d="M26.1017 28.748C25.9981 30.4956 24.6512 31.907 22.9336 32.1096V35.8027C26.682 35.5863 29.6659 32.5448 29.7903 28.7711L26.1017 28.748Z"
      fill="#FFCB32" />
    <path
      d="M50.7326 38.7868H50.3777V36.0538H49.4131V35.7383H51.6972V36.0538H50.7326V38.7868Z"
      fill="#222222" />
    <path
      d="M54.0015 36.4609C54.1031 36.4609 54.1941 36.4685 54.2745 36.4852L54.226 36.8067C54.1319 36.7855 54.0485 36.7749 53.9757 36.7749C53.7907 36.7749 53.6329 36.8492 53.501 36.9994C53.369 37.1495 53.3038 37.3361 53.3038 37.5605V38.786H52.958V36.5019H53.2431L53.2826 36.925H53.2993C53.3842 36.7764 53.4858 36.6611 53.6056 36.5808C53.727 36.5004 53.8574 36.4609 54.0015 36.4609Z"
      fill="#222222" />
    <path
      d="M55.8802 36.5034V37.9852C55.8802 38.1718 55.9227 38.3098 56.0076 38.4023C56.0925 38.4933 56.2245 38.5403 56.405 38.5403C56.6446 38.5403 56.819 38.4751 56.9297 38.3447C57.0405 38.2142 57.0951 38.0004 57.0951 37.7046V36.5034H57.4409V38.7875H57.1557L57.1057 38.4812H57.0875C57.0162 38.5934 56.9176 38.6799 56.7917 38.739C56.6658 38.7982 56.5233 38.8285 56.361 38.8285C56.0834 38.8285 55.8741 38.7633 55.7361 38.6313C55.5981 38.4994 55.5283 38.2885 55.5283 37.9973V36.5034H55.8802Z"
      fill="#222222" />
    <path
      d="M60.5017 38.1637C60.5017 38.376 60.4228 38.5398 60.2635 38.6551C60.1058 38.7704 59.8828 38.828 59.5962 38.828C59.2929 38.828 59.0578 38.7795 58.8879 38.6839V38.3624C58.9971 38.4185 59.1154 38.4625 59.2413 38.4943C59.3672 38.5262 59.4885 38.5429 59.6053 38.5429C59.7858 38.5429 59.9253 38.514 60.0224 38.4564C60.1194 38.3988 60.168 38.3108 60.168 38.1925C60.168 38.103 60.1301 38.0272 60.0527 37.965C59.9754 37.9013 59.8252 37.827 59.6007 37.7405C59.3884 37.6617 59.2367 37.5919 59.1473 37.5327C59.0578 37.4736 58.991 37.4069 58.9471 37.331C58.9031 37.2552 58.8818 37.1657 58.8818 37.0626C58.8818 36.876 58.9577 36.7289 59.1093 36.6212C59.261 36.5135 59.4688 36.4604 59.7327 36.4604C59.9784 36.4604 60.2195 36.5105 60.4531 36.6106L60.3303 36.8927C60.1012 36.7987 59.8935 36.7516 59.7069 36.7516C59.5431 36.7516 59.4187 36.7774 59.3353 36.829C59.2519 36.8806 59.211 36.9519 59.211 37.0413C59.211 37.102 59.2261 37.1551 59.258 37.1976C59.2898 37.24 59.3399 37.281 59.4096 37.3204C59.4794 37.3598 59.6129 37.416 59.81 37.4888C60.0815 37.5873 60.2635 37.6874 60.3591 37.786C60.4546 37.8861 60.5017 38.012 60.5017 38.1637Z"
      fill="#222222" />
    <path
      d="M62.6557 38.5439C62.7163 38.5439 62.7755 38.5393 62.8331 38.5302C62.8908 38.5211 62.9348 38.512 62.9681 38.5014V38.7668C62.9302 38.785 62.8756 38.8002 62.8028 38.8123C62.73 38.8245 62.6648 38.8305 62.6056 38.8305C62.1643 38.8305 61.9428 38.5985 61.9428 38.1329V36.7739H61.6152V36.6071L61.9428 36.463L62.0884 35.9746H62.2886V36.5039H62.9514V36.7724H62.2886V38.1162C62.2886 38.2542 62.322 38.3588 62.3872 38.4332C62.4524 38.506 62.5419 38.5439 62.6557 38.5439Z"
      fill="#222222" />
    <path
      d="M65.2444 38.828C64.9061 38.828 64.6407 38.7249 64.4451 38.5201C64.2494 38.3138 64.1523 38.0287 64.1523 37.6632C64.1523 37.2946 64.2433 37.0019 64.4238 36.785C64.6058 36.5681 64.8485 36.4604 65.1549 36.4604C65.4415 36.4604 65.6675 36.5545 65.8343 36.7425C66.0012 36.9306 66.0846 37.1794 66.0846 37.4872V37.7056H64.5103C64.5179 37.9741 64.5846 38.1773 64.7135 38.3169C64.8424 38.4564 65.0229 38.5247 65.2565 38.5247C65.5022 38.5247 65.7464 38.4731 65.986 38.37V38.6778C65.8632 38.7309 65.7479 38.7688 65.6387 38.7916C65.5295 38.8159 65.3975 38.828 65.2444 38.828ZM65.1503 36.7501C64.9668 36.7501 64.8212 36.8093 64.712 36.9291C64.6028 37.0489 64.5391 37.2142 64.5194 37.4251H65.713C65.713 37.2067 65.6645 37.0398 65.5674 36.9246C65.4703 36.8078 65.3308 36.7501 65.1503 36.7501Z"
      fill="#222222" />
    <path
      d="M69.0834 38.4798H69.0652C68.9059 38.7119 68.6663 38.8271 68.3478 38.8271C68.049 38.8271 67.8169 38.7255 67.6501 38.5208C67.4848 38.316 67.4014 38.0263 67.4014 37.6502C67.4014 37.274 67.4848 36.9813 67.6516 36.772C67.8185 36.5642 68.0505 36.4596 68.3478 36.4596C68.6572 36.4596 68.8953 36.5718 69.0606 36.7978H69.0879L69.0728 36.6325L69.0652 36.4717V35.542H69.411V38.7847H69.1289L69.0834 38.4798ZM68.3902 38.539C68.6268 38.539 68.7982 38.4753 68.9044 38.3463C69.0106 38.2174 69.0637 38.0112 69.0637 37.7245V37.6517C69.0637 37.3286 69.0091 37.0966 68.9014 36.9586C68.7937 36.8206 68.6223 36.7508 68.3857 36.7508C68.1825 36.7508 68.0278 36.8297 67.9201 36.9874C67.8124 37.1451 67.7593 37.3681 67.7593 37.6547C67.7593 37.9459 67.8124 38.1674 67.9201 38.316C68.0262 38.4646 68.184 38.539 68.3902 38.539Z"
      fill="#222222" />
    <path
      d="M74.0237 36.465C74.324 36.465 74.5576 36.5681 74.7229 36.7729C74.8882 36.9777 74.9716 37.2673 74.9716 37.6435C74.9716 38.0181 74.8882 38.3108 74.7199 38.5186C74.553 38.7264 74.3194 38.8295 74.0222 38.8295C73.8735 38.8295 73.737 38.8022 73.6142 38.7476C73.4913 38.693 73.3882 38.6081 73.3048 38.4943H73.279L73.2062 38.7886H72.959V35.5459H73.3048V36.3331C73.3048 36.509 73.2987 36.6682 73.2881 36.8078H73.3048C73.4671 36.5788 73.7067 36.465 74.0237 36.465ZM73.9736 36.7547C73.737 36.7547 73.5672 36.823 73.4625 36.9579C73.3579 37.0929 73.3063 37.3219 73.3063 37.642C73.3063 37.9635 73.3594 38.1925 73.4671 38.3305C73.5748 38.4686 73.7461 38.5383 73.9812 38.5383C74.1936 38.5383 74.3528 38.461 74.4559 38.3063C74.5606 38.1516 74.6122 37.9286 74.6122 37.6389C74.6122 37.3417 74.5606 37.1202 74.4559 36.9746C74.3528 36.8275 74.1905 36.7547 73.9736 36.7547Z"
      fill="#222222" />
    <path
      d="M76.0654 36.5034H76.437L76.9375 37.8062C77.0467 38.1035 77.115 38.3189 77.1423 38.4508H77.159C77.1772 38.3795 77.2151 38.2582 77.2727 38.0868C77.3303 37.9154 77.5184 37.3876 77.8384 36.5034H78.21L77.2287 39.1045C77.1317 39.3608 77.0179 39.5444 76.8875 39.6521C76.757 39.7597 76.5978 39.8128 76.4097 39.8128C76.3035 39.8128 76.2004 39.8007 76.0973 39.7779V39.5004C76.1731 39.5171 76.2596 39.5246 76.3536 39.5246C76.5917 39.5246 76.7601 39.3912 76.8617 39.1242L76.9891 38.7997L76.0654 36.5034Z"
      fill="#222222" />
    <path
      d="M82.3625 38.8285C82.0243 38.8285 81.7589 38.7253 81.5632 38.5206C81.3676 38.3143 81.2705 38.0292 81.2705 37.6637C81.2705 37.2951 81.3615 37.0024 81.542 36.7855C81.724 36.5686 81.9667 36.4609 82.273 36.4609C82.5597 36.4609 82.7857 36.555 82.9525 36.743C83.1193 36.9311 83.2028 37.1798 83.2028 37.4877V37.7061H81.6284C81.636 37.9746 81.7028 38.1778 81.8317 38.3174C81.9606 38.4569 82.1411 38.5251 82.3747 38.5251C82.6204 38.5251 82.8645 38.4736 83.1042 38.3704V38.6783C82.9813 38.7314 82.8661 38.7693 82.7569 38.7921C82.6477 38.8163 82.5157 38.8285 82.3625 38.8285ZM82.2685 36.7506C82.085 36.7506 81.9394 36.8098 81.8302 36.9296C81.721 37.0494 81.6573 37.2147 81.6375 37.4255H82.8312C82.8312 37.2071 82.7826 37.0403 82.6856 36.925C82.5885 36.8083 82.449 36.7506 82.2685 36.7506Z"
      fill="#222222" />
    <path
      d="M85.1449 38.7885L84.2773 36.5044H84.6489L85.1403 37.8588C85.2511 38.1758 85.3163 38.3805 85.336 38.4761H85.3527C85.3678 38.4018 85.4164 38.2501 85.4983 38.018C85.5802 37.786 85.7622 37.2825 86.0458 36.5044H86.4174L85.5498 38.7885H85.1449Z"
      fill="#222222" />
    <path
      d="M88.6008 38.828C88.2626 38.828 87.9972 38.7249 87.8015 38.5201C87.6059 38.3138 87.5088 38.0287 87.5088 37.6632C87.5088 37.2946 87.5998 37.0019 87.7803 36.785C87.9623 36.5681 88.205 36.4604 88.5113 36.4604C88.798 36.4604 89.024 36.5545 89.1908 36.7425C89.3576 36.9306 89.441 37.1794 89.441 37.4872V37.7056H87.8667C87.8743 37.9741 87.941 38.1773 88.07 38.3169C88.1989 38.4564 88.3794 38.5247 88.6129 38.5247C88.8586 38.5247 89.1028 38.4731 89.3425 38.37V38.6778C89.2196 38.7309 89.1043 38.7688 88.9951 38.7916C88.8875 38.8159 88.7555 38.828 88.6008 38.828ZM88.5083 36.7501C88.3248 36.7501 88.1792 36.8093 88.07 36.9291C87.9608 37.0489 87.8971 37.2142 87.8773 37.4251H89.071C89.071 37.2067 89.0224 37.0398 88.9254 36.9246C88.8268 36.8078 88.6888 36.7501 88.5083 36.7501Z"
      fill="#222222" />
    <path
      d="M91.9263 36.4609C92.0279 36.4609 92.1189 36.4685 92.1993 36.4852L92.1508 36.8067C92.0567 36.7855 91.9733 36.7749 91.9005 36.7749C91.7155 36.7749 91.5577 36.8492 91.4258 36.9994C91.2938 37.1495 91.2286 37.3361 91.2286 37.5605V38.786H90.8828V36.5019H91.1679L91.2074 36.925H91.2241C91.309 36.7764 91.4106 36.6611 91.5304 36.5808C91.6518 36.5004 91.7837 36.4609 91.9263 36.4609Z"
      fill="#222222" />
    <path
      d="M93.1191 36.5034H93.4907L93.9912 37.8062C94.1004 38.1035 94.1687 38.3189 94.196 38.4508H94.2127C94.2309 38.3795 94.2688 38.2582 94.3264 38.0868C94.384 37.9154 94.5721 37.3876 94.8921 36.5034H95.2637L94.2824 39.1045C94.1854 39.3608 94.0716 39.5444 93.9412 39.6521C93.8107 39.7597 93.6515 39.8128 93.4634 39.8128C93.3573 39.8128 93.2541 39.8007 93.151 39.7779V39.5004C93.2268 39.5171 93.3133 39.5246 93.4073 39.5246C93.6454 39.5246 93.8138 39.3912 93.9154 39.1242L94.0428 38.7997L93.1191 36.5034Z"
      fill="#222222" />
    <path
      d="M98.501 38.7877V35.7407H98.8559V38.7877H98.501Z"
      fill="#222222" />
    <path
      d="M102.055 38.787V37.3097C102.055 37.1232 102.013 36.9852 101.928 36.8926C101.843 36.8001 101.711 36.7546 101.531 36.7546C101.291 36.7546 101.117 36.8198 101.006 36.9488C100.895 37.0777 100.839 37.2915 100.839 37.5888V38.787H100.493V36.5029H100.774L100.83 36.8153H100.847C100.918 36.7031 101.016 36.6151 101.144 36.5544C101.271 36.4922 101.414 36.4619 101.572 36.4619C101.846 36.4619 102.054 36.5286 102.192 36.6606C102.331 36.7926 102.401 37.0049 102.401 37.2976V38.7885H102.055V38.787Z"
      fill="#222222" />
    <path
      d="M105.522 38.4798H105.504C105.344 38.7119 105.105 38.8271 104.786 38.8271C104.487 38.8271 104.255 38.7255 104.089 38.5208C103.923 38.316 103.84 38.0263 103.84 37.6502C103.84 37.274 103.923 36.9813 104.09 36.772C104.257 36.5642 104.489 36.4596 104.786 36.4596C105.096 36.4596 105.334 36.5718 105.499 36.7978H105.526L105.511 36.6325L105.504 36.4717V35.542H105.849V38.7847H105.567L105.522 38.4798ZM104.829 38.539C105.065 38.539 105.237 38.4753 105.343 38.3463C105.449 38.2174 105.502 38.0112 105.502 37.7245V37.6517C105.502 37.3286 105.448 37.0966 105.34 36.9586C105.232 36.8206 105.061 36.7508 104.824 36.7508C104.621 36.7508 104.466 36.8297 104.359 36.9874C104.251 37.1451 104.198 37.3681 104.198 37.6547C104.198 37.9459 104.251 38.1674 104.359 38.316C104.466 38.4646 104.622 38.539 104.829 38.539Z"
      fill="#222222" />
    <path
      d="M107.407 35.8843C107.407 35.8054 107.427 35.7463 107.465 35.7099C107.504 35.6735 107.553 35.6553 107.61 35.6553C107.667 35.6553 107.714 35.6735 107.755 35.7114C107.796 35.7493 107.815 35.8069 107.815 35.8843C107.815 35.9616 107.796 36.0208 107.755 36.0587C107.714 36.0966 107.667 36.1163 107.61 36.1163C107.553 36.1163 107.503 36.0966 107.465 36.0587C107.427 36.0208 107.407 35.9632 107.407 35.8843ZM107.782 38.7887H107.436V36.5046H107.782V38.7887Z"
      fill="#222222" />
    <path
      d="M110.773 38.7883L110.705 38.4637H110.688C110.574 38.6062 110.461 38.7033 110.347 38.7549C110.233 38.8049 110.092 38.8307 109.922 38.8307C109.696 38.8307 109.519 38.7731 109.39 38.6563C109.261 38.5395 109.197 38.3742 109.197 38.1588C109.197 37.6978 109.566 37.4551 110.304 37.4338L110.693 37.4217V37.2807C110.693 37.1017 110.655 36.9697 110.577 36.8833C110.5 36.7984 110.377 36.7559 110.207 36.7559C110.016 36.7559 109.801 36.8135 109.561 36.9303L109.455 36.6649C109.567 36.6042 109.69 36.5557 109.825 36.5208C109.959 36.4859 110.094 36.4692 110.229 36.4692C110.502 36.4692 110.703 36.5299 110.834 36.6512C110.966 36.7726 111.031 36.9667 111.031 37.2321V38.7913H110.773V38.7883ZM109.992 38.5441C110.207 38.5441 110.377 38.4849 110.5 38.3666C110.623 38.2483 110.685 38.083 110.685 37.8707V37.6644L110.339 37.6796C110.065 37.6887 109.866 37.7326 109.745 37.807C109.623 37.8828 109.563 38.0011 109.563 38.1603C109.563 38.2862 109.601 38.3803 109.677 38.4455C109.749 38.5107 109.856 38.5441 109.992 38.5441Z"
      fill="#222222" />
    <path
      d="M114.161 38.787V37.3097C114.161 37.1232 114.118 36.9852 114.033 36.8926C113.948 36.8001 113.817 36.7546 113.636 36.7546C113.396 36.7546 113.222 36.8198 113.111 36.9488C113.001 37.0777 112.944 37.2915 112.944 37.5888V38.787H112.599V36.5029H112.879L112.935 36.8153H112.952C113.023 36.7031 113.122 36.6151 113.249 36.5544C113.377 36.4922 113.519 36.4619 113.677 36.4619C113.952 36.4619 114.159 36.5286 114.297 36.6606C114.437 36.7926 114.507 37.0049 114.507 37.2976V38.7885H114.161V38.787Z"
      fill="#222222" />
    <path
      d="M53.8691 29.6077C53.8691 30.2249 53.6477 30.7118 53.2033 31.0667C52.7589 31.4216 52.1416 31.5991 51.3484 31.5991C50.6188 31.5991 49.9743 31.4626 49.4131 31.188V29.8412C49.8742 30.0475 50.2655 30.1916 50.584 30.2765C50.904 30.3599 51.1952 30.4024 51.4606 30.4024C51.7791 30.4024 52.0218 30.3417 52.1932 30.2204C52.363 30.0991 52.448 29.9186 52.448 29.6774C52.448 29.544 52.41 29.4241 52.3357 29.3195C52.2614 29.2148 52.1507 29.1147 52.0066 29.0177C51.861 28.9206 51.5668 28.7674 51.1209 28.5551C50.7038 28.3594 50.3898 28.1698 50.1805 27.9894C49.9712 27.8089 49.8044 27.598 49.68 27.3584C49.5557 27.1188 49.4935 26.8382 49.4935 26.5167C49.4935 25.9115 49.6982 25.4368 50.1077 25.091C50.5172 24.7452 51.0845 24.5723 51.8079 24.5723C52.1628 24.5723 52.5026 24.6147 52.8256 24.6982C53.1487 24.7816 53.4854 24.9014 53.8388 25.0531L53.3716 26.18C53.0061 26.0298 52.7058 25.9252 52.4662 25.866C52.228 25.8068 51.993 25.7765 51.7624 25.7765C51.4879 25.7765 51.2771 25.8402 51.1315 25.9676C50.9844 26.095 50.9116 26.2618 50.9116 26.4681C50.9116 26.5955 50.9419 26.7078 51.0011 26.8033C51.0602 26.8989 51.1542 26.9899 51.2847 27.0793C51.4136 27.1688 51.72 27.3281 52.2038 27.5586C52.8423 27.8635 53.2806 28.1698 53.5172 28.4777C53.7493 28.7856 53.8691 29.1617 53.8691 29.6077Z"
      fill="#222222" />
    <path
      d="M61.6298 24.6709V29.0951C61.6298 29.6001 61.5161 30.043 61.2901 30.4237C61.0641 30.8044 60.738 31.0956 60.3103 31.2988C59.8826 31.502 59.3776 31.6021 58.7952 31.6021C57.9155 31.6021 57.233 31.3777 56.7461 30.9272C56.2593 30.4768 56.0166 29.8595 56.0166 29.0784V24.6724H57.462V28.8585C57.462 29.3847 57.5682 29.7715 57.7805 30.0187C57.9928 30.2644 58.3432 30.3888 58.8331 30.3888C59.3063 30.3888 59.6506 30.2644 59.8644 30.0172C60.0783 29.77 60.1844 29.3802 60.1844 28.8509V24.6739H61.6298V24.6709Z"
      fill="#222222" />
    <path
      d="M65.7126 28.8842V31.5081H64.2627V24.6709H66.2541C67.1823 24.6709 67.8709 24.8408 68.3168 25.179C68.7627 25.5172 68.9856 26.0314 68.9856 26.7199C68.9856 27.1219 68.8749 27.4798 68.6535 27.7938C68.4321 28.1077 68.1181 28.3519 67.7131 28.5309C68.7415 30.0673 69.4118 31.0607 69.7243 31.5096H68.1151L66.4831 28.8858H65.7126V28.8842ZM65.7126 27.7058H66.1798C66.6378 27.7058 66.976 27.6299 67.1944 27.4768C67.4128 27.3236 67.522 27.0839 67.522 26.7563C67.522 26.4318 67.4113 26.2012 67.1884 26.0647C66.9654 25.9282 66.6196 25.8585 66.1525 25.8585H65.7126V27.7058Z"
      fill="#222222" />
    <path
      d="M75.7296 31.5085L75.2336 29.8811H72.7417L72.2458 31.5085H70.6836L73.0966 24.644H74.8696L77.2918 31.5085H75.7296ZM74.8878 28.6663C74.4298 27.1921 74.172 26.3579 74.1143 26.1638C74.0567 25.9711 74.0157 25.818 73.99 25.7057C73.8868 26.1046 73.5926 27.092 73.1057 28.6663H74.8878Z"
      fill="#222222" />
    <path
      d="M84.6089 31.5081H82.9633L81.1721 28.6279L80.5593 29.0678V31.5081H79.1094V24.6709H80.5593V27.7998L81.1296 26.996L82.9815 24.6724H84.5907L82.2049 27.6982L84.6089 31.5081Z"
      fill="#222222" />
    <path
      d="M90.4628 29.6077C90.4628 30.2249 90.2414 30.7118 89.797 31.0667C89.3526 31.4216 88.7353 31.5991 87.9421 31.5991C87.2126 31.5991 86.568 31.4626 86.0068 31.188V29.8412C86.4679 30.0475 86.8592 30.1916 87.1777 30.2765C87.4977 30.3599 87.7889 30.4024 88.0544 30.4024C88.3729 30.4024 88.617 30.3417 88.7869 30.2204C88.9568 30.0991 89.0417 29.9186 89.0417 29.6774C89.0417 29.544 89.0038 29.4241 88.9295 29.3195C88.8552 29.2148 88.7444 29.1147 88.6004 29.0177C88.4548 28.9206 88.1605 28.7674 87.7146 28.5551C87.2975 28.3594 86.9836 28.1698 86.7743 27.9894C86.565 27.8089 86.3981 27.598 86.2738 27.3584C86.1494 27.1188 86.0872 26.8382 86.0872 26.5167C86.0872 25.9115 86.292 25.4368 86.7015 25.091C87.111 24.7452 87.6782 24.5723 88.4017 24.5723C88.7566 24.5723 89.0963 24.6147 89.4194 24.6982C89.7424 24.7816 90.0791 24.9014 90.4325 25.0531L89.9654 26.18C89.5999 26.0298 89.2996 25.9252 89.0599 25.866C88.8218 25.8068 88.5867 25.7765 88.3562 25.7765C88.0817 25.7765 87.8708 25.8402 87.7252 25.9676C87.5781 26.095 87.5053 26.2618 87.5053 26.4681C87.5053 26.5955 87.5356 26.7078 87.5948 26.8033C87.6539 26.8989 87.748 26.9899 87.8784 27.0793C88.0073 27.1688 88.3137 27.3281 88.7975 27.5586C89.4361 27.8635 89.8744 28.1698 90.111 28.4777C90.3445 28.7856 90.4628 29.1617 90.4628 29.6077Z"
      fill="#222222" />
    <path
      d="M98.2618 31.5081H96.8164V28.5566H94.1091V31.5081H92.6592V24.6709H94.1091V27.3509H96.8164V24.6709H98.2618V31.5081Z"
      fill="#222222" />
    <path
      d="M105.13 31.5085L104.634 29.8811H102.142L101.646 31.5085H100.084L102.497 24.644H104.27L106.692 31.5085H105.13ZM104.288 28.6663C103.83 27.1921 103.572 26.3579 103.515 26.1638C103.457 25.9711 103.416 25.818 103.39 25.7057C103.287 26.1046 102.993 27.092 102.506 28.6663H104.288Z"
      fill="#EC7E25" />
    <path
      d="M112.696 31.5085L112.2 29.8811H109.709L109.213 31.5085H107.65L110.063 24.644H111.836L114.259 31.5085H112.696ZM111.855 28.6663C111.397 27.1921 111.139 26.3579 111.081 26.1638C111.023 25.9711 110.983 25.818 110.957 25.7057C110.854 26.1046 110.559 27.092 110.073 28.6663H111.855Z"
      fill="#EC7E25" />
    <path
      d="M58.6133 10.2097C58.6133 12.9094 56.9389 14.7021 54.4955 14.7021C53.6279 14.7021 52.8802 14.4261 52.269 13.9332V18.0707H49.4131V5.85683H52.0733L52.1522 6.50749C52.7634 5.97514 53.5506 5.65967 54.497 5.65967C56.9389 5.67787 58.6133 7.56917 58.6133 10.2097ZM55.7377 10.2097C55.7377 9.04794 55.0278 8.12124 53.9647 8.12124C52.7043 8.12124 52.1522 8.92963 52.1522 10.19C52.1522 11.4701 52.8226 12.239 53.9647 12.239C55.0278 12.239 55.7377 11.4306 55.7377 10.2097Z"
      fill="#222222" />
    <path
      d="M66.4751 5.73707L66.2976 8.04243C66.199 8.02271 66.1004 8.00299 65.7652 8.00299C64.2486 8.00299 63.2036 8.81139 63.2036 9.95345V14.5232H60.3477V5.85537H63.0079L63.1262 6.91857C63.6389 6.12989 64.4852 5.6582 65.747 5.6582C66.0413 5.6582 66.3173 5.69764 66.4751 5.73707Z"
      fill="#222222" />
    <path
      d="M76.424 10.1703C76.424 12.8109 74.5327 14.7204 71.7556 14.7204C68.9589 14.7204 67.1055 12.8094 67.1055 10.1703C67.1055 7.56922 68.9573 5.6582 71.7556 5.6582C74.5327 5.6582 76.424 7.56922 76.424 10.1703ZM73.5468 10.1703C73.5468 8.96912 72.837 8.12129 71.7541 8.12129C70.6515 8.12129 69.9811 8.96912 69.9811 10.1703C69.9811 11.411 70.6515 12.2588 71.7541 12.2588C72.8385 12.2588 73.5468 11.411 73.5468 10.1703Z"
      fill="#222222" />
    <path
      d="M84.6991 14.19C84.1471 14.5055 83.2613 14.7224 82.2163 14.7224C80.0687 14.7224 78.6703 13.5803 78.6703 11.551V8.18242H77.4297V5.85735H78.6703V3.63086H81.5262V5.85735H84.2062V8.16271H81.5262V11.1764C81.5262 11.965 81.9995 12.2395 82.688 12.2395C83.1021 12.2395 83.4964 12.1804 84.0075 11.9635L84.6991 14.19Z"
      fill="#222222" />
    <path
      d="M94.2738 10.9575H88.3633C88.6393 11.7857 89.3886 12.3362 90.5701 12.3362C91.5165 12.3362 92.0473 12.139 92.5205 11.8236L93.6823 13.6754C93.0119 14.1684 92.0079 14.7189 90.3729 14.7189C87.3593 14.7189 85.4482 12.8079 85.4482 10.1689C85.4482 7.46917 87.241 5.65674 89.9209 5.65674C92.6388 5.65674 94.3542 7.27201 94.3542 10.1886C94.3527 10.4464 94.3132 10.8013 94.2738 10.9575ZM88.3633 9.26341H91.7319C91.5544 8.4353 91.0418 8.00305 90.0969 8.00305C89.249 8.00305 88.5999 8.37767 88.3633 9.26341Z"
      fill="#222222" />
    <path
      d="M103.71 9.48019V14.5232H100.991L100.971 14.0105C100.38 14.464 99.5925 14.7203 98.7644 14.7203C96.7943 14.7203 95.3564 13.6177 95.3564 11.8447C95.3564 9.9534 96.8337 8.94935 99.0799 8.94935C99.77 8.94935 100.419 9.10708 100.932 9.44227V9.34369C100.932 8.37908 100.301 7.827 98.725 7.827C97.7407 7.827 97.1886 8.02417 96.6957 8.20163L96.9323 5.95542C97.3266 5.83712 98.1335 5.65967 99.3757 5.65967C102.271 5.65815 103.71 7.27341 103.71 9.48019ZM100.932 11.8447C100.932 11.4109 100.597 10.8407 99.5728 10.8407C98.7644 10.8407 98.2336 11.235 98.2336 11.8447C98.2336 12.4559 98.766 12.829 99.5728 12.829C100.419 12.829 100.932 12.4165 100.932 11.8447Z"
      fill="#222222" />
    <path
      d="M114.308 9.24364V14.5232H111.431V9.36194C111.431 8.67185 110.958 8.10158 110.013 8.10158C109.205 8.10158 108.615 8.61422 108.615 9.38166V14.5232H105.759V5.85537H108.439L108.518 6.60461C108.971 6.03282 109.719 5.6582 110.862 5.6582C113.127 5.6582 114.308 6.99895 114.308 9.24364Z"
      fill="#222222" />
    <path
      d="M49.4131 21.0267C54.8292 20.787 60.2437 20.6612 65.6598 20.5747C68.3671 20.5216 71.0759 20.5095 73.7831 20.4776L81.9065 20.4458L90.0298 20.4761L94.0915 20.5171L96.1224 20.5383L98.1532 20.5717C103.569 20.6566 108.984 20.7825 114.4 21.0252C108.984 21.2663 103.569 21.3937 98.1532 21.4787L96.1224 21.512L94.0915 21.5333L90.0298 21.5742L81.9065 21.6045L73.7831 21.5727C71.0759 21.5408 68.3671 21.5287 65.6598 21.4756C60.2437 21.3922 54.8292 21.2663 49.4131 21.0267Z"
      fill="#EC7E25" />
  </svg>
</template>