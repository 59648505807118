<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_501_421)">
      <path
        d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0V0ZM12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92893C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.7612C17.6541 3.51808 19.2159 4.79981 20.3147 6.4443C21.4135 8.08879 22 10.0222 22 12C21.9971 14.6513 20.9426 17.1931 19.0679 19.0679C17.1931 20.9426 14.6513 21.9971 12 22Z"
        fill="currentColor" />
      <path
        d="M17.515 14.143L13 11.434V6C13 5.73478 12.8946 5.48043 12.7071 5.29289C12.5196 5.10536 12.2652 5 12 5C11.7348 5 11.4804 5.10536 11.2929 5.29289C11.1054 5.48043 11 5.73478 11 6V12C11.0067 12.1712 11.0542 12.3382 11.1386 12.4873C11.223 12.6364 11.3417 12.7631 11.485 12.857L16.485 15.857C16.5976 15.9275 16.7231 15.9748 16.8542 15.9962C16.9853 16.0176 17.1194 16.0127 17.2486 15.9818C17.3778 15.9508 17.4995 15.8944 17.6066 15.8159C17.7138 15.7373 17.8042 15.6382 17.8726 15.5244C17.941 15.4105 17.9861 15.2842 18.0051 15.1527C18.0242 15.0212 18.0169 14.8873 17.9836 14.7587C17.9503 14.6301 17.8917 14.5094 17.8112 14.4037C17.7308 14.298 17.6301 14.2094 17.515 14.143Z"
        fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_501_421">
        <rect
          width="24"
          height="24"
          fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>