<template>
  <svg
    width="17"
    height="12"
    viewBox="0 0 17 12"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.2975 5.27461L13.2324 3.11771C11.0755 1.58518 8.29426 1.30137 5.85356 2.15278L3.86694 0.222924C3.6399 -0.00411766 3.29933 -0.00411766 3.07229 0.222924C2.84525 0.449966 2.84525 0.790529 3.07229 1.01757L4.71835 2.66363C4.43454 2.77715 4.2075 2.94743 3.98046 3.11771L0.915394 5.27461C0.745113 5.38813 0.631592 5.55841 0.631592 5.72869C0.631592 5.89897 0.745113 6.06925 0.858634 6.18277L3.9237 8.33967C5.28595 9.3046 6.932 9.75869 8.5213 9.75869C9.42947 9.75869 10.3944 9.5884 11.2458 9.3046L13.1757 11.2345C13.2892 11.348 13.4595 11.4047 13.573 11.4047C13.6865 11.4047 13.8568 11.348 13.9703 11.2345C14.1973 11.0074 14.1973 10.6669 13.9703 10.4398L12.3242 8.79376C12.6081 8.68024 12.8351 8.50995 13.0621 8.33967L16.1272 6.18277C16.2975 6.06925 16.3542 5.89897 16.3542 5.72869C16.3542 5.55841 16.411 5.38813 16.2975 5.27461ZM4.60483 7.37474L2.22089 5.72869L4.60483 4.08264C4.88863 3.8556 5.22919 3.68531 5.56975 3.51503L6.6482 4.59348C6.42116 4.93404 6.30764 5.33137 6.30764 5.72869C6.30764 6.97742 7.32933 7.99911 8.57806 7.99911C8.97538 7.99911 9.3727 7.88559 9.71327 7.65855L10.3944 8.33967C8.46454 8.90728 6.30764 8.56672 4.60483 7.37474ZM9.65651 6.01249L8.29426 4.65024C8.40778 4.65024 8.46454 4.59348 8.57806 4.59348C9.20242 4.59348 9.71327 5.10433 9.71327 5.72869C9.71327 5.84221 9.71327 5.89897 9.65651 6.01249ZM8.86186 6.80714C8.74834 6.8639 8.69158 6.8639 8.57806 6.8639C7.95369 6.8639 7.44285 6.35306 7.44285 5.72869C7.44285 5.61517 7.44285 5.55841 7.49961 5.44489L8.86186 6.80714ZM12.5513 7.37474C12.2675 7.60179 11.9269 7.77207 11.5864 7.94235L10.5079 6.8639C10.735 6.52334 10.8485 6.12601 10.8485 5.72869C10.8485 4.47996 9.82679 3.45827 8.57806 3.45827C8.18073 3.45827 7.78341 3.57179 7.44285 3.79883L6.76172 3.11771C8.69158 2.5501 10.8485 2.89067 12.5513 4.08264L14.9352 5.72869L12.5513 7.37474Z"
      fill="currentColor" />
  </svg>
</template>