<template>
  <svg
    width="8"
    height="5"
    viewBox="0 0 8 5"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.43107 1.18229e-05H0.574687C0.422505 -0.000990149 0.276239 0.0617312 0.168349 0.174253C0.0604539 0.286673 -9.53868e-05 0.439579 1.12797e-07 0.598994C9.57594e-05 0.758405 0.0609313 0.911208 0.169017 1.02343L3.59721 4.61445C3.70424 4.72597 3.84906 4.78849 4 4.78849C4.15094 4.78849 4.29575 4.72597 4.40279 4.61445L7.83098 1.02343C7.93859 0.911708 7.99942 0.759719 7.99999 0.600995C8.00066 0.442285 7.94107 0.289782 7.83432 0.177075C7.72767 0.0643553 7.58257 0.000630041 7.43105 2.98268e-05L7.43107 1.18229e-05Z"
      fill="currentColor" />
  </svg>
</template>