<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none">
    <path
      d="M9.03833 20H15.0383V21C15.0383 21.55 14.5883 22 14.0383 22H10.0383C9.48833 22 9.03833 21.55 9.03833 21V20ZM19.0383 9C19.0383 11.38 17.8483 13.47 16.0383 14.74V17C16.0383 17.55 15.5883 18 15.0383 18H9.03833C8.48833 18 8.03833 17.55 8.03833 17V14.74C6.22833 13.47 5.03833 11.38 5.03833 9C5.03833 5.13 8.16833 2 12.0383 2C15.9083 2 19.0383 5.13 19.0383 9ZM14.7483 8.29C14.3583 7.9 13.7183 7.9 13.3283 8.29L12.0383 9.59L10.7483 8.29C10.3583 7.9 9.71833 7.9 9.32833 8.29C8.93833 8.68 8.93833 9.32 9.32833 9.71L11.0383 11.41V16H13.0383V11.41L14.7483 9.71C15.1383 9.32 15.1383 8.68 14.7483 8.29Z"
      fill="currentColor" />
  </svg>
</template>