
import PoweredBy from "./PoweredBy.vue";

export default {
  name: "FooterView",
  components: {
    PoweredBy,
  },
  props: ["footerStyle", "footerHide", "footerLogo"],
};
