<template>
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.9222 12.9374H7.91274V14.6251C7.91274 15.52 8.27003 16.3784 8.9061 17.0114C9.54196 17.6444 10.4046 18 11.304 18H16.9564C17.8556 18 18.7182 17.6444 19.3543 17.0114C19.9904 16.3784 20.3477 15.52 20.3477 14.6251V3.37494C20.3477 2.48 19.9904 1.62156 19.3543 0.988568C18.7182 0.355574 17.8556 0 16.9564 0H11.304C10.4046 0 9.54196 0.355574 8.9061 0.988568C8.27003 1.62156 7.91274 2.48 7.91274 3.37494V5.0626C7.91274 5.37316 8.16576 5.62496 8.47802 5.62496C8.79009 5.62496 9.04311 5.37316 9.04311 5.0626V3.37494C9.04386 2.77849 9.28231 2.20654 9.70605 1.78486C10.13 1.36299 10.7047 1.12569 11.304 1.12511H16.9564C17.5557 1.12567 18.1304 1.36297 18.5541 1.78486C18.9781 2.20654 19.2165 2.77849 19.2171 3.37494V14.6251C19.2165 15.2215 18.9781 15.7935 18.5541 16.2151C18.1304 16.637 17.5557 16.8743 16.9564 16.8749H11.304C10.7047 16.8743 10.13 16.637 9.70605 16.2151C9.28233 15.7935 9.04388 15.2215 9.04311 14.6251V12.9374H9.03364C9.01339 12.647 8.77059 12.4216 8.478 12.4216C8.18524 12.4216 7.94242 12.647 7.92217 12.9374H7.9222ZM14.6956 8.9999V9.00009C14.6958 9.14925 14.6362 9.29239 14.5302 9.39785C14.4242 9.50332 14.2804 9.56264 14.1303 9.56246H1.92939L5.12104 12.7389H5.12123C5.3381 12.9593 5.33621 13.3122 5.11706 13.5303C4.89792 13.7484 4.54325 13.7501 4.32185 13.5342L0.165234 9.39767H0.165422C0.0594444 9.2922 -0.000167847 9.14926 -0.000167847 9.00009C-0.000167847 8.85093 0.0594444 8.7078 0.165422 8.60233L4.32185 4.46577C4.54327 4.24993 4.89793 4.25182 5.11706 4.46991C5.3362 4.688 5.3381 5.04095 5.12122 5.26128L1.92957 8.43754H14.1305C14.2804 8.43735 14.4242 8.49667 14.5302 8.60214C14.6362 8.70761 14.6958 8.85074 14.6956 9.00009L14.6956 8.9999Z"
      fill="currentColor" />
  </svg>
</template>