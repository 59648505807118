import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bg-skyBlue-50 w-full h-[50px] rounded-lg px-2 sm:px-5 md:px-9 flex items-center justify-between"
}
const _hoisted_2 = {
  key: 0,
  class: "text-theme-500 font-medium text-xl"
}
const _hoisted_3 = { class: "flex items-center relative" }
const _hoisted_4 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["shadow-md rounded-xl overflow-hidden bg-white relative p-1", $props.cardContainerStyle])
  }, [
    ($props.cardItemTitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          ($props.cardItemTitle)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString($props.cardItemTitle), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "cardItemHeaderRight")
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "cardItemBody")
    ])
  ], 2))
}