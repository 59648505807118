<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_940_3420)">
      <path
        d="M0.666748 6H15.3334"
        stroke="#00C7B2"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square" />
      <path
        d="M14.0001 2H2.00008C1.2637 2 0.666748 2.59695 0.666748 3.33333V14C0.666748 14.7364 1.2637 15.3333 2.00008 15.3333H14.0001C14.7365 15.3333 15.3334 14.7364 15.3334 14V3.33333C15.3334 2.59695 14.7365 2 14.0001 2Z"
        stroke="#00C7B2"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square" />
      <path
        d="M4.00018 0.666687L3.99951 3.33335"
        stroke="#00C7B2"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square" />
      <path
        d="M12.0002 0.666687L11.9995 3.33335"
        stroke="#00C7B2"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square" />
    </g>
    <defs>
      <clipPath id="clip0_940_3420">
        <rect
          width="16"
          height="16"
          fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>