<template>
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="32"
      cy="32"
      r="32"
      fill="currentColor" />
    <path
      d="M43.9712 21.7778H40.7981V24.9028C38.1916 23.7917 35.316 23.1667 32.2988 23.1667C27.5108 23.1667 23.0912 24.7222 19.5498 27.3333L17 24C21.2638 20.8611 26.5617 19 32.2988 19C36.5059 19 40.4723 20.0139 43.9712 21.7778ZM27.1992 37.3333L32.2988 44L37.3984 37.3333C35.9818 36.2917 34.2111 35.6667 32.2988 35.6667C30.3864 35.6667 28.6157 36.2917 27.1992 37.3333ZM22.0996 30.6667L24.6494 34C26.7742 32.4306 29.4232 31.5 32.2988 31.5C35.1744 31.5 37.8233 32.4306 39.9481 34L40.7981 32.8889V29.5833C38.2908 28.1528 35.401 27.3333 32.2988 27.3333C28.4741 27.3333 24.9469 28.5694 22.0996 30.6667ZM43.6312 38.4444H46.4643V35.6667H43.6312V38.4444ZM43.6312 24.5556V32.8889H46.4643V24.5556H43.6312Z"
      fill="#FFFFFF" />
  </svg>
</template>