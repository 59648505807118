<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1951 12.878V17.8537C12.1951 19.7001 13.0156 20.4878 14.939 20.4878H19.6808C20.0595 19.4683 21.0654 18.7317 22.256 18.7317C23.769 18.7317 24.9999 19.9139 24.9999 21.3659C24.9999 22.8178 23.769 24 22.256 24C21.0654 24 20.0595 23.2634 19.6808 22.2439H14.939C11.9897 22.2439 10.3659 20.6844 10.3659 17.8537V12.878H5.3193C4.94039 13.8976 3.93472 14.6341 2.74388 14.6341C1.23088 14.6341 0 13.4519 0 12C0 10.5481 1.23088 9.36587 2.74388 9.36587C3.93451 9.36587 4.94039 10.1024 5.31909 11.122H10.3658V6.14631C10.3658 3.31559 11.9897 1.75609 14.939 1.75609H19.6807C20.0595 0.736553 21.0651 0 22.256 0C23.769 0 24.9998 1.1822 24.9998 2.63413C24.9998 4.08622 23.769 5.26826 22.256 5.26826C21.0653 5.26826 20.0595 4.53171 19.6807 3.51218H14.939C13.0156 3.51218 12.1951 4.29986 12.1951 6.14631V11.122H19.6809C20.0596 10.1024 21.0653 9.36587 22.2561 9.36587C23.7691 9.36587 25 10.5481 25 12C25 13.4519 23.7691 14.6341 22.2561 14.6341C21.0655 14.6341 20.0596 13.8976 19.6809 12.878H12.1951Z"
      fill="currentColor" />
  </svg>
</template>