import { BaseErrorHandlerFactory, HandlerContext } from "@/conductor/ErrorHandlers/BaseErrorHandlerFactory";
import { ConductorEvent, ErrorCodes, ErrorMessages, ErrorTypes } from "@/conductor/journey-constants";
import { eventPayload } from "../conductor";

export class ConsentRequestErrorHandler extends BaseErrorHandlerFactory {
  constructor(ctx: HandlerContext) {
    super(ctx);
  }

  processError(err: any): any {
    if (err?.name === "StatusError") {
      const {
        discriminator,
        aaHandle,
      } = this.storeToRefs;
      const responseObj = err.context;
      // TODO: handle non-success scenario for getting linked accounts.
      this.events.fire(ConductorEvent.AA_CONSENT_DETAILS_RESOLUTION_FAILED, eventPayload(this.store.requestId, discriminator.value, {
        aaHandle: aaHandle.value,
        retriable: false,
      }, this.aaErrorContext(responseObj)));
      this.handleBadRequest(ErrorCodes.FAILURE, ErrorTypes.FLOW_ERROR, "FAILURE", true, this.aaErrorContext(responseObj));
      // invoke troubleshoot helper
      // invokeTroubleshoot(ctx);
      this.log.debug("Failed to get consent details with consent handle:", this.store.consentHandle);
      this?.context?.fireJourneyEvents(ConductorEvent.AA_CONSENT_DETAILS_RESOLUTION_FAILED);
    } else {
      if (err === ErrorMessages.SESSION_EXPIRED) {
        this.handleSessionError();
      }
      this.log.fatal("Error while trying to get consent details with consent handle:", this.store.consentHandle, err);
    }
  }
}