<template>
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.66675 11.8334L7.00008 6.50002L11.0001 10.5L16.3334 5.16669"
      stroke="#00C7B2"
      stroke-width="2"
      stroke-miterlimit="10" />
  </svg>
</template>