import moment, { Moment } from "moment/moment";
import { BaseErrorHandlerFactory, HandlerContext } from "@/conductor/ErrorHandlers/BaseErrorHandlerFactory";
import { CAUSE, ConductorEvent, ErrorCodes, ErrorMessages, ErrorTypes } from "@/conductor/journey-constants";
import { DiscoveryStatus, Institution } from "@/conductor/models";

export class AccountDiscoveryErrorHandler extends BaseErrorHandlerFactory {
  constructor(ctx: HandlerContext) {
    super(ctx);
  }

  processError(err: any, extraParams: { auto: boolean; institution: Institution; request: any; start: Moment }): any {
    const {
      auto,
      institution,
      request,
      start,
    } = extraParams || {};
    const latency = moment.duration(moment().diff(start)).asMilliseconds();
    const { aaHandle } = this.storeToRefs;

    if (err?.name === "StatusError") {
      const result: { success: boolean; error: any; badRequest: any; sessionError: boolean } = {
        success: false,
        error: null,
        badRequest: null,
        sessionError: false,
      };
      const responseObj = err.context;
      switch (responseObj.status) {
        case "BAD_REQUEST": {
          result.badRequest = {
            institution: institution.id,
            error: responseObj?.message,
          } as any;
          this.store.updateDiscoveryStatus({
            status: DiscoveryStatus.FAILED,
            discovering: false,
            auto,
          }, institution);
          this.context?.fireJourneyEvents(ConductorEvent.ACCOUNTS_DISCOVERY_FAILED, {}, {}, {
            aaHandle: aaHandle.value,
            fipId: institution.id,
            auto: auto,
            ...this.aaErrorContext(responseObj),
          });
          if (!auto && !this.storeToRefs.somethingLoading.value) {
            this.handleBadRequest(ErrorCodes.BAD_REQUEST, ErrorTypes.FLOW_ERROR, responseObj?.message, true, this.aaErrorContext(responseObj));
          }
          break;
        }
        case "FAILURE": {
          result.badRequest = {
            institution: institution.id,
            error: responseObj?.message,
          } as any;
          this.store.updateDiscoveryStatus({
            status: DiscoveryStatus.FAILED,
            discovering: false,
            auto,
          }, institution);
          this.context?.fireJourneyEvents(ConductorEvent.ACCOUNTS_DISCOVERY_FAILED, {}, {}, {
            aaHandle: aaHandle.value,
            fipId: institution.id,
            auto: auto,
            ...this.aaErrorContext(responseObj),
          });
          break;
        }
        case "RETRY": {
          this.store.updateDiscoveryStatus({
            status: DiscoveryStatus.NO_ACCOUNTS,
            auto,
            discovering: false,
          }, institution);
          this.context?.fireJourneyEvents(ConductorEvent.ACCOUNTS_DISCOVERY_FAILED, {}, {}, {
            aaHandle: aaHandle.value,
            cause: CAUSE.NO_DATA, // type: CAUSE_TYPE.FIP_ERROR,
            fipId: institution.id,
            auto: auto,
            ...this.aaErrorContext(responseObj),
          });
          break;
        }

        case "FIP_UNAVAILABLE":
        case "UNKNOWN_ERROR":
        default: {
          result.error = {
            institution: institution.id,
            error: responseObj?.message || responseObj?.status,
          } as any;
          this.store.updateDiscoveryStatus({
            status: DiscoveryStatus.FAILED,
            auto,
            discovering: false,
          }, institution);
          this.context?.fireJourneyEvents(ConductorEvent.ACCOUNTS_DISCOVERY_FAILED, {}, {}, {
            aaHandle: aaHandle.value,
            cause: responseObj?.status === "FIP_UNAVAILABLE" ? CAUSE.FIP_UNAVAILABLE : CAUSE.UNKNOWN,
            fipId: institution.id,
            auto,
            ...this.aaErrorContext(responseObj),
          });
          break;
        }
      }

      this.context?.fireJourneyEvents(ConductorEvent.JOURNEY_METRIC, request, {}, {
        name: "FIP:AA:UserDiscoveryResponse",
        aaHandle: aaHandle.value,
        fipId: institution.id,
        latency,
        success: responseObj?.status === "SUCCESS",
      }, { internal: true });

      return result;
    } else {
      // maybe inform the caller that we could not talk to AA here?
      // if session has expired , restart the journey, take user to login
      if (err === ErrorMessages.SESSION_EXPIRED) {
        this.handleSessionError();
        return {
          success: false,
          sessionError: true,
        };
      } else {
        return {
          success: false,
          error: {
            institution: institution.id,
            error: err,
          },
        };
      }
    }
  }
}