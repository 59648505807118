

export default {
  name: "HeaderView",
  props: ["headerStyle", "imgSrc", "altName", "logoHide", "headerHide"],
  // props: {
  //   imgSrc: String,
  //   altName: String,
  //   logoShow: Boolean,
  // }
};
