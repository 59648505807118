<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.7621 0.000226632H3.77799C3.11121 0.000226632 2.57159 0.539879 2.57159 1.20663V2.57159H1.55561C0.69841 2.57159 0 3.27 0 4.1272V16.4446C0 17.3018 0.69841 18.0002 1.55561 18.0002H13.873C14.7302 18.0002 15.4286 17.3018 15.4286 16.4446V15.4286H16.7936C17.4604 15.4286 18 14.889 18 14.2222V1.2064C17.9683 0.539624 17.4287 0 16.7619 0L16.7621 0.000226632ZM14.3494 16.4129C14.3494 16.6985 14.1271 16.9209 13.8415 16.9209H1.55578C1.27012 16.9209 1.0478 16.6985 1.0478 16.4129V4.09545C1.0478 3.80979 1.27013 3.58747 1.55578 3.58747H13.8732C14.1589 3.58747 14.3812 3.8098 14.3812 4.09545L14.381 16.4129H14.3494ZM16.9208 14.1907C16.9208 14.2859 16.8573 14.3495 16.762 14.3495H15.3971V4.09555C15.3971 3.23836 14.6987 2.53995 13.8415 2.53995H3.61927V1.17498C3.61927 1.07976 3.68283 1.01621 3.77805 1.01621H16.7621C16.8574 1.01621 16.9209 1.07976 16.9209 1.17498L16.9208 14.1907Z"
      fill="currentColor" />
  </svg>
</template>