<template>
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.37478 4.69565V2.63022C2.37478 1.1776 3.65917 0 5.24426 0C6.82903 0 8.11374 1.17872 8.11374 2.63022V4.69565H8.69588C9.23881 4.69565 9.67895 5.16365 9.67895 5.73829V10.9574C9.67895 11.5332 9.23917 12 8.69588 12H1.79263C1.24971 12 0.80957 11.532 0.80957 10.9574V5.73829C0.80957 5.16242 1.24935 4.69565 1.79263 4.69565H2.37478ZM1.85298 5.73907V10.9566H8.63571V5.73907H1.85298ZM7.0705 4.69565V2.63022C7.0705 1.77582 6.27173 1.04344 5.24439 1.04344C4.21634 1.04344 3.41828 1.77504 3.41828 2.63022V4.69565H7.0705ZM4.72269 7.7136C4.72269 7.42554 4.95423 7.19199 5.24439 7.19199C5.53255 7.19199 5.7661 7.42346 5.7661 7.7136V9.27899C5.7661 9.56715 5.53456 9.80061 5.24439 9.80061C4.95624 9.80061 4.72269 9.56914 4.72269 9.27899V7.7136Z"
      fill="currentColor" />
  </svg>
</template>