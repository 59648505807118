<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.33333 1.5H7.1C5.13982 1.5 4.15972 1.5 3.41103 1.88148C2.75247 2.21703 2.21703 2.75247 1.88148 3.41103C1.5 4.15972 1.5 5.13982 1.5 7.1V7.33333M7.33333 22.5H7.1C5.13982 22.5 4.15972 22.5 3.41103 22.1185C2.75247 21.783 2.21703 21.2475 1.88148 20.589C1.5 19.8403 1.5 18.8602 1.5 16.9V16.6667M22.5 7.33333V7.1C22.5 5.13982 22.5 4.15972 22.1185 3.41103C21.783 2.75247 21.2475 2.21703 20.589 1.88148C19.8403 1.5 18.8602 1.5 16.9 1.5H16.6667M22.5 16.6667V16.9C22.5 18.8602 22.5 19.8403 22.1185 20.589C21.783 21.2475 21.2475 21.783 20.589 22.1185C19.8403 22.5 18.8602 22.5 16.9 22.5H16.6667M1.5 12H1.51167M6.75 12H6.76167M17.25 12H17.2617M12 12H12.0117M22.5 12H22.5117"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>