<template>
  <svg
    width="32"
    height="19"
    viewBox="0 0 32 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.3125 8.76562V10.4609H23.5156V8.76562H31.3125ZM28.3281 5.57812V13.8594H26.5078V5.57812H28.3281Z"
      fill="currentColor" />
    <path
      d="M17.9975 4.67611C17.9776 4.71726 17.9571 4.75772 17.9392 4.79886C17.8021 5.11019 17.5696 5.28848 17.2261 5.31249C17.1623 5.31729 17.0978 5.31729 17.0334 5.31729C11.674 5.31729 6.31467 5.31591 0.955323 5.31317C0.775203 5.31735 0.596291 5.28274 0.430726 5.21168C0.137226 5.07453 -0.00335201 4.81189 7.67305e-05 4.48616C-0.0020909 4.33225 0.041712 4.18118 0.125881 4.0523C0.21005 3.92342 0.330756 3.82259 0.472557 3.7627C0.964923 3.53297 1.46278 3.31353 1.95857 3.09066L8.5342 0.133035C8.63981 0.0850327 8.74884 0.0438878 8.85582 0H9.13698C9.25972 0.0521168 9.38385 0.101491 9.50591 0.155665C12.1145 1.33195 14.7235 2.50435 17.333 3.67286C17.6574 3.81824 17.9029 4.00751 17.9975 4.35861V4.67611Z"
      fill="currentColor" />
    <path
      d="M18 17.2635C17.7943 17.8594 17.594 17.9993 16.9556 17.9993C11.6182 17.9993 6.28126 17.9993 0.944775 17.9993C0.628645 17.9993 0.35366 17.9239 0.157537 17.6565C0.074846 17.544 0.0225981 17.4121 0.00585346 17.2735C-0.0108912 17.1349 0.008435 16.9944 0.0619601 16.8655C0.115485 16.7366 0.201395 16.6237 0.311368 16.5377C0.421342 16.4517 0.551652 16.3956 0.689677 16.3748C0.794 16.3609 0.899191 16.3544 1.00443 16.3556H16.9625C17.5885 16.3556 17.7943 16.5003 18 17.0852V17.2635Z"
      fill="currentColor" />
    <path
      d="M15.963 6.87741V14.2149C16.1053 14.2244 16.2466 14.2439 16.3861 14.2732C16.5652 14.3239 16.721 14.4354 16.8267 14.5887C16.9323 14.7419 16.9812 14.9272 16.9649 15.1125C16.9203 15.5329 16.622 15.8292 16.2106 15.8593C16.1352 15.8655 16.059 15.8662 15.9829 15.8662C11.3253 15.8662 6.6675 15.8662 2.00945 15.8662C1.8696 15.8709 1.72978 15.8563 1.59389 15.823C1.41274 15.7684 1.25673 15.6516 1.15345 15.4931C1.05016 15.3346 1.00627 15.1446 1.02952 14.9569C1.05173 14.766 1.14072 14.5891 1.28076 14.4574C1.42081 14.3258 1.60289 14.2479 1.79481 14.2375C1.86956 14.2334 1.94499 14.2375 2.03139 14.2375V6.88358C1.94979 6.87467 1.86407 6.86918 1.77972 6.85547C1.68788 6.84269 1.60342 6.79813 1.54105 6.72952C1.47867 6.66091 1.44232 6.5726 1.43832 6.47996C1.43433 6.38732 1.46294 6.29621 1.51918 6.22249C1.57542 6.14876 1.65572 6.09709 1.74612 6.07646C1.82014 6.05967 1.89584 6.05139 1.97173 6.05177C6.6531 6.05177 11.3342 6.05177 16.0152 6.05177H16.068C16.369 6.05863 16.559 6.21841 16.559 6.46322C16.559 6.70803 16.3848 6.85204 16.0837 6.86987L15.963 6.87741ZM10.2172 6.8829H7.7807V14.2204H10.2185L10.2172 6.8829ZM14.3042 14.2266V6.88427H11.8691V14.2266H14.3042ZM3.69227 14.2266H6.11708V6.87947H3.69227V14.2266Z"
      fill="currentColor" />
  </svg>
</template>