import { Ref } from "vue";
import { Logger } from "tslog";
import { ConductorEvent, ErrorCodes, ErrorTypes, InternalConductorEvent } from "@/conductor/journey-constants";
import eventEmitter from "@/composables/event.emitter";
import { Store, storeToRefs } from "pinia";
import { JOURNEY_LAYOUTS } from "@/constants/constants";

export type HandlerContext = {
  denyAndExit: any;
  retryLogin: any;
  layoutView: string;
  sessionError: Ref<any>;
  store: Store;
  events: any;
  fireJourneyEvents: any;
};

export class BaseErrorHandlerFactory {
  context: HandlerContext;
  store: any;
  storeToRefs: any;
  events: any;
  log: Logger<any>;

  constructor(ctx: HandlerContext) {
    this.context = ctx;
    this.store = ctx.store;
    this.storeToRefs = storeToRefs(ctx.store);
    this.events = ctx.events;
    this.log = new Logger({
      name: "[conductor-lite]", // prefix: ['[conductor-lite]'],
      prettyLogTimeZone: "local",
      hideLogPositionForProduction: true, // process.env.NODE_ENV !== 'production'
    });
  }

  createErrorHandler<T extends BaseErrorHandlerFactory>(ErrorClass: new (context: any) => T) {
    return new ErrorClass(this.context);
  }

  eventPayload = (requestId: string, discriminator: number, fields?: Record<string, any>, aaErrorCtx = {}) => {
    return {
      requestId,
      discriminator,
      timestamp: new Date().toISOString(), ...fields, ...aaErrorCtx,
    };
  };

  _raiseErrorEventForAA = (errorMessage: string, errorCode = "AA_ERROR", errorType = "INSTITUTION_ERROR", extraParams: any = {}) => {
    this.events.fire(ConductorEvent.ERROR, this.eventPayload(this.store.requestId, this.storeToRefs.discriminator.value, {
      errorCode,
      errorType,
      errorMessage, ...extraParams,
    }));
  };

  invokeTroubleshoot = (ctx: any, resetData = false) => {
    // modify the onPrimary call handler to add state clean as well
    const _primaryHandler = ctx.onPrimary;
    const _secondaryHandler = ctx.onSecondary;
    const { troubleshootError } = this.storeToRefs;

    ctx.onPrimary = () => {
      // now invoke the supplied primary handler
      if (_primaryHandler) {
        // ensure handler is present
        _primaryHandler();
      }

      if (resetData) {
        troubleshootError.value = undefined;
      }
    };

    ctx.onSecondary = () => {
      // now invoke the supplied secondary handler
      if (_secondaryHandler) {
        // ensure handler is present
        _secondaryHandler();
      }
    };
    troubleshootError.value = ctx;
  };

  aaErrorContext = (response: any) => {
    const context = {} as any;
    if (response?.aaOperation || response?.data?.aaOperation || response.reason?.data?.aaOperation) {
      context.aaOperation = response?.aaOperation || response.data?.aaOperation || response.reason?.data?.aaOperation;
    }
    if (response?.aaOpAttempt || response?.data?.aaOpAttempt || response?.reason?.data?.aaOpAttempt) {
      context.aaOpAttempt = response?.aaOpAttempt || response?.data?.aaOpAttempt || response?.reason?.data?.aaOpAttempt;
    }
    if (response?.aaErrorCode || response?.data?.aaErrorCode || response?.reason?.data?.aaErrorCode) {
      context.aaErrorCode = response?.aaErrorCode || response?.data?.aaErrorCode || response?.reason?.data?.aaErrorCode;
    }
    if (response?.aaMessage || response?.data?.aaMessage || response?.reason?.data?.aaMessage) {
      context.aaMessage = response?.aaMessage || response?.data?.aaMessage || response?.reason?.data?.aaMessage;
    }
    return context;
  };

  private handleError = (error: {
    errorCode: ErrorCodes;
    errorType: ErrorTypes;
    errorMessage: string;
    aaError: boolean;
    aaErrorCtx: object;
    conductorEvent: InternalConductorEvent;
  }) => {
    const {
      errorCode,
      errorMessage,
      errorType,
      aaErrorCtx,
      aaError,
      conductorEvent,
    } = error;

    const {
      aaHandle,
      lastKnownStatus,
    } = this.storeToRefs;

    const ctx = {
      error: true,
      title: "Sorry, could not fetch details",
      useDefaultDesc: true,
      primaryText: "Try other method",
      onPrimary: () => this.context?.denyAndExit?.(errorMessage, // message for SDK users
        errorCode, // errorCode
        false, // false => don't exit once consent is rejected
        errorType, // errorType
      ),
    };
    if (aaError) {
      // some issue with the data sent to AA, hence exit the journey
      this._raiseErrorEventForAA("Some issue in the request that is sent to AA", ConductorEvent.AA_ERROR, ErrorTypes.INSTITUTION_ERROR, {
        aaHandle: aaHandle.value,
        retriable: false,
        status: lastKnownStatus.value, ...aaErrorCtx,
      });
    }
    // invoke troubleshoot helper
    this.invokeTroubleshoot(ctx);
    eventEmitter.emit(conductorEvent, {
      errorCode,
      errorType,
      errorMessage,
      aaError,
    });
  };

  handleBadRequest = (errorCode = ErrorCodes.BAD_REQUEST, errorType = ErrorTypes.API_ERROR, errorMessage = "Bad request", aaError = true, aaErrorCtx = {}) => {
    // handle common bad request and generate terminal error
    this.handleError({
      errorType,
      errorMessage,
      errorCode,
      aaError,
      aaErrorCtx,
      conductorEvent: InternalConductorEvent.BAD_REQUEST,
    });
  };

  handleFailures = (errorCode = ErrorCodes.FAILURE, errorType = ErrorTypes.API_ERROR, errorMessage = "Failure", aaError = true, aaErrorCtx = {}) => {
    // handle common bad request and generate terminal error
    this.handleError({
      errorCode,
      errorType,
      errorMessage,
      aaError,
      aaErrorCtx,
      conductorEvent: InternalConductorEvent.FAILURES,
    });
  };

  handleSessionError = () => {
    if (this.context?.layoutView === JOURNEY_LAYOUTS.V2) {
      this.context.sessionError.value = true;
      return;
    }
    const ctx = {
      error: true,
      title: "Session timeout",
      useDefaultDesc: false,
      primaryText: "Retry Again",
      secondaryText: "Try Another Method",
      onSecondary: () => this.context.denyAndExit("Session Error", // message for SDK users
        ErrorCodes.ALTERNATIVE_CHOSEN, // errorCode
        false, // false => don't exit once consent is rejected
        ErrorTypes.INSTITUTION_ERROR, // errorType
      ),
      onPrimary: () => this.context.retryLogin(),
      btnStyle: "space-x-2",
    };
    this._raiseErrorEventForAA("Session timeout", "AA_ERROR", ErrorTypes.INSTITUTION_ERROR, {
      aaHandle: this.storeToRefs?.aaHandle.value,
      retriable: true,
      status: this.storeToRefs?.lastKnownStatus,
    });
    // invoke troubleshoot helper and ask user to restart the journey
    this.invokeTroubleshoot(ctx, true);
    eventEmitter.emit(InternalConductorEvent.SESSION_ERROR);
  };
}