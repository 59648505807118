<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none">
    <path
      d="M8.08329 0.833008L0.166626 4.99967V6.66634H16V4.99967L8.08329 0.833008ZM11.8333 8.33301V14.1663H14.3333V8.33301H11.8333ZM0.166626 18.333H16V15.833H0.166626V18.333ZM6.83329 8.33301V14.1663H9.33329V8.33301H6.83329ZM1.83329 8.33301V14.1663H4.33329V8.33301H1.83329Z"
      fill="currentColor" />
  </svg>
</template>