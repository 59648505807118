import { FinvuWebSdk } from "@finarkein/aasdk-finvu";
import { AaWebSdkController, InitOptions } from "@finarkein/aasdk-core";
import { NADLService } from "@finarkein/aasdk-nadl";
import { SaafeService } from "@finarkein/aasdk-saafe";
import { OnemoneyService } from "@finarkein/aasdk-onemoney";
import { ProteanService } from "@finarkein/aasdk-protean";

const PREFIX = "VUE_APP_TPCONFIG_AA_";

export class AaSdkAdapter {
  public registry: Map<string, AaWebSdkController>;
  public configs: Map<string, Record<string, any>>;

  constructor() {
    this.registry = new Map();
    this.configs = new Map();

    const autoResolved = this._autoLoad(); // this will be the superset
    for (const [handle, cfg] of autoResolved.entries()) {
      if (handle === "finvu") {
        // Finvu SDK registration
        this.addSdk(new FinvuWebSdk(), cfg);
      } else if (handle === "nadl") {
        this.addSdk(new NADLService(), cfg);
      } else if (handle === "saafe") {
        this.addSdk(new SaafeService(), cfg);
      } else if (handle === "onemoney") {
        this.addSdk(new OnemoneyService(), cfg);
      } else if (handle === "protean") {
        this.addSdk(new ProteanService(), cfg);
      }
    }
  }

  public addSdk(sdk: AaWebSdkController, config?: Record<string, any>) {
    this.registry.set(config?.handle || sdk.identifier(), sdk);
    if (!config) {
      config = this.loadSdkConfig(sdk.identifier());
      // FIXME: Do we need to check for empty config? Does it matter?
    }
    this.configs.set(config?.handle || sdk.identifier(), config);
    console.log(`[sdk-adapter] Resolved ${config?.handle || sdk.identifier()} configs`, config);
  }

  public aaHandles(checkAvailable = false, checkEnabled = true): Set<string> {
    const result = new Set<string>();
    for (const [handle, value] of this.configs.entries()) {
      if (checkAvailable && !this.registry.has(handle)) {
        continue; // no known sdk for this guy!
      }
      if (checkEnabled && value["enabled"] == "false") {
        continue; // available, not enabled
      }
      result.add(handle);
    }
    return result;
  }

  private _autoLoad(): Map<string, Record<string, any>> {
    const resolvedSDKs = new Map<string, Record<string, any>>();
    for (const [key, value] of Object.entries(process.env)) {
      if (key.startsWith(PREFIX)) {
        // resolve the AA identifier
        const splitted = key.split("_");
        if (splitted.length >= 5) {
          const extractedId = splitted[4].toLowerCase(); // use lowercase everywhere
          let configs = resolvedSDKs.get(extractedId);
          if (!configs) {
            configs = {};
            resolvedSDKs.set(extractedId, configs);
          }
          const prop = this._getProp(key, PREFIX.length + extractedId.length + 1);
          this._updateConfig(extractedId, prop, process.env, value, key, configs);
        }
      }
    }
    return resolvedSDKs;
  }

  private _getProp(src: string, offset: number) {
    return src.substring(offset).toLowerCase();
  }

  private _updateConfig(id: string, prop: string, target: any, maybeVal: any, knownKey: string, updatee: Record<string, any>) {
    updatee[prop] = target[knownKey]; // why not maybeVal?
  }

  private loadSdkConfig(identifier: string) {
    const UID = identifier.toUpperCase();
    const prefix = PREFIX + UID + "_";
    const resolvedConfigs: Record<string, any> = {};
    for (const [key, value] of Object.entries(process.env)) {
      if (key.startsWith(prefix)) {
        const prop = this._getProp(key, prefix.length - 1);
        this._updateConfig(identifier, prop, process.env, value, key, resolvedConfigs);
      }
    }
    return resolvedConfigs;
  }

  getSdk(identifier: string): AaWebSdkController {
    const sdk = this.registry.get(identifier);
    if (sdk) {
      return sdk;
    }
    throw new Error("Get SDK called for unknown/unsupported handle: " + identifier);
  }

  setUpSdk(identifier: string, otherOpts: Record<string, any> = {}) {
    const sdk = this.registry.get(identifier);
    if (sdk) {
      const sdkConfig = this.configs.get(identifier);
      return sdk.setup({ ...sdkConfig, ...otherOpts } as InitOptions);
    } else {
      throw new Error("Set up SDK called for unknown/unsupported handle: " + identifier);
    }
  }
}