<template>
  <svg
    width="18"
    height="27"
    viewBox="0 0 18 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.6844 6.91072V5.05313H15.9586C16.6331 5.05497 17.1835 4.51398 17.1937 3.83968V1.21529C17.1837 0.540587 16.6335 -0.000819382 15.9586 9.3094e-07H1.23498C0.560068 -0.000819382 0.00984375 0.540587 0 1.21529V3.83968C0.0102539 4.51398 0.560478 5.05497 1.23498 5.05313H1.50937V6.91072C1.50835 7.79051 1.90928 8.62251 2.59793 9.16986L7.53498 13.117L2.69493 16.8714C1.98639 17.4173 1.57213 18.2624 1.575 19.1572V21.1969H1.23498C0.560478 21.195 0.0102539 21.736 0 22.4103V25.0347C0.00984375 25.7094 0.560068 26.2508 1.23498 26.25H15.9586C16.6335 26.2508 17.1837 25.7094 17.1937 25.0347V22.4103C17.1835 21.736 16.6331 21.195 15.9586 21.1969H15.75V19.1701C15.7459 18.2665 15.3226 17.4161 14.6042 16.8679L9.66574 13.1119L14.5966 9.16966C15.2851 8.62231 15.6858 7.7901 15.6844 6.91072ZM1.3125 1.3125H15.8812V3.74063H1.3125V1.3125ZM15.8812 24.9375H1.3125V22.5094H2.11805C2.15578 22.5094 2.19393 22.52 2.23351 22.52C2.27288 22.52 2.31144 22.5094 2.34917 22.5094H14.9697C15.0072 22.5094 15.0458 22.52 15.0851 22.52C15.1247 22.52 15.1631 22.5094 15.2006 22.5094H15.8812V24.9375ZM14.4375 19.1701V21.1969H2.8875V19.1572C2.88647 18.6683 3.11268 18.2066 3.50027 17.9084L8.60016 13.9525L13.8093 17.9123C14.2023 18.2118 14.4344 18.6763 14.4375 19.1701ZM13.7757 8.14468L8.5981 12.2848L3.41865 8.14468C3.04172 7.84609 2.82187 7.39163 2.82187 6.91072V5.05313H14.3719V6.91072C14.3719 7.39163 14.1524 7.84588 13.7757 8.14468Z"
      fill="currentColor" />
  </svg>
</template>