import useBackend from "@/services/api.service";
import { defineStore, storeToRefs } from "pinia";
import { ref, reactive, shallowRef } from "vue";
import WebFont from "webfontloader";

import useEventService from "@/composables/useEventService";
import useUtilService from "@/services/util.service";

export const useItrInfoStore = defineStore("itr-info-Store", {
  state: () => ({
    orgName: "",
    brandLogo: "",
    itrBrandColor: "",
    pan: "",
    itrNote: "",
    loading: false,
    tenantId: "",
    redirectUrl: "",
    brandFont: "",
    features: {} as any,
    templatesTxt: {} as any,
    extraConfig: {} as any,
    error: {
      status: false,
      code: "500",
      message: "",
    },
  }),

  actions: {
    async itrInfos(requestId: any) {
      const { getItrRequestDetails } = useBackend();
      this.loading = true;
      await getItrRequestDetails(requestId)
        .then((response) => {
          const data = response.data;

          this.orgName = data.orgName;
          this.tenantId = data.id;

          if (data.brandInfo) {
            this.brandLogo = data.brandInfo.logo;
            this.itrBrandColor = data.brandInfo.color;
          }
          if (data.template) {
            this.itrNote = data.template.itr1;
          }
          this.pan = data.customer.pan;

          if (data.redirectUrl) {
            this.redirectUrl = data.redirectUrl;
          }

          if (data.features) {
            this.features = data.features;
          }
          if (data.templates) {
            this.templatesTxt = data.templates;
          }
          if (data.extraConfig) {
            this.extraConfig = data.extraConfig;
          }

          if (data.features["ui.itr.font.family"]) {
            WebFont.load({
              google: {
                families: [data.features["ui.itr.font.family"]], // Add your desired fonts here
              },
              // You can also add custom font providers or font events here if needed
            });
          }

          // useItrStore().updateFromRequestDetails(requestId, response.data);
        })
        .catch((error: any) => {
          if (error.response) {
            // Request made and server responded
            const errResponse = error.response;
            this.error = {
              status: true,
              code: errResponse.status,
              message: error.response.data.errorMessage,
            };
          } else if (error.request) {
            // The request was made but no response was received
            this.error = {
              status: true,
              code: "500",
              message: "",
            };
          } else {
            // Something happened in setting up the request that triggered an Error
            this.error = {
              status: true,
              code: "500",
              message: "",
            };
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  getters: {},
});

export const useItrStore = defineStore("itr-store", () => {
  interface ViewError {
    status?: boolean;
    code?: string;
    message?: string;
    errCode?: string;
  }

  const events = useEventService();
  const { eventPayload } = useUtilService();

  const isLoading = ref(false);
  const requestId = ref("");
  const tenantId = ref("");
  const pan = ref("");
  const redirectUrl = ref("");
  const itrNote = ref("");
  const viewHandler = shallowRef();
  const inpErrMsg = ref("");
  const brand = ref({
    color: "",
    logo: "",
    name: "",
  });
  const features = ref({} as any);
  const templatesTxt = ref({} as any);
  const itrInfo = reactive({
    pwd: "",
  });
  const processingError = ref<ViewError>({});

  function updateFromRequestDetails(reqId: string, data: any) {
    requestId.value = reqId;
    tenantId.value = data.id;
    pan.value = data.customer.pan;

    if (data.brandInfo) {
      const brandInfo = data.brandInfo;
      updateBrand(data.orgName, brandInfo.color, brandInfo.logo);
    }
    if (data.template) {
      itrNote.value = data.template.itr1;
    }
    if (data.redirectUrl) {
      redirectUrl.value = data.redirectUrl;
    }
    if (data.features) {
      features.value = data.features;
    }
    if (data.templates) {
      templatesTxt.value = data.templates;
    }
  }

  async function eventUpdate(e: any, reqId: any) {
    console.log(e);
    console.log(reqId);
    return await events.fire(e, eventPayload(reqId));
  }

  function updateBrand(name: string, color: string, logo: string) {
    brand.value.name = name;
    brand.value.color = color;
    brand.value.logo = logo;
  }

  return {
    isLoading,
    requestId,
    tenantId,
    pan,
    brand,
    features,
    templatesTxt,
    redirectUrl,
    viewHandler,
    inpErrMsg,
    itrInfo,
    processingError,
    updateFromRequestDetails,
    updateBrand,
    eventUpdate,
  };
});

export const useItrStatusStore = defineStore("itr-status-store", () => {
  const reqId = ref("");
  const itrStatus = ref("");
  const itrErrorCode = ref("");

  const itrStatusGet = ref("");

  async function itrInfoStatus(requestId: any) {
    const { getItrStatusDetails } = useBackend();

    return await getItrStatusDetails(requestId).then((response) => {
      const data = response.data;
      // console.log(data)
      itrStatus.value = data.status;
      itrErrorCode.value = data.errorCode;
    });
  }

  function updateItrInfoStatus(requestId: string, data: any) {
    reqId.value = requestId;
    itrStatusGet.value = data.status;
    // itrErrorCode.value = data.errorCode;
  }

  return {
    itrStatus,
    itrErrorCode,
    itrInfoStatus,
    itrStatusGet,
    updateItrInfoStatus,
  };
});

export const useItrFeatureStore = defineStore("itr-feature-store", () => {
  const { features } = storeToRefs(useItrInfoStore());

  const showDefault = ref(false);
  const headerHide = ref(true);
  const headerLogoHide = ref(true);
  const ribbonHide = ref(true);
  const ribbonBrandColor = ref("");
  const bodyBgColor = ref("");
  const footerHide = ref(true);
  const footerLogoHide = ref(true);
  const hideForgotPassTxt = ref(true);
  const otpResetDisclaimer = ref(false);
  const otpResetDisclaimerBoxBg = ref("");
  const otpResetDisclaimerColorTxt = ref("");
  const otpResetDisclaimerColorHeading = ref("");
  const otpResetDisclaimerHeadingText = ref("");
  const otpResetDisclaimerHeadingHide = ref(true);
  const otpResetDisclaimerShadow = ref(true);
  const otpResetDisclaimerBoxBgHide = ref(true);

  const newPasswordEnabled = ref(false);
  const newPasswordGenerate = ref(false);

  const inpRounded = ref(false);
  const btnRounded = ref(false);
  const inpBorderColor = ref("");
  const inpBorderColorFocus = ref("");
  const inpBorderColorActive = ref("");
  const inpBorderColorDisabled = ref("");
  const fontBoxTitleColor = ref("");
  const fontFamilyCustom = ref("");

  const bgColorHeader = ref("");
  const bgColorBody = ref("");
  const bgColorFooter = ref("");
  const textColorBody = ref("");

  const warningBoxBg = ref("");
  const warningIconColor = ref("");
  const warningTextColor = ref("");

  const redirectTime = ref(0);

  function getFeatures() {
    if (features.value["ui.itr.header.hide"] === true) {
      headerHide.value = false;
    }
    if (features.value["ui.itr.header.logo.hide"] === true) {
      headerLogoHide.value = false;
    }
    if (features.value["ui.itr.body.ribbonBand.hide"] === true) {
      ribbonHide.value = false;
    }
    if (features.value["ui.itr.body.ribbonBand.color"]) {
      ribbonBrandColor.value = features.value["ui.itr.body.ribbonBand.color"];
    }
    if (features.value["ui.itr.footer.hide"] === true) {
      footerHide.value = false;
    }
    if (features.value["ui.itr.footer.logo.hide"] === true) {
      footerLogoHide.value = false;
    }
    if (features.value["ui.itr.body.rounded.input"] === "rounded") {
      inpRounded.value = true;
    }
    if (features.value["ui.itr.body.rounded.button"] === "rounded") {
      btnRounded.value = true;
    }
    if (features.value["ui.itr.body.input.color"]) {
      inpBorderColor.value = features.value["ui.itr.body.input.color"];
    }
    if (features.value["ui.itr.body.input.color.focus"]) {
      inpBorderColorFocus.value
        = features.value["ui.itr.body.input.color.focus"];
    }
    if (features.value["ui.itr.body.input.color.active"]) {
      inpBorderColorActive.value
        = features.value["ui.itr.body.input.color.active"];
    }
    if (features.value["ui.itr.body.input.color.disabled"]) {
      inpBorderColorDisabled.value
        = features.value["ui.itr.body.input.color.disabled"];
    }
    if (features.value["ui.itr.font.color.boxTitle"]) {
      fontBoxTitleColor.value = features.value["ui.itr.font.color.boxTitle"];
    }
    if (features.value["ui.itr.font.family"]) {
      fontFamilyCustom.value = features.value["ui.itr.font.family"];
    }

    if (features.value["ui.itr.background.header"]) {
      bgColorHeader.value = features.value["ui.itr.background.header"];
    }
    if (features.value["ui.itr.background.body"]) {
      bgColorBody.value = features.value["ui.itr.background.body"];
    }
    if (features.value["ui.itr.background.footer"]) {
      bgColorFooter.value = features.value["ui.itr.background.footer"];
    }
    if (features.value["ui.itr.font.color"]) {
      textColorBody.value = features.value["ui.itr.font.color"];
    }

    if (features.value["ui.itr.fp.autoPassword"] === false) {
      newPasswordEnabled.value = true;
    }
    if (features.value["ui.itr.fp.autoPassword.generate"] === true) {
      newPasswordGenerate.value = true;
    }
    if (features.value["ui.itr.fp.otp.disclaimer"] === true) {
      otpResetDisclaimer.value = true;
    }
    if (features.value["ui.itr.fp.otp.disclaimer.background"]) {
      otpResetDisclaimerBoxBg.value
        = features.value["ui.itr.fp.otp.disclaimer.background"];
    }
    if (features.value["ui.itr.fp.otp.disclaimer.color"]) {
      otpResetDisclaimerColorTxt.value
        = features.value["ui.itr.fp.otp.disclaimer.color"];
    }
    if (features.value["ui.itr.fp.otp.disclaimer.color.heading"]) {
      otpResetDisclaimerColorHeading.value
        = features.value["ui.itr.fp.otp.disclaimer.color.heading"];
    }
    if (features.value["ui.itr.fp.otp.disclaimer.shadow"] === false) {
      otpResetDisclaimerShadow.value = false;
    }
    if (features.value["ui.itr.fp.otp.disclaimer.heading.hide"] === true) {
      otpResetDisclaimerHeadingHide.value = false;
    }
    if (features.value["ui.itr.fp.otp.disclaimer.background.hide"] === true) {
      otpResetDisclaimerBoxBgHide.value = false;
    }
    if (features.value["ui.itr.warning.box.background"]) {
      warningBoxBg.value = features.value["ui.itr.warning.box.background"];
    }
    if (features.value["ui.itr.warning.icon.color"]) {
      warningIconColor.value = features.value["ui.itr.warning.icon.color"];
    }
    if (features.value["ui.itr.warning.text.color"]) {
      warningTextColor.value = features.value["ui.itr.warning.text.color"];
    }

    if (features.value["ui.itr.fp.enabled"] === false) {
      hideForgotPassTxt.value = false;
      showDefault.value = true;
    } else {
      showDefault.value = true;
    }

    if (features.value["ui.itr.ty.redirectTime"]) {
      redirectTime.value = features.value["ui.itr.ty.redirectTime"];
    } else {
      redirectTime.value = 5000;
    }
  }

  return {
    getFeatures,
    showDefault,
    hideForgotPassTxt,
    newPasswordEnabled,
    newPasswordGenerate,
    otpResetDisclaimer,
    otpResetDisclaimerBoxBg,
    otpResetDisclaimerColorTxt,
    otpResetDisclaimerColorHeading,

    headerHide,
    headerLogoHide,
    ribbonHide,
    ribbonBrandColor,
    bodyBgColor,
    footerHide,
    footerLogoHide,

    inpRounded,
    btnRounded,
    inpBorderColor,
    inpBorderColorFocus,
    inpBorderColorActive,
    inpBorderColorDisabled,
    otpResetDisclaimerShadow,
    otpResetDisclaimerHeadingHide,
    otpResetDisclaimerHeadingText,
    otpResetDisclaimerBoxBgHide,

    warningBoxBg,
    warningIconColor,
    warningTextColor,

    fontBoxTitleColor,
    fontFamilyCustom,

    bgColorHeader,
    bgColorBody,
    bgColorFooter,
    textColorBody,

    redirectTime,
  };
});

export const useItrFeatStore = defineStore("itr-feat-store", () => {
  const showDefault = ref(false);
  const headerHide = ref(true);
  const headerLogoHide = ref(true);
  const closeBtnHide = ref(true);
  const ribbonHide = ref(true);
  const ribbonBrandColor = ref("");
  const bodyBgColor = ref("");
  const footerHide = ref(true);
  const footerLogoHide = ref(true);
  const hideForgotPassTxt = ref(true);
  const fpInpSplit = ref(false);
  const fpOtpAutoTrigger = ref(false);
  const fpInpCount = ref(6);
  const otpResetDisclaimer = ref(false);
  const otpResetDisclaimerBoxBg = ref("");
  const otpResetDisclaimerColorTxt = ref("");
  const otpResetDisclaimerColorHeading = ref("");

  const warningBoxBg = ref("");
  const warningIconColor = ref("");
  const warningTextColor = ref("");

  const newPasswordEnabled = ref(false);
  const newPasswordGenerate = ref(false);

  const inpRounded = ref(false);
  const btnRounded = ref(false);
  const inpBorderColor = ref("");
  const inpBorderColorFocus = ref("");
  const inpBorderColorActive = ref("");
  const fontBoxTitleColor = ref("");
  const fontFamilyCustom = ref("");

  const bgColorHeader = ref("");
  const bgColorBody = ref("");
  const bgColorFooter = ref("");
  const textColorBody = ref("");

  function getFeatures(feat: any) {
    if (feat["ui.itr.header.hide"] === true) {
      headerHide.value = false;
    }
    if (feat["ui.itr.header.logo.hide"] === true) {
      headerLogoHide.value = false;
    }
    if (feat["ui.itr.header.closeBtn.hide"] === true) {
      closeBtnHide.value = false;
    }
    if (feat["ui.itr.body.ribbonBand.hide"] === true) {
      ribbonHide.value = false;
    }
    if (feat["ui.itr.body.ribbonBand.color"]) {
      ribbonBrandColor.value = feat["ui.itr.body.ribbonBand.color"];
    }
    if (feat["ui.itr.footer.hide"] === true) {
      footerHide.value = false;
    }
    if (feat["ui.itr.footer.logo.hide"] === true) {
      footerLogoHide.value = false;
    }
    if (feat["ui.itr.body.rounded.input"] === "rounded") {
      inpRounded.value = true;
    }
    if (feat["ui.itr.body.rounded.button"] === "rounded") {
      btnRounded.value = true;
    }
    if (feat["ui.itr.body.input.color"]) {
      inpBorderColor.value = feat["ui.itr.body.input.color"];
    }
    if (feat["ui.itr.body.input.color.focus"]) {
      inpBorderColorFocus.value = feat["ui.itr.body.input.color.focus"];
    }
    if (feat["ui.itr.body.input.color.active"]) {
      inpBorderColorActive.value = feat["ui.itr.body.input.color.active"];
    }
    if (feat["ui.itr.font.color.boxTitle"]) {
      fontBoxTitleColor.value = feat["ui.itr.font.color.boxTitle"];
    }
    if (feat["ui.itr.font.family"]) {
      WebFont.load({
        google: {
          families: [feat["ui.itr.font.family"]], // Add your desired fonts here
        },
        // You can also add custom font providers or font events here if needed
      });
      fontFamilyCustom.value = feat["ui.itr.font.family"];
    }

    if (feat["ui.itr.background.header"]) {
      bgColorHeader.value = feat["ui.itr.background.header"];
    }
    if (feat["ui.itr.background.body"]) {
      bgColorBody.value = feat["ui.itr.background.body"];
    }
    if (feat["ui.itr.background.footer"]) {
      bgColorFooter.value = feat["ui.itr.background.footer"];
    }
    if (feat["ui.itr.font.color"]) {
      textColorBody.value = feat["ui.itr.font.color"];
    }
    if (feat["ui.itr.fp.otp.boxType"] === "split") {
      fpInpSplit.value = true;
    }

    if (feat["ui.itr.fp.enabled"] === false) {
      hideForgotPassTxt.value = false;
    }
    if (feat["ui.itr.fp.otp.autoTrigger"] === true) {
      fpOtpAutoTrigger.value = true;
    }
    if (feat["ui.itr.fp.autoPassword"] === false) {
      newPasswordEnabled.value = true;
    }
    if (feat["ui.itr.fp.autoPassword.generate"] === true) {
      newPasswordGenerate.value = true;
    }
    if (feat["ui.itr.fp.otp.disclaimer"] === true) {
      otpResetDisclaimer.value = true;
    }
    if (feat["ui.itr.fp.otp.disclaimer.background"]) {
      otpResetDisclaimerBoxBg.value
        = feat["ui.itr.fp.otp.disclaimer.background"];
    }
    if (feat["ui.itr.fp.otp.disclaimer.color"]) {
      otpResetDisclaimerColorTxt.value = feat["ui.itr.fp.otp.disclaimer.color"];
    }
    if (feat["ui.itr.fp.otp.disclaimer.color.heading"]) {
      otpResetDisclaimerColorHeading.value
        = feat["ui.itr.fp.otp.disclaimer.color.heading"];
    }

    if (feat["ui.itr.warning.box.background"]) {
      warningBoxBg.value = feat["ui.itr.warning.box.background"];
    }
    if (feat.value["ui.itr.warning.icon.color"]) {
      warningIconColor.value = feat["ui.itr.warning.icon.color"];
    }
    if (feat.value["ui.itr.warning.text.color"]) {
      warningTextColor.value = feat["ui.itr.warning.text.color"];
    }

    if (feat["ui.itr.otp.input.split"]) {
      fpInpCount.value = feat["ui.itr.otp.input.split"];
    } else {
      fpInpCount.value = 6;
    }
  }

  return {
    getFeatures,
    showDefault,
    hideForgotPassTxt,

    headerHide,
    headerLogoHide,
    closeBtnHide,
    ribbonHide,
    ribbonBrandColor,
    bodyBgColor,
    footerHide,
    footerLogoHide,

    inpRounded,
    btnRounded,
    inpBorderColor,
    inpBorderColorFocus,
    inpBorderColorActive,
    fontBoxTitleColor,
    fontFamilyCustom,

    bgColorHeader,
    bgColorBody,
    bgColorFooter,
    textColorBody,

    fpInpSplit,
    fpOtpAutoTrigger,
    fpInpCount,
    otpResetDisclaimer,
    otpResetDisclaimerBoxBg,
    otpResetDisclaimerColorTxt,
    otpResetDisclaimerColorHeading,

    warningBoxBg,
    warningIconColor,
    warningTextColor,

    newPasswordEnabled,
    newPasswordGenerate,
  };
});