<template>
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="1"
      width="14"
      height="14"
      rx="2.5"
      fill="#ffffff"
      stroke="currentColor"
    />
    <line
      x1="4"
      y1="8"
      x2="11"
      y2="8"
      stroke="currentColor" />
  </svg>
</template>