import { watchEffect } from "vue";
import { EventService } from "@finarkein/event-service";
import { useOrgInfosTemplate, useV2ItrInfoStore } from "@/store";
import { usev2Store } from "@/store/v2.store";
import { useAAJourneyStore } from "@/store/aa-journey.store";

const heartBeatStopDuration = process.env.VUE_APP_HEARTBEAT_STOP_DURATION ? parseInt(process.env.VUE_APP_HEARTBEAT_STOP_DURATION) : 1000 * 2 * 60;
const heartBeatTimeoutDuration = process.env.VUE_APP_HEARTBEAT_TIMEOUT_DURATION ? parseInt(process.env.VUE_APP_HEARTBEAT_TIMEOUT_DURATION) : 10000; // 10s

const eventService = new EventService({
  hubConfig: {
    hubUrl: `${process.env.VUE_APP_API_EVENTS_BASE}/e/event`,
  },
  extraConfig: {
    heartBeatTimeoutDuration,
    heartBeatStopDuration,
  },
});

const useEventService = () => {
  const store1 = useOrgInfosTemplate();
  const store2 = usev2Store();
  const commonJourneyStore = useAAJourneyStore();
  const itrStore = useV2ItrInfoStore();

  watchEffect(() => {
    eventService.tenant = store2.tenantId ? store2.tenantId : commonJourneyStore.tenantId ? commonJourneyStore.tenantId : store1.tenantId ? store1.tenantId : itrStore.tenantId;
  });

  watchEffect(() => {
    eventService.requestId = store2.reqId ? store2.reqId : commonJourneyStore.requestId ? commonJourneyStore.requestId : itrStore.requestId;
  });

  watchEffect(() => {
    eventService.lastKnownStatus = commonJourneyStore.lastKnownStatus ? commonJourneyStore.lastKnownStatus : "";
  });

  watchEffect(() => {
    eventService.discriminator = commonJourneyStore.discriminator ? commonJourneyStore.discriminator : Date.now();
  });

  return eventService;
};

export default useEventService;