<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.5605 5.22492H0.439415C0.242919 5.22492 0.0702276 5.09435 0.0166692 4.90517C-0.0368892 4.716 0.0416173 4.51436 0.209045 4.41125L7.2696 0.0652314C7.41094 -0.0217438 7.58912 -0.0217438 7.73034 0.0652314L14.7909 4.41125C14.9583 4.51424 15.0368 4.716 14.9834 4.90517C14.9298 5.09435 14.7571 5.22492 14.5605 5.22492ZM7.79878 2.58477H7.20128C6.95855 2.58477 6.76183 2.78149 6.76183 3.02422C6.76183 3.26695 6.95855 3.46367 7.20128 3.46367H7.79878C8.04151 3.46367 8.23823 3.26695 8.23823 3.02422C8.23823 2.78149 8.04151 2.58477 7.79878 2.58477ZM13.9577 6.10366V6.54311C13.9577 6.78584 13.7608 6.98257 13.5182 6.98257H1.48164C1.23891 6.98257 1.04219 6.78584 1.04219 6.54311V6.10366H13.9577ZM1.62813 7.86147H3.38583V12.0702H1.62813V7.86147ZM6.71469 7.86147H4.95687V12.0702H6.71469V7.86147ZM8.2854 7.86147H10.0432V12.0702H8.2854V7.86147ZM11.6139 7.86147H13.3716V12.0702H11.6139V7.86147ZM14.5605 12.9492H0.440051C0.197322 12.9492 0.000597455 13.1459 0.000597455 13.3887V14.5605C0.000597455 14.8033 0.197322 15 0.440051 15H14.5605C14.8032 15 14.9999 14.8033 14.9999 14.5605V13.3887C14.9999 13.1459 14.8032 12.9492 14.5605 12.9492Z"
      fill="currentColor" />
  </svg>
</template>