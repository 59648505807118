import { BaseErrorHandlerFactory, HandlerContext } from "@/conductor/ErrorHandlers/BaseErrorHandlerFactory";
import { ConductorEvent, ErrorCodes, ErrorMessages, ErrorTypes } from "@/conductor/journey-constants";
import { AccountLinkingResponseProto } from "@/conductor/models";
import { eventPayload } from "../conductor";

export class LinkAccountsErrorHandler extends BaseErrorHandlerFactory {
  constructor(ctx: HandlerContext) {
    super(ctx);
  }

  async processError(err: any, fip: any, retryAllowed: boolean, badRequestCallBack: () => void): Promise<any> {
    if (err?.name === "StatusError") {
      const {
        discriminator,
        aaHandle,
      } = this.storeToRefs;
      const responseObj = err.context;
      if (responseObj.status === "RETRY") {
        // update the caller to store the state and enable retry?
        return {
          retryNow: true,
          response: responseObj.data as AccountLinkingResponseProto,
        };
      } else if (responseObj.status === "BAD_REQUEST") {
        if (retryAllowed) {
          await badRequestCallBack();
        } else {
          this.events.fire(ConductorEvent.LINKED_ACCOUNTS_FETCH_BAD_REQUEST, eventPayload(this.store.requestId, discriminator.value, {
            aaHandle: aaHandle.value,
            retriable: false,
          }, this.aaErrorContext(responseObj)));
          this.handleFailures(ErrorCodes.FAILURE, ErrorTypes.FLOW_ERROR, responseObj.message, true, this.aaErrorContext(responseObj));
          throw new Error(responseObj.status);
        }
      } else if (responseObj.status === "FAILURE") {
        this.events.fire(ConductorEvent.LINKED_ACCOUNTS_FETCH_FAILURE, eventPayload(this.store.requestId, discriminator.value, {
          aaHandle: aaHandle.value,
          retriable: false,
        }, this.aaErrorContext(responseObj)));
        this.handleFailures(ErrorCodes.FAILURE, ErrorTypes.FLOW_ERROR, responseObj.message, true, this.aaErrorContext(responseObj));
      } else if (responseObj.status === "SESSION_ERROR") {
        this.handleSessionError();
      } else {
        this.events.fire(ConductorEvent.LINKED_ACCOUNTS_FETCH_FAILURE, eventPayload(this.store.requestId, discriminator.value, {
          aaHandle: aaHandle.value,
          retriable: false,
        }, this.aaErrorContext(responseObj)));
        this.handleBadRequest(ErrorCodes.UNKNOWN, ErrorTypes.INSTITUTION_ERROR, "", false, this.aaErrorContext(responseObj));
        this.log.error(`Error while initating account linking for ${fip.fipName} with AA`, this.storeToRefs.aaHandle.value, responseObj?.message);
      }
    } else {
      if (err === ErrorMessages.SESSION_EXPIRED) {
        this.handleSessionError();
        throw ErrorMessages.SESSION_EXPIRED;
      }
      this.log.fatal(`Error while initating account linking for ${fip.fipName} with AA`, this.store.aaHandle, err?.message | err);
    }
  }
}