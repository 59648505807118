<template>
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.78808 3.06592C6.8377 3.06592 5.24194 4.61937 5.24194 6.51803C5.24194 8.41669 6.8377 9.97014 8.78808 9.97014C10.7385 9.97014 12.3342 8.41669 12.3342 6.51803C12.3342 4.61937 10.7385 3.06592 8.78808 3.06592ZM8.78808 8.81944C7.48783 8.81944 6.42399 7.7838 6.42399 6.51803C6.42399 5.25225 7.48783 4.21662 8.78808 4.21662C10.0883 4.21662 11.1522 5.25225 11.1522 6.51803C11.1522 7.7838 10.0883 8.81944 8.78808 8.81944Z"
      fill="currentColor" />
    <path
      d="M8.78726 0.188965C4.207 0.188965 0.512939 3.78508 0.512939 8.24389C0.512939 10.4302 1.42902 12.444 2.87703 13.8823C3.23164 14.2275 3.61581 14.544 4.05908 14.8317C5.38888 15.7522 7.01419 16.27 8.78726 16.27C10.5603 16.27 12.1855 15.7235 13.5154 14.8317C13.9292 14.544 14.3133 14.2275 14.6975 13.8823C16.175 12.4152 17.0616 10.4302 17.0616 8.24389C17.0616 3.78508 13.3675 0.188965 8.78726 0.188965ZM8.78726 15.1481C7.01419 15.1481 5.41843 14.5152 4.17728 13.4796C4.50234 12.444 5.47753 11.696 6.63002 11.696H10.9739C12.1264 11.696 13.1016 12.444 13.4266 13.4796C12.1559 14.5152 10.5602 15.1481 8.78692 15.1481H8.78726ZM14.3132 12.559C13.6926 11.3508 12.4219 10.5453 10.9739 10.5453H6.63002C5.15247 10.5453 3.88177 11.3796 3.29075 12.559C2.28601 11.3796 1.69499 9.88364 1.69499 8.24389C1.69499 4.44657 4.88651 1.33967 8.78726 1.33967C12.688 1.33967 15.8795 4.44657 15.8795 8.24389C15.8795 9.88364 15.2885 11.3796 14.3133 12.559H14.3132Z"
      fill="currentColor" />
  </svg>
</template>