<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.3555 25.0001H13.4934C13.1376 25.0011 12.7926 24.8757 12.5207 24.6462C12.2484 24.4168 12.0663 24.0985 12.0068 23.7474C11.9473 23.3964 12.0142 23.0358 12.1953 22.7296L17.1263 14.3394C17.3963 13.8786 17.8903 13.5955 18.4244 13.5955C18.9584 13.5955 19.4525 13.8786 19.7225 14.3394L24.6535 22.7296C24.8349 23.0358 24.9015 23.3964 24.842 23.7474C24.7825 24.0984 24.6004 24.4168 24.3281 24.6462C24.0561 24.8757 23.7115 25.0011 23.3554 25.0001H23.3555ZM18.4245 14.6981C18.285 14.6968 18.1558 14.7704 18.085 14.8907L13.154 23.294C13.0846 23.4166 13.0846 23.5663 13.154 23.6886C13.2212 23.813 13.3524 23.8892 13.4935 23.886H23.3557C23.4968 23.8863 23.627 23.8108 23.6964 23.6879C23.7659 23.5654 23.7636 23.4147 23.6907 23.294L18.7641 14.8907C18.6937 14.7704 18.5641 14.6968 18.4246 14.6981H18.4245Z"
      fill="currentColor" />
    <path
      d="M18.4244 21.2944C18.1259 21.2947 17.8795 21.0605 17.8648 20.7623V17.6797C17.8911 17.3914 18.1326 17.1706 18.4222 17.1706C18.7118 17.1706 18.9533 17.3914 18.9796 17.6797V20.7623C18.9671 21.0598 18.7223 21.2947 18.4244 21.2944Z"
      fill="currentColor" />
    <path
      d="M18.4246 22.9777H18.1862L18.1404 22.9501L18.0947 22.9181H18.0944C18.0825 22.9034 18.072 22.8881 18.0624 22.8721L18.0348 22.8263C18.0323 22.8097 18.0323 22.7927 18.0348 22.7758C18.0323 22.7591 18.0323 22.7422 18.0348 22.7255V22.675C18.0326 22.6567 18.0326 22.6382 18.0348 22.6199V22.5924V22.5649V22.5374V22.3675C18.0326 22.3492 18.0326 22.331 18.0348 22.3124V22.2619V22.2622C18.0326 22.2436 18.0326 22.2254 18.0348 22.2072C18.0329 22.1918 18.0329 22.1765 18.0348 22.1611L18.0624 22.1153C18.072 22.0993 18.0825 22.084 18.0944 22.0696L18.1401 22.0376L18.1859 22.0101H18.709L18.7548 22.0376L18.8005 22.0696H18.8008C18.8127 22.084 18.8232 22.0993 18.8328 22.1153L18.8604 22.1611C18.862 22.1765 18.862 22.1918 18.8604 22.2072C18.8623 22.2254 18.8623 22.2436 18.8604 22.2622V22.3128V22.3678V22.4779V22.5329V22.5835V22.5831C18.8629 22.5998 18.8629 22.6171 18.8604 22.6337C18.8629 22.6503 18.8629 22.6673 18.8604 22.6842L18.8328 22.73C18.8232 22.746 18.8127 22.7614 18.8008 22.7758L18.7551 22.8078L18.7093 22.8353H18.4476L18.4246 22.9777Z"
      fill="currentColor" />
    <path
      d="M11.7275 23.4723C9.42149 23.4711 7.16696 22.7911 5.24523 21.5167C3.32318 20.2426 1.81904 18.4309 0.92022 16.3076C0.0150159 14.1593 -0.229408 11.79 0.218214 9.50225C0.665844 7.21446 1.78543 5.11196 3.4339 3.46382C6.18334 0.695453 10.1292 -0.524291 13.9612 0.209472C17.7931 0.942834 21.0094 3.53393 22.5422 7.12207C24.0745 10.7102 23.7226 14.8253 21.6031 18.1015C21.5343 18.2467 21.4057 18.3549 21.2508 18.3981C21.096 18.4413 20.9302 18.4151 20.7961 18.3264C20.6618 18.2381 20.5728 18.0957 20.5517 17.9364C20.5302 17.777 20.5792 17.6164 20.6857 17.4961C22.4056 14.8318 22.8452 11.5384 21.8844 8.51615C20.9235 5.49403 18.6629 3.05912 15.7202 1.87714C12.7778 0.694831 9.46068 0.889125 6.67634 2.40668C3.89167 3.92426 1.93055 6.60629 1.3294 9.71982C0.727858 12.8334 1.5489 16.0532 3.56788 18.4983C5.58683 20.9437 8.59266 22.3593 11.7639 22.3584C12.0897 22.3584 12.4199 22.3584 12.7456 22.3126V22.3123C13.0521 22.2844 13.3231 22.5103 13.3513 22.8169C13.3791 23.1234 13.1532 23.3947 12.8467 23.4226C12.4522 23.4546 12.0896 23.4728 11.7274 23.4728L11.7275 23.4723Z"
      fill="currentColor" />
    <path
      d="M11.7274 12.8902C11.2609 12.8922 10.8392 12.6122 10.66 12.1818C10.4805 11.7512 10.5788 11.2546 10.9087 10.925C11.2385 10.5951 11.7348 10.4969 12.1655 10.6761C12.5962 10.8556 12.8758 11.2769 12.8742 11.7434C12.8717 12.3757 12.3597 12.8877 11.7274 12.8902ZM11.7274 11.707C11.707 11.707 11.6906 11.7233 11.6906 11.7435C11.6929 11.7621 11.7086 11.7758 11.7274 11.7758C11.745 11.7758 11.7594 11.7614 11.7594 11.7435C11.7607 11.7342 11.7578 11.7249 11.7517 11.7179C11.7457 11.7109 11.7367 11.7067 11.7274 11.707Z"
      fill="currentColor" />
    <path
      d="M7.98412 16.0421C7.83598 16.0421 7.69391 15.9826 7.58992 15.877C7.48465 15.7737 7.42546 15.6322 7.42546 15.4847C7.42546 15.3372 7.48466 15.1961 7.58992 15.0928L11.1678 11.5104V4.39569C11.1937 4.1074 11.4356 3.88663 11.7249 3.88663C12.0145 3.88663 12.256 4.1074 12.2823 4.39569V11.7351C12.2823 11.881 12.2247 12.0212 12.1216 12.1248L8.3787 15.8678C8.27631 15.9769 8.13392 16.0396 7.98418 16.0422L7.98412 16.0421Z"
      fill="currentColor" />
  </svg>
</template>