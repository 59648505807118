<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      id="Vector"
      d="M1.8 3.6H0V16.2C0 17.199 0.81 18 1.8 18H14.4V16.2H1.8V3.6ZM14.85 11.025C14.85 9.675 12.15 9 10.8 9C9.45 9 6.75 9.675 6.75 11.025V11.7H14.85M10.8 7.425C11.916 7.425 12.825 6.516 12.825 5.4C12.825 4.284 11.916 3.375 10.8 3.375C9.684 3.375 8.775 4.284 8.775 5.4C8.775 6.516 9.684 7.425 10.8 7.425ZM16.2 0H5.4C4.41 0 3.6 0.81 3.6 1.8V12.6C3.6 13.599 4.41 14.4 5.4 14.4H16.2C17.199 14.4 18 13.599 18 12.6V1.8C18 0.801 17.19 0 16.2 0ZM16.2 12.6H5.4V1.8H16.2V12.6Z"
      fill="currentColor" />
  </svg>
</template>