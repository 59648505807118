<template>
  <svg
    width="32"
    height="29"
    viewBox="0 0 32 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.615373 19.1003L8 19.1003C8.25162 19.1003 8.47787 18.9351 8.57137 18.7015L10.3035 14.365L11.7081 20.4484C11.7728 20.729 12.0214 20.9246 12.3112 20.9239C12.5991 20.9223 12.8474 20.7205 12.9089 20.4393L16.3581 4.67095L18.464 28.3616C18.4889 28.641 18.6994 28.8681 18.976 28.9141C19.253 28.9596 19.5254 28.8252 19.6393 28.569L24.3999 17.8696L31.3846 17.8696C31.7244 17.8696 32 17.594 32 17.2542C32 16.9143 31.7244 16.6388 31.3846 16.6388L24 16.6388C23.7568 16.6388 23.5364 16.77 23.4376 16.9922L19.4795 25.892L17.2284 0.563267C17.2015 0.260518 16.9576 0.0213286 16.6544 0.00201663C16.6411 0.0012041 16.6281 8.06721e-05 16.615 8.0671e-05C16.3278 8.06459e-05 16.0763 0.19958 16.0142 0.48358L12.2916 17.501L11.0611 12.1688C11.0007 11.9067 10.7769 11.7142 10.5088 11.6936C10.2392 11.6727 9.99006 11.8409 9.89012 12.0906L7.58331 17.8696L0.61531 17.8696C0.275435 17.8696 -6.39076e-05 18.1452 -6.39373e-05 18.485C-2.93189e-06 18.8248 0.275559 19.1003 0.615373 19.1003Z"
      fill="currentColor" />
  </svg>
</template>