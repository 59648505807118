<template>
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 630.77 206.15"
    width="30"
    height="20">
    <g
      id="Layer_1-2"
      data-name="Layer_1">
      <g>
        <g>
          <path
            class="cls-2"
            d="M209.28,115.05c0,2.77,1.56,4.96,4.66,6.56,3.11,1.61,6.85,3.08,11.2,4.43,4.35,1.34,8.81,2.72,13.38,4.13,4.58,1.4,8.39,4,11.45,7.81s4.59,8.61,4.59,14.39c0,8.09-3.11,14.43-9.34,19.03-6.23,4.6-14.05,6.9-23.49,6.9-8.3,0-15.43-1.74-21.38-5.22-5.95-3.48-10.27-8.3-12.96-14.48l15.66-9.1c2.91,8.09,9.14,12.13,18.68,12.13s14.31-3.15,14.31-9.44c0-2.69-1.54-4.88-4.63-6.56-3.09-1.68-6.82-3.19-11.16-4.53-4.36-1.35-8.82-2.72-13.39-4.13-4.58-1.41-8.41-3.94-11.49-7.59-3.09-3.65-4.64-8.28-4.64-13.89,0-7.74,2.95-13.98,8.85-18.69,5.89-4.71,13.22-7.07,21.97-7.07,6.96,0,13.15,1.54,18.6,4.63,5.45,3.09,9.63,7.38,12.54,12.88l-15.32,8.59c-2.91-6.4-8.2-9.6-15.83-9.6-3.48,0-6.4.75-8.75,2.25-2.36,1.5-3.54,3.68-3.54,6.56" />
          <path
            class="cls-2"
            d="M333.98,91.93h18.19v84.19h-18.19v-12.13c-6.84,9.54-16.66,14.31-29.46,14.31-11.56,0-21.44-4.29-29.63-12.89-8.2-8.58-12.3-19.04-12.3-31.4s4.1-22.95,12.3-31.49c8.19-8.52,18.07-12.79,29.63-12.79,12.8,0,22.62,4.71,29.46,14.14v-11.95ZM288.36,153.3c5.05,5.1,11.39,7.66,19.03,7.66s13.97-2.56,19.02-7.66c5.05-5.1,7.58-11.53,7.58-19.28s-2.53-14.17-7.58-19.28c-5.05-5.1-11.39-7.66-19.02-7.66s-13.98,2.56-19.03,7.66c-5.05,5.11-7.58,11.53-7.58,19.28s2.53,14.18,7.58,19.28" />
          <path
            class="cls-2"
            d="M437.65,91.93h18.18v84.19h-18.18v-12.13c-6.85,9.54-16.67,14.31-29.47,14.31-11.56,0-21.44-4.29-29.63-12.89-8.2-8.58-12.29-19.04-12.29-31.4s4.09-22.95,12.29-31.49c8.19-8.52,18.07-12.79,29.63-12.79,12.8,0,22.62,4.71,29.47,14.14v-11.95ZM392.02,153.3c5.05,5.1,11.39,7.66,19.03,7.66s13.98-2.56,19.03-7.66c5.05-5.1,7.58-11.53,7.58-19.28s-2.53-14.17-7.58-19.28c-5.05-5.1-11.39-7.66-19.03-7.66s-13.98,2.56-19.03,7.66c-5.05,5.11-7.58,11.53-7.58,19.28s2.53,14.18,7.58,19.28" />
          <path
            class="cls-2"
            d="M518.14,73.58c-13.25-1.24-19.87,4.32-19.87,16.67v1.69h19.87v17.51h-19.87v66.67h-18.19v-66.67h-12.79v-17.51h12.79v-1.69c0-11.55,3.23-20.34,9.69-26.34,6.45-6,15.91-8.61,28.37-7.83v17.51Z" />
          <path
            class="cls-2"
            d="M540.66,141.77c1.34,6.4,4.43,11.31,9.25,14.73,4.83,3.43,10.72,5.14,17.68,5.14,9.65,0,16.89-3.48,21.73-10.44l14.98,8.75c-8.31,12.24-20.59,18.35-36.87,18.35-13.7,0-24.75-4.18-33.17-12.54-8.42-8.36-12.63-18.94-12.63-31.74s4.15-23.09,12.45-31.57c8.31-8.48,18.98-12.71,31.99-12.71s22.48,4.32,30.39,12.96c7.91,8.64,11.87,19.13,11.87,31.48,0,1.91-.22,4.44-.67,7.58h-67.01ZM540.48,126.95h49.5c-1.23-6.85-4.07-12.01-8.5-15.49-4.43-3.48-9.63-5.22-15.58-5.22-6.73,0-12.34,1.85-16.83,5.56-4.49,3.7-7.35,8.75-8.59,15.15" />
          <path
            class="cls-5"
            d="M44.77,78.76h-21.14v-26.77C23.64,23.33,46.96,0,75.63,0s51.99,23.33,51.99,51.99h-21.13c0-17.01-13.85-30.86-30.86-30.86s-30.86,13.85-30.86,30.86v26.77Z" />
          <path
            class="cls-1"
            d="M77.36,206.15l-6.44-4.95c-17.2-13.23-30.53-26.99-40.73-42.08-2.6-3.86-5.02-7.84-7.19-11.85-1.33-2.46-2.61-5.01-3.79-7.54l-4.44-9.59,19.17-8.87,4.45,9.58c.99,2.14,2.06,4.28,3.19,6.37,1.84,3.4,3.89,6.79,6.11,10.07,7.62,11.28,17.36,21.8,29.66,32.02,28.83-24,44.5-50.56,48.7-82.61-14.88-4.9-30.45-7.37-46.39-7.37-19.04,0-42.09,4.97-61.66,13.31l-10.65,4.58L0,87.34l9.72-4.14c22.38-9.54,47.88-15.01,69.94-15.01s42,3.87,61.57,11.51l7.26,2.83-.56,7.77c-3.15,43.84-24.12,80.12-64.14,110.9l-6.44,4.96Z" />
        </g>
        <g>
          <path
            class="cls-3"
            d="M620.74,109.5c-5.44,0-9.88-4.43-9.88-9.88s4.43-9.88,9.88-9.88,9.88,4.43,9.88,9.88-4.43,9.88-9.88,9.88ZM620.74,90.93c-4.79,0-8.69,3.9-8.69,8.69s3.9,8.69,8.69,8.69,8.69-3.9,8.69-8.69-3.9-8.69-8.69-8.69Z" />
          <path
            class="cls-4"
            d="M623.38,104.67l-2.23-3.81h-2.35v3.81h-1.32v-9.96h3.98c.85,0,1.58.3,2.19.9.61.6.91,1.33.91,2.18,0,.64-.19,1.23-.58,1.76-.38.53-.88.91-1.49,1.12l2.35,3.98h-1.47ZM618.8,95.95v3.71h2.66c.49,0,.91-.18,1.26-.55.35-.36.52-.8.52-1.32s-.17-.95-.52-1.31c-.35-.36-.77-.54-1.26-.54h-2.66Z" />
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped>
  .cls-1 {
    fill: #00b2c1;
  }

  .cls-2, .cls-3, .cls-4 {
    fill: #105476;
  }

  .cls-3 {
    stroke-miterlimit: 10;
  }

  .cls-3, .cls-4 {
    stroke: #105476;
    stroke-width: .3px;
  }

  .cls-5 {
    fill: #fcb034;
  }
</style>