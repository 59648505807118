<template>
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.56035 -0.00532792C8.47401 0.0050428 8.39157 0.045536 8.33059 0.107538L1.62316 6.81497C1.5719 6.86543 1.53515 6.93046 1.51835 7.00039L0.873409 9.83815C0.84435 9.9652 0.884863 10.1059 0.977018 10.1981C1.06917 10.2903 1.20992 10.3308 1.33696 10.3017L4.17472 9.65676C4.24608 9.64067 4.31263 9.60386 4.36418 9.55196L11.0716 2.84452C11.213 2.70147 11.213 2.44341 11.0716 2.30035L8.87879 0.107538C8.79696 0.0245634 8.67617 -0.0182468 8.56035 -0.00532792ZM8.60469 0.925812L10.2493 2.57445L9.50762 3.31614L7.86301 1.67153L8.60469 0.925812ZM7.3148 2.2157L8.95941 3.86435L4.21906 8.6047L2.57445 6.96008L7.3148 2.2157ZM2.16733 7.64534L3.53381 9.01182L1.76827 9.41088L2.16733 7.64534Z"
      fill="currentColor" />
  </svg>
</template>