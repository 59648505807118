<template>
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 7.38461H2.53846L4.69231 2.76923L6.53846 13.2308L8.69231 6.46153L10.2308 10.1538L12.3846 4.61538L14.2308 10.1538L15.1538 7.38461H17"
      stroke="#00C7B2"
      stroke-width="1.5"
      stroke-linecap="round" />
  </svg>
</template>