<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 0L8 8L0 11L8 14L11 22L14 14L22 11L14 8L11 0Z"
      fill="currentColor" />
  </svg>
</template>