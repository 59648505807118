import useBackend from "@/services/api.service";
import { defineStore } from "pinia";

export const useOrgInfosTemplate = defineStore("orgInfosTemplate", {
  state: () => ({
    discriminator: Date.now(),
    resultList: [],
    tenantId: "",
    orgName: "",
    brandLogo: "",
    brandColor: "",
    mobileNumber: "",
    aaList: [] as any[],
    aaSelected: {} as any,
    fipType: "",
    fipSelectEnabled: false,
    loading: false,
    redirectUrl: "",
    aaFipList: [] as any[],
    fipListMap: {} as any,
    error: {
      status: false,
      code: "500",
      message: "",
    },
  }),

  actions: {

    // TRACES left after removing the method
    // this.fipType = data.anubhavConfig.fipSelectType;
    // this.fipSelectEnabled = data.anubhavConfig.fipSelectEnabled;

    async orgInfos(requestId: any) {
      const { getRequestDetails } = useBackend();
      this.loading = true;
      return getRequestDetails(requestId)
        .then((response) => {
          const data = response.data;
          this.tenantId = data.id; // tenant identifier
          this.orgName = data.orgName;
          if (data.brandInfo) {
            this.brandLogo = data.brandInfo.logo;
            this.brandColor = data.brandInfo.color;
          }
          this.mobileNumber = data.customer.mobileNumber;
          this.aaList = data.aa;
          this.aaSelected = data.aa[0];
          this.redirectUrl = data.redirectUrl;
        })
        .catch((error: any) => {
          if (error.response) {
            // Request made and server responded
            const errResponse = error.response;
            this.error = {
              status: true,
              code: errResponse.status,
              message: error.response.data.errorMessage,
            };
          } else if (error.request) {
            // The request was made but no response was received
            this.error = {
              status: true,
              code: "500",
              message: "",
            };
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
            this.error = {
              status: true,
              code: "500",
              message: "",
            };
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async orgFips(requestId: any) {
      return useBackend().getRequestFip(requestId)
        .then((response: any) => {
          console.log(response.data.content);
          const data = response.data;
          this.aaFipList = data.content;

          this.aaList.forEach((element: any) => {
            this.fipListMap[element.handle] = [];
          });
          this.aaFipList.forEach((element: any) => {
            element.aa.forEach((item: any) => {
              const temp = {
                id: element.id,
                name: element.name,
                logo: element.logo,
              };
              if (!this.fipListMap[item]) {
                this.fipListMap[item] = [];
              }
              this.fipListMap[item].push(temp);
            });
          });
        })
        .catch((error: any) => {
          if (error.response) {
            // Request made and server responded
            const errResponse = error.response;
            this.error = {
              status: true,
              code: errResponse.status,
              message: error.response.data.errorMessage,
            };
          } else if (error.request) {
            // The request was made but no response was received
            this.error = {
              status: true,
              code: "500",
              message: "",
            };
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
            this.error = {
              status: true,
              code: "500",
              message: "",
            };
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

  },
  getters: {},
});