import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container mx-auto px-4" }
const _hoisted_2 = { class: "flex items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PoweredBy = _resolveComponent("PoweredBy")!

  return ($props.footerHide)
    ? (_openBlock(), _createElementBlock("footer", {
        key: 0,
        class: _normalizeClass(["w-full bg-white shadow-md py-2 h-[64px]", $props.footerStyle])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            ($props.footerLogo)
              ? (_openBlock(), _createBlock(_component_PoweredBy, {
                  key: 0,
                  class: ""
                }))
              : _createCommentVNode("", true)
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}