<template>
  <svg
    width="76"
    height="75"
    viewBox="0 0 76 75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.1018 2.49869C36.7672 1.06288 39.2328 1.06287 40.8982 2.49868L47.1142 7.85786C47.9354 8.56591 48.9875 8.94883 50.0717 8.93432L58.2783 8.82452C60.4769 8.79511 62.3657 10.38 62.7185 12.5503L64.0355 20.6513C64.2095 21.7216 64.7692 22.6912 65.6091 23.377L71.9663 28.5679C73.6695 29.9587 74.0976 32.3868 72.9728 34.2762L68.7745 41.3284C68.2198 42.2601 68.0254 43.3627 68.2279 44.428L69.7611 52.4908C70.1719 54.6509 68.9391 56.7862 66.8629 57.5106L59.1138 60.2142C58.0899 60.5714 57.2323 61.291 56.7027 62.2373L52.6946 69.3992C51.6207 71.3181 49.3038 72.1613 47.2478 71.3817L39.5737 68.4717C38.5598 68.0873 37.4402 68.0873 36.4263 68.4717L28.7522 71.3817C26.6962 72.1613 24.3793 71.3181 23.3055 69.3992L19.2973 62.2373C18.7677 61.291 17.9101 60.5714 16.8862 60.2142L9.13706 57.5106C7.06093 56.7862 5.82812 54.6509 6.23888 52.4908L7.77206 44.428C7.97463 43.3627 7.78022 42.2601 7.22553 41.3284L3.02717 34.2762C1.90236 32.3868 2.33051 29.9587 4.0337 28.5679L10.3909 23.377C11.2308 22.6912 11.7905 21.7216 11.9645 20.6513L13.2815 12.5504C13.6343 10.38 15.5231 8.79511 17.7217 8.82452L25.9283 8.93432C27.0125 8.94883 28.0646 8.56591 28.8858 7.85786L35.1018 2.49869Z"
      fill="currentColor" />
    <path
      d="M38.0722 41.0121L39.5714 42.5724C39.9649 42.9819 40.6201 42.9819 41.0136 42.5724L52.4484 30.6716C53.0793 30.015 54.1297 30.015 54.7605 30.6716C55.3568 31.2921 55.3568 32.2727 54.7605 32.8932L41.7346 46.4499C40.9477 47.269 39.6373 47.269 38.8503 46.4499L31.353 38.6471C30.7568 38.0265 30.7568 37.046 31.353 36.4255C31.9839 35.7689 33.0343 35.7689 33.6651 36.4255L35.8504 38.6998L38.0722 41.0105V41.0121ZM39.1396 37.4917C38.5451 36.873 38.545 35.8953 39.1395 35.2765L44.7092 29.4787C45.3382 28.8239 46.3857 28.8238 47.0149 29.4786C47.6094 30.0973 47.6094 31.075 47.015 31.6938L41.4453 37.4916C40.8162 38.1464 39.7687 38.1465 39.1396 37.4917ZM32.5636 44.5285C33.1595 45.1485 33.1595 46.1284 32.5636 46.7485C31.9332 47.4046 30.8836 47.4046 30.2531 46.7485L22.4689 38.6471C21.8727 38.0265 21.8727 37.046 22.4689 36.4255C23.0998 35.7689 24.1502 35.7689 24.7811 36.4255L25.8468 37.5346L25.8452 37.5363L32.5636 44.5285Z"
      fill="white" />
  </svg>
</template>