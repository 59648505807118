import { BaseErrorHandlerFactory, HandlerContext } from "@/conductor/ErrorHandlers/BaseErrorHandlerFactory";
import { ConductorEvent, ErrorCodes, ErrorTypes } from "@/conductor/journey-constants";

export class MobileAuthInitErrorHandler extends BaseErrorHandlerFactory {
  constructor(ctx: HandlerContext) {
    super(ctx);
  }

  processError(err: any, reject: (reason?: any) => any): any {
    if (err?.name === "StatusError") {
      const responseObj = err.context;
      if (responseObj?.status === "RETRY") {
        // retriable?
        this.context?.fireJourneyEvents(ConductorEvent.ALT_MOBILE_AA_OTP_RETRY, {}, {}, this.aaErrorContext(responseObj));
        reject();
      } else if (responseObj?.status === "BAD_REQUEST") {
        this.context?.fireJourneyEvents(ConductorEvent.ALT_MOBILE_AA_OTP_FAILED, {}, {}, this.aaErrorContext(responseObj));
        this.handleBadRequest(ErrorCodes.BAD_REQUEST, ErrorTypes.INPUT_ERROR, responseObj.message, true, this.aaErrorContext(responseObj));
      } else if (responseObj?.status === "SESSION_ERROR") {
        this.handleSessionError();
      } else if (responseObj?.status === "ALREADY_REGISTERED") {
        reject("ALREADY_REGISTERED");
      } else {
        // error or any other response
        this.context?.fireJourneyEvents(ConductorEvent.ALT_MOBILE_AA_OTP_FAILED, {}, {}, this.aaErrorContext(responseObj));
        reject();
      }
    } else {
      reject(err);
    }
  }
}