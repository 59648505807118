<template>
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5 11.8V14.8857M3.5 14.8857V11.8M9 11.8V14.8857M1 19H17M1 7.68572H17V5.11429L9 1L1 5.11429V7.68572Z"
      stroke="#00C7B2"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>