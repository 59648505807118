import useBackend from "@/services/api2.service";
import { defineStore } from "pinia";
import { ref, shallowRef, computed, reactive, watch } from "vue";
import { AccountFilterParser } from "../conductor/aa-filters";
import { AaSdkAdapter } from "@/services/aasdk-adapter.service";
import {
  AaWebSdkController,
  ConsentAction,
  LinkedAccount,
} from "@finarkein/aasdk-core";
import useUtilService from "@/services/util.service";
import {
  AaMeta,
  AccountLinkedResProto,
  ConsentRequestInfo,
  Customer,
  DiscoveredAccount,
  DiscoveryStatus,
  Institution,
  InstitutionAccounts,
  InstitutionDiscoveryUpdate,
  UserConsentedAccount,
  UserInfo,
} from "@/conductor/models";
import LogoFinvu from "@/assets/logo-finvu.vue";
import LogoNADL from "@/assets/logo-nadl.vue";
import LogoOnemoney from "@/assets/logo-onemoney.vue";
import LogoSaafe from "@/assets/logo-saafe.vue";
import LogoProtean from "@/assets/logo-protean.vue";
import {
  ConductorFeatures,
  InternalConductorEvent,
} from "@/conductor/journey-constants";
import emitter from "@/composables/event.emitter";
import {
  CONSENT_STATUS, DATA_FETCH_STATUS, DOC_UPLOAD_STATUS, FLOW_STATE, PROTEAN_AA_HANDLE, SAAFE_AA_HANDLE, SALARIED_FLAG_STATUS,
  SALARIED_FLAG_STATUS_CONSENT,
} from "@/constants/constants";
const { indianizeMobileNumber, checkForValidURL, capitalizeFirstLetter } = useUtilService();

export const useAAJourneyStore = defineStore("journeyStore", () => {
  const api = useBackend();

  const exitWorld = ref(false);
  const aaAuth = ref(false);

  // Root level error handling
  const troubleshootError = ref(); // undefined by default

  // Feature specific stuff
  const features = ref(new Map<string, any>());

  // set the templates
  const templates = ref(new Map<string, any>());
  // design / theme related templates
  const themeTemplates = ref({} as any);

  // Current used by Accounts page
  const isProcessing = ref(false);

  const requestId = ref("");
  const processIds = ref<string[]>(); // IMPORTANT: keep initial value undefined

  const discriminator = ref(Date.now());
  const tenantId = ref("");
  const v3Title = ref("");
  const brand = ref({
    color: "",
    logo: "",
    name: "",
  });
  const availableAAs = ref(new Array<AaMeta>());
  const customer = ref<Customer>({ maskedMobile: "" });
  const rawCustomerMobile = computed(() =>
    additionalMobiles.value.length !== 0
      ? additionalMobiles.value[additionalMobiles.value.length - 1]
      : customer.value.mobile || customer.value.maskedMobile,
  );
  const customerMobile = computed(() =>
    indianizeMobileNumber(rawCustomerMobile.value),
  );
  const additionalMobiles = ref<string[]>([]);
  const currentMobile = ref("");
  const selectedBank = ref<any>([]);
  const selectedBanks = ref([] as any);

  // OTP related
  const otpForUser = ref(new Map<string, any>());
  // Journey stuff
  const institutionsLoading = ref(false);
  const institutions = ref(new Map<string, Institution>()); // all institutions
  const selectedInstitutions = ref<Set<string>>(); // default is undefined

  // accounts mangement stuff
  const discoveryQueue = ref<string[]>([]); // institutions to discover accounts from (fipIds)
  const filterParser = ref<AccountFilterParser>();
  const institutionWiseAccounts = ref(new Map<string, InstitutionAccounts>());
  // maintain the count for auto discovery
  const autoDiscoveryCount = ref(0);
  const autoRetryCount = ref(0);

  // Transient states
  const lastKnownStatus = ref<string>("await_init");
  const viewHandler = shallowRef();
  const aaSdkAdapter = ref(new AaSdkAdapter());
  const aaHandle = ref<string>("");
  const extraIdentifiersList = ref<any>([]);
  const aaSdk = ref<AaWebSdkController>();
  const otpReference = ref<string>();
  const maxOTPLimit = ref<any>();
  const awaitNext = ref<Promise<void>>();

  // consent related stuff
  const consentHandle = ref<string>();
  // reqId <> consentHandle
  const consentHandleMap = ref(new Map<string, string>());
  const enabledRequests = ref(new Set<string>());
  const consentDetail = {} as any;
  const consentTemplate = ref<{ id: string; def: any }>();
  const consentTemplates = ref(new Map<string, { id: string; def: any }>());
  const encryptedParams = ref<{
    encryptedFiuId: string;
    encryptedRequest: string;
    requestDate: string;
  }>();
  const encryptedParamsMap = ref(
    new Map<
      string,
      {
        encryptedFiuId: string;
        encryptedRequest: string;
        requestDate: string;
      }
    >(),
  );
  const consentRequestInfo = ref<ConsentRequestInfo>();
  const financialInstruments = computed<string[]>(() => {
    return consentRequestInfo.value?.fiTypes
      ? consentRequestInfo.value.fiTypes
      : consentTemplate.value?.def.fiTypes;
  });
  const consentAction = ref<ConsentAction>();
  const autoDiscoveryTrigger = ref(undefined as any);

  const triggerAltMobile = ref(false);
  const triggerFipLinking = ref(false);

  // set the view type - one time ops
  const viewsType = ref();
  // current view
  const currentView = ref<string>("");
  // previous view
  const previousHandler = ref<string>("");
  // TODO: temp, fix this later on
  // const selectFipsViewCount = ref(0);

  // fip filters
  const fipFilters = ref([] as any);

  // more details to support v2 & v2X journey
  const receivedXid = ref("");
  const onCompleteRedirectUrl = ref();
  const altRedirectUrl = ref("");
  const redirectMode = ref(undefined);
  const flowState = ref("");
  const flowStatus = ref({} as any);
  const errorObject = ref();
  const webRedirectUrl = ref();
  // const extraUserDetails = ref([] as any);
  // const requestAlreadyProcessed = ref(false);
  const aaHandleLogo = ref();
  const selectedFipList = ref([] as any);
  const receivedAuth = ref();

  // for maintaining state of asking for extra details
  const askAdditionalInfo = ref(false);
  // const eventEmitter = emitter;
  // const altRedirectUrl = ref();
  const eventEmitter = emitter;
  const extraJourneyReq = ref(); // received journey type & other details
  // extra user details
  const extraUserDetails = ref([] as any);
  const mutualFundsData = ref([] as any);
  // upload manually activate
  const exitFlowUploadManually = ref(false);
  const denyConsent = ref(false);
  const docUploadManually = ref(false);
  const cancelJourney = ref(false);
  // show otp toaster
  const showOtpSentPopup = ref(false);
  // text interpolation keys
  const orgName = ref("");
  const policyNumber = ref("");
  const userInfo = ref();

  const userConsentedAccounts = ref([] as UserConsentedAccount[]);
  const workspace = ref(); // TODO: move this to org details
  const parentId = ref();
  // Feature functions
  function getFeature<T>(featureName: string, defaultValue?: T): T {
    if (features.value.has(featureName)) {
      return features.value.get(featureName) as T;
    } else {
      return defaultValue as T;
    }
  }

  function setFeature<T>(featureaName: string, value: T): void {
    // Simply overwrite
    features.value.set(featureaName, value);
  }
  // Template functions
  function getTemplates<T>(templateName: string, defaultValue?: T): T {
    if (templates.value.has(templateName)) {
      return templates.value.get(templateName) as T;
    } else {
      return defaultValue as T;
    }
  }

  function setTemplate<T>(templateName: string, value: T): void {
    // Simply overwrite
    templates.value.set(templateName, value);
  }

  // Utility functions
  function preInit(reqId: string, subRequestIds?: string[]) {
    requestId.value = reqId; // the parent/main request id
    // for backward comptability, re-fill requestId if subRequestIds are not present
    const idToProcess = subRequestIds ?? [reqId];
    processIds.value = idToProcess;
  }

  function resolveMobileDetails(input: string) {
    const isAllDigits = /^\d+$/.test(input);
    if (isAllDigits) {
      // we have mobile in plain-text
      // replace first 6 digits with 'X'
      const maskedMobile = input.replace(/^(\d{6})/, "XXXXXX");
      return {
        maskedMobile,
        mobile: input,
      };
    } else {
      return {
        maskedMobile: input,
      };
    }
  }

  function setEnabledRequests(reqIds: string[]) {
    enabledRequests.value.clear(); // clean up
    reqIds.map(addEnabledRequest);
  }

  function addEnabledRequest(reqId: string) {
    enabledRequests.value.add(reqId);
  }

  function removeEnabledRequest(reqId: string) {
    enabledRequests.value.delete(reqId);
  }

  function isRequestEnabled(reqId: string): boolean {
    return enabledRequests.value.has(reqId);
  }

  function updateFromRequestDetails(
    reqId: string,
    data: any,
    childReqId?: string,
  ) {
    requestId.value = requestId.value ?? reqId; // disallow resetting of `requestId`!
    if (!processIds.value && requestId.value === reqId) {
      processIds.value = [reqId];
    }

    const currentReqId = childReqId ?? reqId; // use child request id for further references below
    // [NEW] Assume this request to be enabled
    addEnabledRequest(currentReqId);

    tenantId.value = data.id;
    workspace.value = data.workspace;
    if (data.templates) {
      v3Title.value = data.templates["v3.title"];
    } else {
      v3Title.value = "Cash Loan";
    }
    fipFilters.value = data.accountFilters || [];
    const brandInfo = data.brandInfo;
    updateBrand(data.orgName, brandInfo.color, brandInfo.logo);
    setAaMeta(data.aa);
    updateCustomer(resolveMobileDetails(data.customer.mobileNumber));
    if (data.webviewConsentTemplate) {
      consentDetail.value = data.webviewConsentTemplate.def;
      updateConsenTemplate(data.webviewConsentTemplate, currentReqId);
    }
    // to support the v2 layout , further details that are required
    if (data.extraConfig?.xid) {
      receivedXid.value = data.extraConfig.xid;
    }
    if (data.extraConfig?.onCompleteRedirectUrl) {
      onCompleteRedirectUrl.value = data.extraConfig.onCompleteRedirectUrl;
    }
    if (data.extraConfig?.altRedirectUrl) {
      altRedirectUrl.value = data.extraConfig.altRedirectUrl;
    }
    if (data.extraConfig?.mode) {
      redirectMode.value = data.extraConfig.mode;
    }
    if (data.aaHandle) {
      aaHandle.value = data.aaHandle;
    }

    if (data.flowState) {
      flowState.value = data.flowState;
    }
    if (data.redirectUrl && !onCompleteRedirectUrl.value) {
      onCompleteRedirectUrl.value = data.redirectUrl;
    }
    if (data.altRedirectUrl && !altRedirectUrl.value) {
      altRedirectUrl.value = data.altRedirectUrl;
    }
    if (data.orgName) {
      orgName.value = data.orgName;
    }
    if (data.policyNumber) {
      policyNumber.value = data.policyNumber;
    }

    if (data.flowStatus) {
      flowStatus.value = data.flowStatus;
    }

    if (data.userInfo) {
      userInfo.value = data.userInfo;
    }

    // if we get user details from the service
    // update extra details if present

    updateExtraUserDetails(data);
  }

  // show doc upload after consent granted
  const documentUploadStatus = computed(() => {
    const result
      = (flowState.value === FLOW_STATE.WAITING
        || flowState.value === FLOW_STATE.RUNNING)
        && (flowStatus.value.dataFetchStatus === DATA_FETCH_STATUS.PENDING
          || flowStatus.value.dataFetchStatus === DATA_FETCH_STATUS.FAILED)
          && (flowStatus.value.consentStatus === CONSENT_STATUS.PENDING
            || flowStatus.value.consentStatus === CONSENT_STATUS.ACTIVE
            || flowStatus.value.consentStatus === CONSENT_STATUS.REVOKED
            || flowStatus.value.consentStatus === CONSENT_STATUS.REJECTED
            || flowStatus.value.consentStatus === CONSENT_STATUS.EXPIRED)
            && flowStatus.value.docUpload === DOC_UPLOAD_STATUS.PENDING;
    return result;
  });

  function updateConsenTemplate(template: any, reqId: string) {
    consentTemplate.value = template; // overwrite for current flow compatability
    consentTemplates.value.set(reqId, template);
  }

  function updateBrand(name: string, color: string, logo: string) {
    brand.value.name = name;
    brand.value.color = color;
    brand.value.logo = logo;
  }

  function setAaMeta(metas: Array<AaMeta>) {
    availableAAs.value.length = 0; // reset whatever is available
    metas.forEach(a => availableAAs.value.push(a));
  }

  function updateCustomer(params: Partial<Customer>) {
    // use the spread operator to apply updates
    customer.value = {
      ...customer.value,
      ...params,
    };
  }

  // ==== institutions ==========
  function setSelectedInstitutions(institutions: Set<string>) {
    selectedInstitutions.value = institutions;
  }

  // Actions, called by conductor
  async function loadInstitutions() {
    institutionsLoading.value = true;
    return api
      .getBankList(requestId.value)
      .then((response) => {
        const data = response.data;
        institutions.value = data.content;
      })
      .finally(() => {
        // institutionsLoading.value = false;
      });
  }

  function getInstitutions() {
    return institutions;
  }

  function updateWebViewParams(data: any, reqId?: string) {
    encryptedParams.value = data;
    reqId = reqId! ?? requestId;
    encryptedParamsMap.value.set(reqId, data);
  }

  function updateConsentHandle(handle: string, reqId?: string) {
    consentHandle.value = handle; // overwrite for backward comptability
    reqId = reqId! ?? requestId;
    consentHandleMap.value.set(reqId, handle);
  }

  /**
   * This method requries all institutions to be already populated
   * @param accounts
   * @param identifierSeq
   */
  function updateLinkedAccounts(
    accounts: LinkedAccount[],
    identifierSeq: number = 0,
  ) {
    accounts.forEach((account) => {
      let institution = institutions.value.get(account.fipId);
      let stable = true;
      // || (!institution.aa.includes(aaHandle.value)) || institution.aa.length ===0
      if (
        !institution
        || !institution.aa.includes(aaHandle.value)
        || institution.aa.length === 0
      ) {
        // TODO: Probably cause the bank is down at the moment?
        stable = false;
        institution = {
          aa: [],
          fiTypes: [],
          id: account.fipId,
          name: account.fipName,
          version: "1.1.2",
        };
      }

      const ia = _createOrGetIA(account.fipId, institution, stable);
      ia.addOrUpdateLinkedAccount(
        { ...account, identifierSeq },
        getFeature(ConductorFeatures.ACCOUNTS_AUTO_SELECT, true),
        getFeature(ConductorFeatures.AA_ACCOUNT_FILTER),
      );
    });
  }

  function _createOrGetIA(
    id: string,
    institutionHint: Institution,
    stable: boolean = true,
  ) {
    // assume stable always
    const map = institutionWiseAccounts.value;
    let ia = map.get(id);
    if (ia == undefined) {
      ia = new InstitutionAccounts(institutionHint, stable);
      map.set(id, ia);
    }
    return ia;
  }

  function linkDiscoveredAccounts(
    res: AccountLinkedResProto,
    institution: Institution,
  ) {
    // Ensure Institution exists
    const ia = institutionWiseAccounts.value.get(institution.id);
    if (!ia) {
      // something really really wrong happened!
      return;
    }
    ia.linkPreviouslyDiscoveredAccounts(
      res.AccLinkDetails,
      getFeature(ConductorFeatures.ACCOUNTS_AUTO_SELECT, true),
      getFeature(ConductorFeatures.AA_ACCOUNT_FILTER),
    );
  }

  function updateDiscoveryStatus(
    update: Partial<InstitutionDiscoveryUpdate>,
    institution: Institution,
  ) {
    const ia = _createOrGetIA(institution.id, institution);
    ia.updateDiscoveryStatus(update);
  }

  function updateDiscoveredAccounts(
    accounts: DiscoveredAccount[],
    institution: Institution,
    idSeq = 0,
  ) {
    const ia = _createOrGetIA(institution.id, institution);
    ia.setDiscoveredAccounts(
      accounts,
      idSeq,
      getFeature(ConductorFeatures.ACCOUNTS_AUTO_SELECT, true),
      getFeature(ConductorFeatures.AA_ACCOUNT_FILTER),
    );
  }

  function updateInstitutionDiscoveryQueue(ids: string[]) {
    discoveryQueue.value = ids;
  }

  function updateUserInformation(data: UserInfo) {
    customer.value.mobile = data.mobileNo;
    customer.value.userId = data.userId;
  }

  function updateInstitutions(data: Institution[]) {
    institutions.value = data.reduce((result, current) => {
      result.set(current.id, current);
      return result;
    }, new Map<string, Institution>());
  }

  function updateConsentRequestInfo(data: ConsentRequestInfo) {
    consentRequestInfo.value = data;
  }

  function $reset() {
    exitWorld.value = false;
    troubleshootError.value = undefined;
    institutionWiseAccounts.value = new Map<string, InstitutionAccounts>();
    isProcessing.value = false;
    aaAuth.value = false;
    otpReference.value = undefined;
    autoDiscoveryTrigger.value = undefined;
    triggerAltMobile.value = false;
    triggerFipLinking.value = false;
    eventEmitter.off(InternalConductorEvent.SESSION_ERROR);
  }
  const totalAccountsCount = computed(() => {
    let tempTotal = 0;
    for (const val of institutionWiseAccounts.value.values()) {
      if (val.stable) tempTotal = tempTotal + val.allAccounts().value.length;
    }
    return tempTotal;
  });

  const totalDiscoveredCount = computed(() => {
    let tempTotal = 0;
    for (const val of institutionWiseAccounts.value.values()) {
      if (val.stable)
        tempTotal = tempTotal + val.getDiscoveredAccounts().value.length;
    }
    return tempTotal;
  });

  const removeListener = computed(() => {
    if (!consentAction.value) {
      return 0;
    } else {
      return 1;
    }
  });

  const workingInstitutions = computed(() => {
    const all = institutionWiseAccounts.value.values();
    return Array.from(all).reduce((result, curr) => {
      if (curr.stable && curr.allAccounts().value.length > 0)
        result.set(curr.meta.id, curr);
      return result;
    }, new Map<string, InstitutionAccounts>());
  });

  const anythingLoading = computed(() => {
    if (isProcessing.value) return true; // something is loading actually
    const working = workingInstitutions.value.values();
    for (const wi of working) {
      if (wi.loading().value) {
        return true;
      }
    }
    return false;
  });

  const selectedSet = computed(() => {
    const all = workingInstitutions.value.values();
    return Array.from(all).reduce((result: any, curr: any) => {
      if (curr.selections.length > 0) result.set(curr.meta.id, curr);
      return result;
    }, new Map<string, InstitutionAccounts>());
  }) as any;

  const getAALogo = computed(() => {
    if (aaHandle.value === "finvu") {
      return LogoFinvu;
    } else if (aaHandle.value === SAAFE_AA_HANDLE) {
      return LogoSaafe;
    } else if (aaHandle.value === "onemoney") {
      return LogoOnemoney;
    } else if (aaHandle.value === PROTEAN_AA_HANDLE) {
      return LogoProtean;
    } else {
      return LogoNADL;
    }
  });

  // const aaHandleName = computed(() => {
  //   if (aaHandle.value === "finvu") {
  //     return 'Finvu';
  //   } else if(aaHandle.value === SAAFE_AA_HANDLE){
  //     return 'Saafe';
  //   }else if(aaHandle.value === "onemoney"){
  //     return 'Onemoney';
  //   }else if(aaHandle.value === PROTEAN_AA_HANDLE){
  //     return 'Protean';
  //   }else {
  //     return 'NADL';
  //   }
  // });
  // to check if atleast accounts of 1 Fip is discovered
  const bankFound = computed(() => {
    for (const val of workingInstitutions.value.values()) {
      if (!val.noAccounts().value) {
        return true;
      }
    }
    return false;
  });

  // total accounts that are rendered
  const totalAccounts = computed(() => {
    let tempTotal = 0;
    for (const acc of workingInstitutions.value.values()) {
      tempTotal = tempTotal + acc.allAccounts().value.length;
    }
    return tempTotal;
  });
  // selected accounts count
  const selectedAccountsCount = computed(() => {
    let tempTotal = 0;
    for (const acc of workingInstitutions.value.values()) {
      tempTotal = tempTotal + acc.selections.length;
    }

    return tempTotal;
  });

  const noAccountSelected = computed(() => {
    for (const ia of workingInstitutions.value.values()) {
      if (ia.selections.length > 0) {
        return false;
      }
    }
    return true;
  });

  const isAutoDiscoveryInProgress = computed(() => {
    for (const val of institutionWiseAccounts.value.values()) {
      if (val.isAutoDiscovery().value && val.loading().value) {
        return true;
      }
    }
    return false;
  });

  const missingAcc = computed(() => {
    return (
      aaAuth.value && totalAccountsCount.value === 0 && !anythingLoading.value
    );
  });

  const noFipFilters = computed(() => {
    return missingAcc.value && fipFilters.value.length === 0;
  });

  const listOfSelectedAccounts = computed(() => {
    const listOfAccounts: any[] = [];
    for (const acc of workingInstitutions.value.values()) {
      if (acc.selections.length > 0) {
        acc.selections.forEach((data) => {
          listOfAccounts.push(data);
        });
      }
    }
    return listOfAccounts;
  });

  const somethingLoading = computed(() => {
    if (isProcessing.value) return true; // something is loading actually
    const instAcc = institutionWiseAccounts.value.values();
    for (const wi of instAcc) {
      if (wi.loading().value) {
        return true;
      }
    }
    return false;
  });

  const finalRedirectionUrl = computed(() => {
    const redirectionUrl
      = consentAction.value === ConsentAction.ACCEPT || ConsentAction.DENY
        ? onCompleteRedirectUrl.value
        : altRedirectUrl.value;
    let finalUrl = "";
    finalUrl = checkForValidURL(redirectionUrl)!;
    return finalUrl;
  });

  const linkedAccountsPresent = computed(() => {
    for (const val of workingInstitutions.value.values()) {
      if (val.getLinkedAccounts().value.length > 0) {
        return true;
      }
    }
    return false;
  });

  const totalLinkedAccountsCount = computed(() => {
    let tempTotal = 0;
    for (const val of institutionWiseAccounts.value.values()) {
      if (val.stable)
        tempTotal = tempTotal + val.getLinkedAccounts().value.length;
    }
    return tempTotal;
  });

  const getLogoFromAAHandle = computed(() => {
    /* return availableAAs.value.filter((aa) => aa.handle === aaHandle.value)[0]
      .logo; */
    const aaLogo = availableAAs.value.filter(
      aa => aa.handle === aaHandle.value,
    );
    return aaLogo.length > 0 ? aaLogo[0].logo : "";
  });

  const getAAHandleDisplayVal = computed(() => {
    const aa = availableAAs.value.filter(
      aa => aa.handle === aaHandle.value,
    );
    if (aa.length && aa[0]?.name) {
      return aa[0].name;
    }
    // Fallback for name if name is not provided from backend
    if (aaHandle.value === SAAFE_AA_HANDLE) {
      return "Saafe";
    } else if (aaHandle.value === PROTEAN_AA_HANDLE) {
      return "Protean SurakshAA";
    }
    return capitalizeFirstLetter(aaHandle.value);
  });

  const getOtpResendTimer = computed(() => {
    const aa = availableAAs.value.find(aa => aa.handle === aaHandle.value);
    if (aa && aa.extraConfig) {
      return aa.extraConfig["ui.aa.altMobile.otp.resend.timerInSeconds"];
    } else {
      return 30;
    }
  });

  const getOtpLength = computed(() => {
    const aa = availableAAs.value.find(aa => aa.handle === aaHandle.value);
    if (aa?.extraConfig?.["ui.aa.otp.length"]) {
      return aa.extraConfig["ui.aa.otp.length"];
    } else {
      // for saafe otp length is 4
      return aa?.handle === SAAFE_AA_HANDLE ? 4 : 6;
    }
  });

  function updateExtraUserDetails(details?: any) {
    extraUserDetails.value = [];

    // PAN
    const pan = details?.identifiers?.pan || details?.userInfo?.pan;
    if (pan) {
      extraUserDetails.value.push({
        type: "PAN",
        value: pan,
      });
    }

    // DOB
    const dob = details?.identifiers?.dob || details?.userInfo?.dob;
    if (dob) {
      extraUserDetails.value.push({
        type: "DOB",
        value: dob,
      });
    }

    // Email
    const email = details?.identifiers?.email || details?.userInfo?.email;
    if (email) {
      extraUserDetails.value.push({
        type: "EMAIL",
        value: email,
      });
    }

    // Mobile
    const mobile = details?.customer?.mobileNumber || details?.userInfo?.mobileNumber;
    if (mobile) {
      extraUserDetails.value.push({
        type: "MOBILE",
        value: mobile,
      });
    }
  }

  const workingInstitutionsWithLinkedAccounts = computed(() => {
    const all = institutionWiseAccounts.value.values();
    return Array.from(all).reduce((result, curr) => {
      if (curr.stable && curr.getLinkedAccounts().value.length > 0)
        result.set(curr.meta.id, curr);
      return result;
    }, new Map<string, InstitutionAccounts>());
  });

  /* grouping of fips based on fitypes */

  const groupedFiTypeAccounts = computed(() => {
    const result = new Map<string, Map<string, any>>();

    for (const [
      fipId,
      institutionAccounts,
    ] of workingInstitutionsWithLinkedAccounts.value.entries()) {
      const { discovery, accounts, selections } = institutionAccounts;

      for (const fiType of financialInstruments.value) {
        const filteredAccounts = new Map(
          Array.from(accounts.entries()).filter(
            value => value[1].FIType === fiType,
          ),
        );
        const filteredSelections = selections.filter(
          account => account.FIType === fiType,
        );
        const filteredLastResult = discovery.lastResult.filter(
          acc => acc.FIType === fiType,
        );

        if (!result.has(fiType)) {
          result.set(fiType, new Map());
        }

        const fipAccounts = result.get(fiType)!.get(fipId) || {
          ...institutionAccounts,
        };

        fipAccounts.discovery = {
          discovering: discovery.discovering,
          status: discovery.status,
          lastResult: filteredLastResult,
          auto: discovery.auto,
        };
        fipAccounts._maskToAccRef = reactive(
          new Map(
            Array.from(filteredAccounts.values()).map(
              ({ maskedAccNumber, accRefNumber }) => [
                maskedAccNumber,
                accRefNumber,
              ],
            ),
          ),
        );
        fipAccounts.accounts = reactive(filteredAccounts);
        fipAccounts.selections = reactive(filteredSelections);

        result.get(fiType)!.set(fipId, fipAccounts);
      }
    }

    return result;
  });

  function getValuesByFip(fipId: any) {
    const accountsValues = [];
    for (const [
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      fiType,
      institutions,
    ] of groupedFiTypeAccounts.value.entries()) {
      if (institutions.has(fipId)) {
        const account = institutions.get(fipId);
        const accountsArray = Array.from(account.accounts.values());
        accountsValues.push(accountsArray);
      }
    }
    return accountsValues;
  }

  const getAccountsByFiTypeAndFiId = (fiType: any, fiId: any) => {
    const accounts = [];
    for (const [ftype, institutions] of groupedFiTypeAccounts.value.entries()) {
      if (ftype === fiType && institutions.has(fiId)) {
        const account = institutions.get(fiId);
        accounts.push(Array.from(account.accounts.values()));
      }
    }
    return accounts.flat();
  };

  const listOfFinancialInstruments = computed(() => {
    let listOfAvailableFiTypes = [] as string[];
    for (const data of consentTemplates.value.values()) {
      listOfAvailableFiTypes = listOfAvailableFiTypes.concat(data.def.fiTypes);
    }
    return Array.from(new Set(listOfAvailableFiTypes)) as string[];
  });

  const consentMappedWithHandle = computed(() => {
    const result = new Map();
    for (const [reqId, someValue] of consentHandleMap.value.entries()) {
      result.set(someValue, consentTemplates.value.get(reqId));
    }
    return result;
  });

  const state = reactive({}) as any;

  const addProperty = (key: string, value: any) => {
    state[key] = value;
  };

  const updateProperty = (key: string, value: any) => {
    if (key in state) {
      state[key] = value;
    } else {
      console.warn(`Property ${key} does not exist in the state.`);
    }
  };

  const getPropertyValue = (key: string) => {
    if (key in state) {
      return state[key];
    } else {
      console.warn(`Property ${key} does not exist in the state.`);
    }
  };

  const noAccounts = computed(() => {
    for (const val of institutionWiseAccounts.value.values()) {
      if (
        val.stable
        && val.getDiscoveredAccStatus() !== DiscoveryStatus.NO_ACCOUNTS
      ) {
        return false; // If any institution does not meet the condition, return false
      }
    }
    return true; // If all institutions meet the condition
  });

  function clearWorkingInstitutionSelections(unlinkedOnly = false) {
    workingInstitutions.value.forEach((wi) => {
      if (unlinkedOnly) {
        const linkedSelections = wi.selections.filter(fa => fa.isLinked());
        wi.selections = linkedSelections;
      } else {
        wi.selections.length = 0;
      }
    });
  }

  const allDiscoveryFailed = ref(false);
  const evaluateAllDiscoveryFailed = () => {
    let failed = true;
    for (const val of institutionWiseAccounts.value.values()) {
      if (
        val.stable
        && val.getDiscoveredAccStatus() !== DiscoveryStatus.FAILED
        && val.loading()
      ) {
        failed = false; // If any institution does not meet the condition, set to false
        break;
      }
    }
    allDiscoveryFailed.value = false; // Reset to trigger the watcher
    setTimeout(() => {
      allDiscoveryFailed.value = failed; // Set the actual value after a brief delay
    }, 500);
  };
  watch(somethingLoading, (newVal) => {
    if (!newVal) {
      evaluateAllDiscoveryFailed();
    }
  });

  const isIframeAllowed = computed(() => {
    const aa = availableAAs.value.find(aa => aa.handle === aaHandle.value);
    if (aa && aa.extraConfig) {
      return aa.extraConfig["iframe"] && getFeature(ConductorFeatures.IFRAME_ALLOWED, false);
      // return false;
    } else {
      return false;
    }
  });

  const salariedSubtitle = computed(() => {
    const flag = userInfo.value?.salaried;

    if (!flag || flag === undefined) {
      return "";
    }
    return flag === "yes" ? SALARIED_FLAG_STATUS.SALARIED : SALARIED_FLAG_STATUS.NONSALARIED;
  });

  const salariedSubtitleConsent = computed(() => {
    const flag = userInfo.value?.salaried;

    if (!flag || flag === undefined) {
      return "";
    }
    return flag === "yes" ? SALARIED_FLAG_STATUS_CONSENT.SALARIED : SALARIED_FLAG_STATUS_CONSENT.NONSALARIED;
  });

  // ==== Consent Detail ==========
  // const route = useRoute();
  // const ide: any = route.params.identifier;
  // const consentRes = ref({} as any);
  // // console.log(consentRes.value);
  // function consentDetail() {
  //     api.getRequestDetails(ide)
  //     .then((response: any) => {
  //         console.log(response.webviewConsentTemplate.def);
  //         consentRes.value = response.webviewConsentTemplate.def;
  //     })
  // }

  const contextualObj = computed(() => {
    const obj = {
      policyNumber: policyNumber.value || "",
      orgName: orgName.value || "",
      userName: userInfo.value?.name?.givenName || "",
      incomeType: salariedSubtitle.value || "",
      incomeTypeConsent: salariedSubtitleConsent.value || "",
      aaHandle: getAAHandleDisplayVal.value || "",
      mobileNumber: rawCustomerMobile.value || "",
    };
    return obj;
  });

  return {
    brand,
    isProcessing,
    exitWorld,
    aaAuth,

    requestId,
    processIds,
    discriminator,
    tenantId,
    v3Title,
    customer,
    customerMobile,
    filterParser,
    lastKnownStatus,
    viewHandler,
    aaSdkAdapter,
    aaHandle,
    aaSdk,
    availableAAs,

    encryptedParams,
    encryptedParamsMap,
    consentHandle,
    consentHandleMap,
    enabledRequests,
    consentRequestInfo,
    consentDetail,
    financialInstruments,
    consentAction,
    otpReference,
    awaitNext,
    institutionWiseAccounts,
    additionalMobiles,
    totalAccountsCount,
    // some discovery metadata
    discoveryQueue,
    autoDiscoveryCount,
    removeListener,
    consentTemplate,
    consentTemplates,

    preInit,
    updateFromRequestDetails,
    loadInstitutions,
    setSelectedInstitutions,
    getInstitutions,
    updateWebViewParams,
    updateConsentHandle,
    updateLinkedAccounts,
    updateInstitutionDiscoveryQueue,
    updateDiscoveredAccounts,
    linkDiscoveredAccounts,
    updateDiscoveryStatus,
    updateUserInformation,
    updateInstitutions,
    updateConsentRequestInfo,
    updateConsenTemplate,
    clearWorkingInstitutionSelections,

    troubleshootError,
    $reset,
    otpForUser,
    currentMobile,

    // FEATURES specific
    getFeature,
    setFeature,

    workingInstitutions,
    anythingLoading,
    selectedSet,
    getAALogo,
    bankFound,
    totalAccounts,
    selectedAccountsCount,
    noAccountSelected,
    isAutoDiscoveryInProgress,
    features,
    missingAcc,

    autoDiscoveryTrigger,
    triggerAltMobile,
    triggerFipLinking,

    getTemplates,
    setTemplate,
    templates, // list of all the templates( text changes)
    themeTemplates, // has all the design related feat in an obj format
    viewsType,
    currentView,
    autoRetryCount, // no of manual auto retries of auto discovery
    rawCustomerMobile,
    previousHandler,
    noFipFilters, // no fip filters
    fipFilters,
    selectedFipList,
    listOfSelectedAccounts,
    somethingLoading,
    // v2 related stuff, refactor later
    flowState,
    errorObject,
    // requestAlreadyProcessed,
    webRedirectUrl,
    aaHandleLogo,
    redirectMode,
    receivedXid,
    onCompleteRedirectUrl,
    altRedirectUrl,
    finalRedirectionUrl,
    linkedAccountsPresent,
    totalDiscoveredCount,
    extraUserDetails,
    askAdditionalInfo,
    totalLinkedAccountsCount,
    getLogoFromAAHandle,
    receivedAuth,

    // getLogoFromAAHandle,
    extraJourneyReq,
    mutualFundsData,
    // extraUserDetails
    exitFlowUploadManually,
    docUploadManually,
    cancelJourney,
    workingInstitutionsWithLinkedAccounts,
    groupedFiTypeAccounts,
    getValuesByFip,
    getAccountsByFiTypeAndFiId,
    // groupedLinkedAccounts,
    getOtpResendTimer,
    getOtpLength,
    listOfFinancialInstruments,
    consentMappedWithHandle,
    denyConsent,
    // add dynamic keys to pinia store
    state,
    addProperty,
    updateProperty,
    getPropertyValue,
    allDiscoveryFailed,
    noAccounts,
    evaluateAllDiscoveryFailed,

    // flowStatus
    flowStatus,
    documentUploadStatus,

    // bank selection
    selectedBank,
    selectedBanks,

    setEnabledRequests,
    addEnabledRequest,
    removeEnabledRequest,
    isRequestEnabled,
    showOtpSentPopup,
    userConsentedAccounts,
    workspace,
    extraIdentifiersList,
    isIframeAllowed,
    getAAHandleDisplayVal,
    contextualObj,
    userInfo,
    parentId,
    maxOTPLimit,
  };
});