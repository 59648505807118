import { JourneyFeatures } from "@/constants/constants";
import { AaSdkAdapter } from "@/services/aasdk-adapter.service";
import { ConsentAction, LinkedAccount } from "@finarkein/aasdk-core";
import { useStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { PROTEAN_AA_HANDLE, SAAFE_AA_HANDLE } from "@/constants/constants";

export type AaMeta = {
  handle: string;
  id: string;
  logo?: string;
  version?: string;
  termsCondition?: string;
  extraConfig?: any;
};

export type Customer = {
  maskedMobile: string;
  mobile?: string;
  maskedHandle?: string;
  handle?: string;
};

export type ConsentRequestDetails = {
  consentHandle: string;
  redirectUrl?: string;
  webviewBaseUrl?: string;
  txnId?: string;
  parameters?: {
    fi: string;
    ecreq: string;
    reqdate: string;
  };
} & Record<string, any>;

export type EncrypedParams = {
  encryptedRequest?: string;
  requestDate?: string;
  encryptedFiuId?: string;
};

export type linkAccProto = {
  // userId: string,
  fipId: string;
  fipName: string;
  maskedAccNumber: string;
  accRefNumber: string;
  linkRefNumber: string;
  // consentIdList: [],
  FIType: string;
  accType: string;
  // linkedAccountUpdateTimestamp: string,
  // AuthenticatorType: string,
};

export type ConsentResponse = {
  status: string;
  message: null;
  consentId: null;
  ver: string;
  txnid: string;
  consentStatus: any;
  createTime: string;
  startTime: string;
  expireTime: string;
  statusLastupdateTimestamp: string;
  FIP: any;
  AA: object;
  FIU: {
    id?: string;
    name?: string;
  };
  User: object;
  Accounts: any;
  ConsentUse: any;
  DataAccess: any;
  Purpose: object;
  Signature: string;
  mode: string;
  fetchType: string;
  consentTypes: [];
  consentDisplayDescriptions: [];
  fiTypes: [];
  DataDateTimeRange: object;
  DataLife: object;
  Frequency: object;
  DataFilter: [];
  consentDetailDigitalSignature: any;
  ConsentHandle: string;
};

export type FIPConsentInfoResponseProto = {
  fipConsentInfos: {
    fipId: string;
    consentId: string;
  }[];
  status: string;
  message: string;
};

export type discoveredAccountProto = {
  accType: string;
  accRefNumber: string;
  maskedAccNumber: string;
  FIType: string;
};

export type AccountLinkingResponseProto = {
  status: string;
  message: string;
  ver: string;
  timestamp: string;
  txnid: string;
  AuthenticatorType: string;
  RefNumber: string;
};

export type AccountLinkedResProto = {
  status: string;
  message: string;
  ver: string;
  timestamp: string;
  txnid: string;
  AccLinkDetails: [];
};

export type MarkCompleteDetails = {
  decryptedValues: {
    errorCode: number;
    fi: string;
    redirectUrl: string;
    sessionId: string;
    srcRef: string;
    status: string;
    txnId: string;
    userId: string;
  };
  updateStatus: boolean;
};

export type UserInfo = {
  userId: string;
  mobileNo: string;
  mobileAuthenticated: string;
  emailId: string;
  emailAuthenticated: string;
};

export const usev2Store = defineStore("store-v2", () => {
  // Utility internal variables
  const discriminator = ref(Date.now());
  const brand = ref({
    color: "",
    logo: "",
    name: "",
  });
  const availableAAs = ref(new Array<AaMeta>());
  const customer = ref<Customer>({ maskedMobile: "" });
  const availableFips = ref(new Array<any>());

  const otpDetails = ref({ otpReference: "" });
  const aaSdkAdapter = ref(new AaSdkAdapter()); // TREAT as singleton! Don't update this ref!
  const availableAccountFilters = ref([] as any);

  // Store to maintain AA related information
  const aaHandle = ref(""); // Account Aggregator chosen for the user journey
  const consentRequestDetails = ref<ConsentRequestDetails>();
  const LinkAccountsList = ref<linkAccProto[]>([]);
  const DiscoveredAccounts = ref([]);
  const consentRes = ref<ConsentResponse>();
  const selectedFip = ref<string[]>([]);
  const fipConsentReqResponse = ref<FIPConsentInfoResponseProto>();
  const aaHandleLogo = ref(""); // Account Aggregator Logo
  const accountLinkingResponse = ref<AccountLinkingResponseProto>();
  const accountLinkedResponse = ref();
  const requestAlreadyProcessed = ref(false);
  const exit = ref(false);
  const mobileNos = ref([] as any);
  const LinkedAccountsMap = ref();
  const DiscoveredAccountsMap = ref(new Map<string, any>());
  const mobileSelected = ref("");
  const xid = ref("");
  const onCompleteRedirectUrl = ref("");
  const altRedirectUrl = ref("");
  const reqId = ref("");
  const tenantId = ref("");
  const availableParams = ref<EncrypedParams>();
  const userData = ref<UserInfo>();
  const userConsentAction = ref<ConsentAction>();
  const receivedXid = ref("");
  const failureOccured = ref(false);
  const redirectMode = ref(undefined);
  const errorObject = ref();
  const webRedirectUrl = ref();
  const aaFipMappings = ref();
  const markCompleteStatus = ref({
    status: "",
    errorCode: "",
    userId: "",
    consentHandle: "",
    errorHandleTxt: "",
  });
  const flowState = ref("");
  const supportedAAs = ref(["finvu", "nadl", SAAFE_AA_HANDLE, "onemoney", PROTEAN_AA_HANDLE]);
  // const maxSelections = ref(-1);
  const consentTemplateDef = ref();
  const featureList = ref(new Map<string, any>());
  const routeState = useStorage("LAST_ROUTE", "");
  // store the details of the extra identifiers provided by the user
  const extraIdentifiers = ref([] as any); //
  const getOtpResendTimer = computed(() => {
    const aa = availableAAs.value.find(aa => aa.handle === aaHandle.value);
    if (aa && aa.extraConfig) {
      return aa?.extraConfig["ui.aa.altMobile.otp.resend.timerInSeconds"];
    } else {
      return "60";
    }
  });

  const getOtpLength = computed(() => {
    const aa = availableAAs.value.find(aa => aa.handle === aaHandle.value);
    if (aa?.extraConfig?.["ui.aa.otp.length"]) {
      return aa.extraConfig["ui.aa.otp.length"];
    } else {
      // for saafe otp length is 4
      return aa?.handle === SAAFE_AA_HANDLE ? 4 : 6;
    }
  });

  const alternateMobileFlow = computed(() => {
    const aa = availableAAs.value.find(aa => aa.handle === aaHandle.value);
    if (aa && aa.extraConfig) {
      return (aa?.extraConfig["ui.aa.altMobile"] ? getFeature(JourneyFeatures.ALT_MOBILE, false) : false);
    } else {
      return false;
    }
  });

  function updateBrand(name: string, color: string, logo: string) {
    brand.value.name = name;
    brand.value.color = color;
    brand.value.logo = logo;
  }

  function setAaMeta(metas: Array<AaMeta>) {
    availableAAs.value.length = 0; // reset whatever is available
    metas.forEach(a => availableAAs.value.push(a));
  }

  function updateCustomer(params: Partial<Customer>) {
    // use the spread operator to apply updates
    customer.value = {
      ...customer.value,
      ...params,
    };
  }

  function updateParams(params: Partial<EncrypedParams>) {
    availableParams.value = {
      ...params,
    };
  }

  function setOtpDetails(otpReference: string) {
    otpDetails.value = {
      otpReference: otpReference,
    };
  }

  function storeConsentDetails(consentDetails: ConsentRequestDetails) {
    consentRequestDetails.value = {
      ...consentRequestDetails.value,
      ...consentDetails,
    };
  }

  function storeConsentResponse(consentDetails: ConsentResponse) {
    consentRes.value = {
      ...consentRes.value,
      ...consentDetails,
    };
  }

  function storeLinkedAccounts(linkedAccounts: LinkedAccount[]) {
    LinkAccountsList.value = {
      ...linkedAccounts,
    };
  }

  function storeDiscoveredAccounts(discoveredAccounts: discoveredAccountProto) {
    DiscoveredAccounts.value = [];
    DiscoveredAccounts.value = {
      ...DiscoveredAccounts.value,
      ...discoveredAccounts,
    };
  }

  function storeAccountLinkingResponse(response: AccountLinkingResponseProto) {
    accountLinkingResponse.value = {
      ...accountLinkingResponse.value,
      ...response,
    };
  }

  function storeLinkedAccountResponse(response: AccountLinkedResProto) {
    accountLinkedResponse.value = {
      ...response,
    };
  }

  function storexid(id: string) {
    xid.value = id;
  }

  function getFeature<T>(featureName: string, defaultValue?: T): T {
    if (featureList.value.has(featureName)) {
      return featureList.value.get(featureName) as T;
    } else {
      return defaultValue as T;
    }
  }

  function setFeature<T>(featureName: string, value: T): void {
    featureList.value.set(featureName, value);
  }

  return {
    // State
    discriminator,
    brand,
    availableAAs,
    availableFips,
    customer,
    consentRequestDetails,
    otpDetails,
    aaSdkAdapter,
    aaHandle,
    LinkAccountsList,
    consentRes,
    fipConsentReqResponse,
    selectedFip,
    aaHandleLogo,
    DiscoveredAccounts,
    accountLinkingResponse,
    requestAlreadyProcessed,
    exit,
    mobileNos,
    LinkedAccountsMap,
    DiscoveredAccountsMap,
    mobileSelected,
    availableAccountFilters,
    onCompleteRedirectUrl,
    altRedirectUrl,
    reqId,
    xid,
    tenantId,
    availableParams,
    userData,
    userConsentAction,
    receivedXid,
    failureOccured,
    redirectMode,
    errorObject,
    webRedirectUrl,
    aaFipMappings,
    markCompleteStatus,
    flowState,
    supportedAAs,
    // maxSelections,
    consentTemplateDef,
    featureList,
    routeState,
    extraIdentifiers,
    // Actions
    updateBrand,
    setAaMeta,
    updateCustomer,
    setOtpDetails,
    storeConsentDetails,
    storeConsentResponse,
    storeLinkedAccounts,
    storeDiscoveredAccounts,
    storeAccountLinkingResponse,
    storeLinkedAccountResponse,
    storexid,
    updateParams,
    getFeature,
    setFeature,
    getOtpResendTimer,
    alternateMobileFlow,
    getOtpLength,
  };
});