import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import { ref } from "vue";

const salt = ref(CryptoJS.lib.WordArray.random(128 / 8));
const iv = ref(CryptoJS.lib.WordArray.random(128 / 8));
const uniquePassPhrase = ref(uuidv4());

export default function cryptoSecure() {
  async function encryptPayloadForEachRequest(input: any) {
    const encryptedPayloadValue = await encrypt(JSON.stringify(input));
    return encryptedPayloadValue;
  }

  async function encrypt(payload: string) {
    const key = CryptoJS.PBKDF2(uniquePassPhrase.value, salt.value, {
      keySize: 256 / 32,
      iterations: 1024,
    });
    const encrypted = CryptoJS.AES.encrypt(payload, key, {
      iv: iv.value,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  }

  async function importPublicKeyAndEncrypt(plaintext: string, JWK: object) {
    const pub = await importPublicKey(JWK);
    const encrypted = await encryptRSA(
      pub,
      new TextEncoder().encode(plaintext),
    );
    const encryptedBase64 = window.btoa(ab2str(encrypted));
    return encryptedBase64;
  }

  async function importPublicKey(JWK: object) {
    return await window.crypto.subtle.importKey(
      "jwk",
      JWK,
      {
        name: "RSA-OAEP",
        hash: "SHA-256",
      },
      true,
      ["encrypt"],
    );
  }

  async function encryptRSA(key: any, plaintext: any) {
    const encrypted = await window.crypto.subtle.encrypt(
      {
        name: "RSA-OAEP",
      },
      key,
      plaintext,
    );
    return encrypted;
  }

  function ab2str(buf: any) {
    const uint8Array = new Uint8Array(buf);
    return String.fromCharCode.apply(null, [...uint8Array]);
  }

  async function runFunctionOnPayload(payload: any) {
    const keysToExclude = [
      "isAadharOtpLogin",
      "sessionId",
      "isForgotPassword",
      "consentGranted",
    ];
    for (const key in payload) {
      if (!keysToExclude.includes(key)) {
        if (key !== "keySet") {
          payload[key] = await encryptPayloadForEachRequest(payload[key]);
        } else {
          const obj = {
            type: "AES",
            keys: {
              salt: salt.value.toString(),
              iv: iv.value.toString(),
              passphrase: uniquePassPhrase.value,
            },
          };
          payload[key] = await importPublicKeyAndEncrypt(
            JSON.stringify(obj),
            payload[key],
          );
        }
      }
    }
    return payload;
  }

  return {
    encryptPayloadForEachRequest,
    runFunctionOnPayload,
  };
}

// //   //******DECRYPING FUNTION STARTS******
// //   // function decrypt(payload: any) {
// //   //   const key = CryptoJS.PBKDF2(uniquePassPhrase.value, salt.value, { keySize: 256 / 32, iterations: 1024 });
// //   //   const decrypted = CryptoJS.AES.decrypt(payload, key, { iv: iv.value, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
// //   //   const decryptedPayloadValue = decrypted.toString(CryptoJS.enc.Utf8);
// //   //   return decryptedPayloadValue;
// //   // }
// //   //******DECRYPING FUNTION STARTS******