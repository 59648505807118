<template>
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.5195 2.10938H21.0938V0.84375C21.0938 0.377789 20.716 0 20.25 0C19.784 0 19.4062 0.377789 19.4062 0.84375V2.10938H7.59375V0.84375C7.59375 0.377789 7.21601 0 6.75 0C6.28399 0 5.90625 0.377789 5.90625 0.84375V2.10938H3.48047C1.56131 2.10938 0 3.67068 0 5.58984V23.5195C0 25.4387 1.56131 27 3.48047 27H23.5195C25.4387 27 27 25.4387 27 23.5195V5.58984C27 3.67068 25.4387 2.10938 23.5195 2.10938ZM3.48047 3.79688H5.90625V4.64062C5.90625 5.10659 6.28399 5.48438 6.75 5.48438C7.21601 5.48438 7.59375 5.10659 7.59375 4.64062V3.79688H19.4062V4.64062C19.4062 5.10659 19.784 5.48438 20.25 5.48438C20.716 5.48438 21.0938 5.10659 21.0938 4.64062V3.79688H23.5195C24.5082 3.79688 25.3125 4.60118 25.3125 5.58984V7.59375H1.6875V5.58984C1.6875 4.60118 2.4918 3.79688 3.48047 3.79688ZM23.5195 25.3125H3.48047C2.4918 25.3125 1.6875 24.5082 1.6875 23.5195V9.28125H25.3125V23.5195C25.3125 24.5082 24.5082 25.3125 23.5195 25.3125Z"
      fill="currentColor" />
  </svg>
</template>