<template>
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5168 14.3007H11.5165V12.2241C11.7062 11.4315 10.4645 10.0524 7.82596 8.11845C10.4818 6.18449 11.7062 4.80536 11.5338 4.02861V1.90446H12.534C12.8617 1.90446 13.1203 1.66672 13.1203 1.36552C13.1203 1.06419 12.8444 0.842285 12.5167 0.842285H1.37603C1.04833 0.842285 0.789706 1.08002 0.789706 1.38123C0.789706 1.68243 1.04834 1.92017 1.37603 1.92017H2.37627V4.0285C2.18656 4.8211 3.42825 6.2002 6.06682 8.13418C3.41094 10.0525 2.18657 11.4314 2.35902 12.224V14.3006H1.35878C1.03108 14.3006 0.772461 14.5383 0.772461 14.8396C0.772461 15.1408 1.0311 15.3785 1.35878 15.3785H12.5167C12.8444 15.3785 13.103 15.1408 13.103 14.8396C13.1031 14.5383 12.8444 14.3007 12.5168 14.3007ZM3.53181 1.9044H10.3783V4.21886C10.3438 4.32988 10.1714 4.6469 9.58495 5.24919L4.32505 5.24908C3.72139 4.64667 3.56621 4.32966 3.53169 4.21875L3.53181 1.9044ZM3.53181 12.018C3.60074 11.8119 4.0664 10.9242 6.94644 8.83176C9.82645 10.9243 10.2921 11.812 10.3611 12.018V14.3008H10.0679L6.94645 11.4791L3.825 14.3008H3.53185L3.53181 12.018Z"
      fill="currentColor" />
  </svg>
</template>