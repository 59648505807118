<template>
  <svg
    width="68"
    height="20"
    viewBox="0 0 68 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5695 11.173C12.5695 10.3749 15.263 8.27994 16.3603 10.9734H17.757C17.757 10.4747 16.9777 8.92515 15.5623 8.67898C13.2678 8.27995 12.5695 9.77634 12.5695 9.77634V8.77875H11.1729V16.56H12.5695V11.173ZM16.3603 10.9735H17.757V16.56H16.3603V10.9735Z"
      fill="#58585A" />
    <path
      d="M61.9507 8.7793H60.6538L63.4471 15.7625C63.4471 15.7625 63.7036 16.4891 63.6466 16.9596C63.5367 17.8658 63.0165 18.6671 62.1502 18.9548C61.6326 19.1266 60.7536 18.7552 60.7536 18.7552V19.7528C60.7536 19.7528 61.8668 20.1315 62.5492 19.9523C63.4035 19.7281 64.0826 18.855 64.2451 18.456C65.3425 15.7625 67.8365 8.7793 67.8365 8.7793H66.6394L64.3449 15.0641L61.9507 8.7793Z"
      fill="#F26B63" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.08773 16.9587C7.8976 16.9587 10.1755 14.6808 10.1755 11.8709C10.1755 9.06106 7.8976 6.7832 5.08773 6.7832C2.27785 6.7832 0 9.06106 0 11.8709C0 14.6808 2.27785 16.9587 5.08773 16.9587ZM5.08773 15.562C7.12627 15.562 8.77883 13.9095 8.77883 11.8709C8.77883 9.8324 7.12627 8.17984 5.08773 8.17984C3.04919 8.17984 1.39663 9.8324 1.39663 11.8709C1.39663 13.9095 3.04919 15.562 5.08773 15.562Z"
      fill="#58585A" />
    <path
      d="M8.93183 0.598556C3.24554 5.28725 3.44506 15.0637 3.44506 15.0637L2.24795 14.2656C2.24795 14.2656 1.35011 5.78605 6.93664 0C7.74047 0.124906 8.19113 0.262228 8.93183 0.598556Z"
      fill="#E21A50" />
    <path
      d="M13.3211 0.797778C3.74423 8.77853 5.29373 16.3603 5.29373 16.3603L4.09662 15.5622C4.09662 15.5622 3.44495 6.98286 11.3259 0.199219C12.1298 0.324125 12.5804 0.461449 13.3211 0.797778Z"
      fill="#14B1C8" />
    <path
      d="M18.1095 1.69554C7.8343 7.78086 7.24887 16.46 7.24887 16.46L6.05176 15.6619C6.05176 15.6619 6.63719 6.28447 16.0146 0.897461C16.8184 1.02237 17.3688 1.35921 18.1095 1.69554Z"
      fill="#F26B63" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.08773 15.5622C7.12627 15.5622 8.77883 13.9096 8.77883 11.8711H10.1755C10.1755 12.5392 10.0439 13.2008 9.78818 13.8181C9.5325 14.4354 9.15774 14.9962 8.6853 15.4687C8.21286 15.9411 7.65199 16.3159 7.03472 16.5715C6.41745 16.8272 5.75586 16.9588 5.08773 16.9588C4.4196 16.9588 3.75801 16.8272 3.14074 16.5715C2.52347 16.3159 1.9626 15.9411 1.49016 15.4687C1.01772 14.9962 0.642963 14.4354 0.38728 13.8181C0.131598 13.2008 0 12.5392 0 11.8711H1.39663C1.39663 13.9096 3.04919 15.5622 5.08773 15.5622Z"
      fill="#58585A" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M48.2834 11.173C48.2834 10.3749 50.9769 8.27994 52.0742 10.9734H53.4708C53.4708 10.4747 52.6916 8.92515 51.2761 8.67898C48.9817 8.27995 48.2834 9.77634 48.2834 9.77634V8.77875H46.8867V16.56H48.2834V11.173ZM52.0742 10.9735H53.4708V16.56H52.0742V10.9735Z"
      fill="#F26B63" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.743 11.971C23.7441 11.9572 23.9367 9.57685 21.9473 9.57685C19.9521 9.57685 19.7526 11.7716 19.7526 11.7716C19.7526 11.7716 19.7428 11.8469 19.7299 11.971H23.743ZM24.9401 11.971C25.1395 8.57922 21.8036 8.67902 21.8036 8.67902C21.4037 8.68523 18.4098 8.8653 18.5554 12.8689C18.7549 18.3556 24.7406 16.2607 24.7406 16.2607V15.0636C24.7406 15.0636 20.5507 16.7595 19.7526 13.8665C19.687 13.6287 19.6678 13.2975 19.6703 12.9686H24.9401V11.971Z"
      fill="#58585A" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M59.4617 11.971C59.4629 11.9572 59.6555 9.57685 57.6661 9.57685C55.6709 9.57685 55.4714 11.7716 55.4714 11.7716C55.4714 11.7716 55.4615 11.8469 55.4486 11.971H59.4617ZM60.6587 11.9711H60.6588C60.8583 8.57922 57.5224 8.67902 57.5224 8.67902C57.1224 8.68523 54.1286 8.8653 54.2742 12.8689C54.4737 18.3556 60.4593 16.2607 60.4593 16.2607V15.0636C60.4593 15.0636 56.2694 16.7595 55.4714 13.8665C55.4058 13.6287 55.3865 13.2975 55.389 12.9686H60.6587V11.9711Z"
      fill="#F26B63" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.5213 10.9828C33.9229 8.61923 35.9131 10.2139 35.9131 10.9661C36.3275 10.9571 37.3098 10.9661 37.3098 10.9661C37.3098 10.9661 37.3424 10.2579 37.1102 9.85552C35.9131 7.78103 32.8206 8.50655 32.1223 9.85552C32.1199 9.86002 32.1176 9.86453 32.1154 9.86904C29.6657 7.4259 27.9528 9.2117 27.5334 9.74084V8.77862H26.1367V16.5599H27.5334V11.3683C29.0255 8.45812 31.1247 10.1928 31.1247 10.9733C31.4838 10.9651 32.0289 10.972 32.1116 10.9731C32.1116 10.9732 32.1116 10.9733 32.1117 10.9733H31.1247V16.5599H32.5213V10.9828ZM37.3098 10.9733H35.9131V16.5599H37.3098V10.9733Z"
      fill="#F26B63" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M42.0983 16.76C44.3021 16.76 46.0887 14.9735 46.0887 12.7697C46.0887 10.5658 44.3021 8.7793 42.0983 8.7793C39.8945 8.7793 38.1079 10.5658 38.1079 12.7697C38.1079 14.9735 39.8945 16.76 42.0983 16.76ZM42.0984 15.6646C43.6972 15.6646 44.9933 14.3685 44.9933 12.7697C44.9933 11.1708 43.6972 9.87468 42.0984 9.87468C40.4995 9.87468 39.2034 11.1708 39.2034 12.7697C39.2034 14.3685 40.4995 15.6646 42.0984 15.6646Z"
      fill="#F26B63" />
  </svg>
</template>