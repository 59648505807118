import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container mx-auto px-4" }
const _hoisted_2 = { class: "flex items-center py-2.5 sm:py-[21px] h-[84px]" }
const _hoisted_3 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.headerHide)
    ? (_openBlock(), _createElementBlock("header", {
        key: 0,
        class: _normalizeClass(["fixed top-0 left-0 w-full bg-white shadow-md z-50", $props.headerStyle])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("p", _hoisted_2, [
            ($props.logoHide)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "h-[42px] md:max-w-[30%] object-contain",
                  src: $props.imgSrc,
                  alt: $props.altName
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true)
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}