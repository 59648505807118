<template>
  <svg
    width="12"
    height="9"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.80957"
      y="4.28462"
      width="2.20497"
      height="5.61069"
      rx="1.10248"
      transform="rotate(-45 0.80957 4.28462)"
      fill="currentColor" />
    <rect
      x="9.95996"
      y="-4.57764e-05"
      width="2.20497"
      height="9.47754"
      rx="1.10248"
      transform="rotate(45 9.95996 -4.57764e-05)"
      fill="currentColor" />
  </svg>
</template>