export const AA_EVENT_LIST_ENUM = {
  selectBank: "SELECT_BANK",
  establishAuth: "ESTABLISH_AUTH",
  otpSent: "OTP_SENT",
  otpSubmitted: "OTP_SUBMITTED",
  otpValidated: "OTP_VALIDATED",
  showLinkedAccounts: "SHOW_LINKED_ACCOUNTS",
  grantConsent: "GRANT_CONSENT",
  denyConsent: "DENY_CONSENT",
  addMoreBanks: "ADD_MORE_BANKS",
  discoverAccounts: "DISCOVER_ACCOUNTS",
  linkAccounts: "LINK_ACCOUNTS",
  otpSentToLinkAcc: "OTP_SENT_TO_LINK_ACCOUNTS",
  otpValidatedToLinkAcc: "OTP_VALIDATED_TO_LINK_ACCOUNTS",
  accountLinked: "ACCOUNT_LINKED",
  addAlternateMobile: "ADD_ALTERNATE_MOBILE",
  validateAlternateMobile: "ALTERNATE_MOBILE_VALIDATED",
};

export const JOURNEY_STATUS_ENUM = {
  pending: "PENDING",
  granted: "GRANTED",
  rejected: "REJECTED",
  cancelled: "CANCELLED",
  failed: "FAILED",
  linkSent: "LINK_SENT",
};

export const REDIRECT_MODE_ENUM = {
  both: "both",
  anubhavx: "anubhavx",
  anubhav: "anubhav",
};

export const ERROR_MESSAGES = {
  MOBILE_SAME_USERID:
    "Please try with another mobile number, this one is same as currently active mobile number",
  INVALID_MOBILE: "Please enter valid mobile number",
  LINK_EXPIRED: "Link Has Already Expired",
  ALREADY_COMPLETED: "Request is already processed",
  IN_PROCESS: "Request is already processing. Please try again in sometime",
  INTERNAL_ERROR: "Request is already processing. Please try again in sometime",
  INVALID_REQUEST:
    "Sorry could not fetch details. Please try again in sometime",

  // operation specific
  CONSENT_JOURNEY_FAILED: "Not able to grant consent",
};

export const ERROR_RESPONSE_CODES = {
  ALREADY_COMPLETED: "AlreadyCompleted",
  IN_PROCESS: "InProcess",
  LINK_EXPIRED: "LinkExpired",
  INTERNAL_ERROR: "InternalError",
  INVALID_REQUEST: "InvalidRequest",
  DATA_NOT_FOUND: "NoDataFound",

  // operation specific
  CONSENT_JOURNEY_FAILED: "CONSENT_JOURNEY_FAILED",
};

export const enum ITREvents {
  OPEN = "OPEN",
  PASSWORD_SUBMIT = "PASSWORD_SUBMIT",
  PASSWORD_VALID = "PASSWORD_VALID",
  PASSWORD_INVALID = "PASSWORD_INVALID",
  AADHAAR_OTP_SUBMIT = "AADHAAR_OTP_SUBMIT",
  AADHAAR_OTP_VALID = "AADHAAR_OTP_VALID",
  AADHAAR_OTP_INVALID = "AADHAAR_OTP_INVALID",
  AADHAAR_OTP_EXPIRED = "AADHAAR_OTP_EXPIRED",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  FORGOT_PASSWORD_OTP_SUBMIT = "FORGOT_PASSWORD_OTP_SUBMIT",
  FORGOT_PASSWORD_OTP_SENT = "FORGOT_PASSWORD_OTP_SENT",
  FORGOT_PASSWORD_OTP_VALID = "FORGOT_PASSWORD_OTP_VALID",
  FORGOT_PASSWORD_OTP_INVALID = "FORGOT_PASSWORD_OTP_INVALID",
  FORGOT_PASSWORD_OTP_EXPIRED = "FORGOT_PASSWORD_OTP_EXPIRED",
  TIMEOUT_ERROR = "TIMEOUT_ERROR",
  ERROR = "ERROR",
  EXPIRED = "EXPIRED",
  SUCCESS = "SUCCESS", // Terminal event
  // EXIT = 'EXIT'      // Terminal event
}

export const ITRSTATUS = {
  // EXECUTING: 'EXECUTING',
  // COMPLETED:'COMPLETED',
  EXPIRED: "EXPIRED",
  SUCCESS: "SUCCESS",
};

export const enum JourneyFeatures {
  MAX_SELECTIONS = "consent.accounts.max-selections",
  SHOW_POWERED_BY = "ui.finarkein.poweredby.enabled",
  ALT_MOBILE = "ui.aa.altMobile",
}

export const enum JOURNEY_INIT_REQUIREMENTS {
  AVAILABLE_STORE = "availableStore",
  AVAILABLE_CONDUCTOR = "availableConductor",
  AVAILABLE_VIEWS = "availableViews",
}

export const enum JOURNEY_LAYOUTS {
  V3 = "v3",
  V5 = "v5",
  V6 = "v6",
  V2 = "v2",
  V7 = "v7",
  V8 = "v8",
}

export const enum COMPONENT_VIEW_TYPE {
  DEFAULT = "default",
  MODAL = "modal",
}

export const OTP_INPUT_TYPE = {
  SPLIT: "split",
  STANDARD: "standard",
};

export const TERMS_AND_CONDITIONS_TYPE = {
  IFRAME: "iframe",
  OTHERS: "others",
};

export const enum GROUPING_MODE {
  GROUPED = "GROUPED",
  UNGROUPED = "UNGROUPED",
}

export const enum SELECTION_MODE {
  MULTIPLE = "MULTIPLE",
  SINGLE = "SINGLE",
}

export const enum MANUAL_UPLOAD_REDIRECTION {
  NONE = "NONE",
  REDIRECT = "REDIRECT",
}
export const enum MANUAL_UPLOAD_REDIRECTION_BUTTON {
  HIDE = "HIDE",
  SHOW = "SHOW",
}

export const enum DOC_UPLOAD {
  AVAILABLE = "AVAILABLE",
  UNAVAILABLE = "UNAVAILABLE",
}

export const enum EXIT_FEEDBACK {
  ENABLE = "ENABLE",
  DISABLE = "DISABLE",
}
export const enum LAYOUT_TYPE {
  DEFAULT = "DEFAULT",
  OTHERS = "OTHERS",
}

export const enum FLOW_STATE {
  WAITING = "WAITING",
  RUNNING = "RUNNING",
  FAILED = "FAILED",
  SUCCESS = "SUCCESS",
}

export const enum CONSENT_STATUS {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  FAILED = "FAILED",
  MANUAL = "MANUAL",
  EXPIRED = "EXPIRED",
  PAUSED = "PAUSED",
  REJECTED = "REJECTED",
  REVOKED = "REVOKED",
}

export const enum DATA_FETCH_STATUS {
  PENDING = "PENDING",
  RUNNING = "RUNNING",
  FAILED = "FAILED",
  NOT_APPLICABLE = "NOT_APPLICABLE",
}

export const enum DOC_UPLOAD_STATUS {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  NOT_APPLICABLE = "NOT_APPLICABLE",
}
// dev and prod have different handle so read the handle from env
export const SAAFE_AA_HANDLE = process.env["VUE_APP_TPCONFIG_AA_SAAFE_HANDLE"] || "saafe";
export const PROTEAN_AA_HANDLE = process.env["VUE_APP_TPCONFIG_AA_PROTEAN_HANDLE"] || "protean";

export const enum SALARIED_FLAG_STATUS {
  SALARIED = "Select the bank wherein your salaried income gets credited",
  NONSALARIED = "Select the bank with your income credits",
}

export const enum SALARIED_FLAG_STATUS_CONSENT {
  SALARIED = "Please select the account/s wherein your salaried income gets credited",
  NONSALARIED = "Please select the account/s with your income credits",
}