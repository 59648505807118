<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke-width="0"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      width="24"
      height="24"
      fill="white" />
    <path
      d="M7.95012 10.2116C8.11734 10.0445 8.24999 9.84601 8.34049 9.62758C8.431 9.40914 8.47758 9.17502 8.47758 8.93858C8.47758 8.70214 8.431 8.46801 8.34049 8.24958C8.24999 8.03115 8.11734 7.83268 7.95012 7.66553L6.03582 5.75663C5.69827 5.41918 5.24051 5.22961 4.76322 5.22961C4.28592 5.22961 3.82817 5.41918 3.49062 5.75663L2.44302 6.80423C2.00357 7.24419 1.72946 7.82235 1.66699 8.44103C1.60451 9.05971 1.7575 9.681 2.10012 10.1999C5.18276 14.8396 9.16046 18.8173 13.8001 21.8999C14.3189 22.243 14.9403 22.3964 15.5592 22.3343C16.178 22.2721 16.7565 21.9982 17.1967 21.5588L18.2452 20.5112C18.5827 20.1737 18.7722 19.7159 18.7722 19.2386C18.7722 18.7613 18.5827 18.3036 18.2452 17.966L16.3381 16.058C16.0006 15.7206 15.5428 15.531 15.0655 15.531C14.5882 15.531 14.1305 15.7206 13.7929 16.058L13.1566 16.6943C11.0215 14.9386 9.06232 12.9794 7.30662 10.8443L7.95012 10.2116Z"
      fill="white" />
    <path
      d="M7.31022 10.8479L7.94652 10.2116C8.11374 10.0444 8.24639 9.84597 8.33689 9.62754C8.4274 9.40911 8.47398 9.17498 8.47398 8.93854C8.47398 8.7021 8.4274 8.46798 8.33689 8.24954C8.24639 8.03111 8.11374 7.83265 7.94652 7.66549L6.03672 5.75659C5.86956 5.58937 5.6711 5.45672 5.45266 5.36621C5.23423 5.27571 5.00011 5.22913 4.76367 5.22913C4.52723 5.22913 4.2931 5.27571 4.07467 5.36621C3.85623 5.45672 3.65777 5.58937 3.49062 5.75659L2.44302 6.80419C2.00357 7.24415 1.72946 7.82231 1.66699 8.44099C1.60451 9.05967 1.7575 9.68096 2.10012 10.1999C3.51436 12.3254 5.12167 14.316 6.90162 16.1462L9.62322 13.4246C8.81322 12.5867 8.02842 11.7299 7.31022 10.8479Z"
      fill="white" />
    <path
      d="M7.94289 10.2115C8.28034 9.874 8.46991 9.41624 8.46991 8.93895C8.46991 8.46165 8.28034 8.0039 7.94289 7.66635L6.03309 5.75745C5.86594 5.59022 5.66748 5.45757 5.44904 5.36707C5.23061 5.27656 4.99648 5.22998 4.76004 5.22998C4.5236 5.22998 4.28948 5.27656 4.07104 5.36707C3.85261 5.45757 3.65415 5.59022 3.48699 5.75745L2.44029 6.80415C2.00127 7.24444 1.72766 7.82276 1.66567 8.44143C1.60369 9.06011 1.75714 9.68121 2.10009 10.1998C5.18325 14.8391 9.16085 18.8167 13.8001 21.8998C14.3189 22.2429 14.9403 22.3964 15.5591 22.3342C16.178 22.2721 16.7565 21.9981 17.1967 21.5587L18.2443 20.5111C18.5817 20.1736 18.7713 19.7158 18.7713 19.2385C18.7713 18.7613 18.5817 18.3035 18.2443 17.9659L16.3345 16.0588C16.1673 15.8916 15.9689 15.759 15.7504 15.6685C15.532 15.578 15.2979 15.5314 15.0614 15.5314C14.825 15.5314 14.5909 15.578 14.3724 15.6685C14.154 15.759 13.9555 15.8916 13.7884 16.0588L13.153 16.6951C11.0182 14.9391 9.05899 12.9799 7.30299 10.8451L7.94289 10.2115Z"
      stroke-width="0.9"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M16.9501 12.4499C18.0181 12.4499 19.0622 12.1332 19.9502 11.5398C20.8382 10.9465 21.5303 10.1031 21.9391 9.1164C22.3478 8.12967 22.4547 7.04391 22.2464 5.99642C22.038 4.94892 21.5237 3.98673 20.7685 3.23153C20.0133 2.47632 19.0511 1.96202 18.0036 1.75366C16.9561 1.5453 15.8703 1.65224 14.8836 2.06096C13.8969 2.46967 13.0535 3.1618 12.4602 4.04983C11.8668 4.93785 11.5501 5.98189 11.5501 7.04991C11.5501 8.48207 12.119 9.85559 13.1317 10.8683C14.1444 11.881 15.5179 12.4499 16.9501 12.4499Z"
      fill="#E6BEFF" />
    <path
      d="M16.9501 1.6499C15.8509 1.649 14.7776 1.98385 13.874 2.60963C12.9703 3.23542 12.2793 4.12229 11.8935 5.15155C11.5076 6.18081 11.4454 7.30336 11.715 8.36898C11.9847 9.43459 12.5734 10.3924 13.4023 11.1143L21.0145 3.5021C20.5091 2.92041 19.8847 2.45401 19.1835 2.13447C18.4823 1.81492 17.7207 1.64968 16.9501 1.6499Z"
      fill="white" />
    <path
      d="M16.9501 12.4499C18.0181 12.4499 19.0622 12.1332 19.9502 11.5398C20.8382 10.9465 21.5303 10.1031 21.9391 9.1164C22.3478 8.12967 22.4547 7.04392 22.2464 5.99642C22.038 4.94892 21.5237 3.98673 20.7685 3.23153C20.0133 2.47632 19.0511 1.96202 18.0036 1.75366C16.9561 1.5453 15.8703 1.65224 14.8836 2.06096C13.8969 2.46967 13.0535 3.1618 12.4602 4.04983C11.8668 4.93785 11.5501 5.98189 11.5501 7.04991C11.5501 8.48207 12.119 9.85559 13.1317 10.8683C14.1444 11.881 15.5179 12.4499 16.9501 12.4499Z"
      stroke-width="0.9"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M16.9501 4.34985V9.74985"
      stroke-width="0.9"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M19.6501 7.04993H14.2501"
      stroke-width="0.9"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>