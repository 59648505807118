<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.11 0C3.54 0 3.05 0.33 2.8 0.81C2.65 1.07 2.74 1.4 3 1.54C3.25 1.68 3.58 1.59 3.72 1.34C3.77 1.17 3.92 1.06 4.11 1.06H10.18C10.41 1.06 10.59 1.23 10.59 1.47V7.54C10.59 7.72 10.48 7.87 10.33 7.92C10.08 8.06 9.99 8.39 10.13 8.64C10.28 8.9 10.61 8.99 10.86 8.84C11.33 8.6 11.65 8.1 11.65 7.54V1.46C11.65 0.66 10.99 0 10.18 0H4.11ZM1.47 2.65C0.66 2.65 0 3.31 0 4.11V10.18C0 10.98 0.66 11.64 1.47 11.64H7.54C8.34 11.64 9.01 10.98 9.01 10.18V4.11C9.01 3.31 8.35 2.65 7.54 2.65H1.47ZM1.47 3.7H7.54C7.77 3.7 7.95 3.87 7.95 4.11V10.18C7.95 10.41 7.78 10.59 7.54 10.59H1.47C1.24 10.59 1.06 10.42 1.06 10.18V4.11C1.06 3.88 1.23 3.7 1.47 3.7Z"
      fill="currentColor"
    />
  </svg>
</template>