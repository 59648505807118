<template>
  <div class="flex items-center">
    <p class="font-medium text-md text-blue-800 text-right leading-5">
      powered<br>by
    </p>
    <a
      href="https://finarkein.com"
      target="_blank"><img
        class="h-[48px] ml-3"
        src="../assets/logo_finarkein.svg"
        alt=""></a>
    <!-- <p class="font-medium text-md text-blue-800">platform</p> -->
  </div>
</template>

<script>

export default {
  components: {

  },
};
</script>